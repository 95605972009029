import { useNavigate } from "react-router-dom";

const AfiliadosDisponibles = () => {
  const navigate = useNavigate();
  const opciones = ['Revive', 'opcion2', 'opcion3'];

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4">
      <h1 className="text-2xl font-bold mb-4"> AFILIADOS DISPONIBLES </h1>
      <div className="w-full flex flex-wrap justify-center">
        {opciones.map(opcion => (
          <button
            key={opcion}
            onClick={() => navigate(`${opcion}`)}
            className="flex-1 m-2 px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            style={{ minWidth: "150px", maxWidth: "300px" }}
          >
            {opcion}
          </button>
        ))}
      </div>
    </div>
  );
}

export default AfiliadosDisponibles;
