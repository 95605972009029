import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const HistoriasOffline = () => {
  const [historias, setHistorias] = useState([]);
  const [filteredHistorias, setFilteredHistorias] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Para abrir/cerrar el modal
  const [selectedHistoria, setSelectedHistoria] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false); // Para el modal de creación
  const [newPaciente, setNewPaciente] = useState({
    nombre: '',
    cedula: '',
    correo: '',
    telefono: '',
    direccion: '',
    sexo: '',
    diagnosticos: [],
  });

  const addDiagnostico = () => {
    setNewPaciente({
      ...newPaciente,
      diagnosticos: [
        ...newPaciente.diagnosticos,
        {
          motivo_de_consulta: '',
          resultados_paraclinicos: '',
          alertas: '',
          alergias: '',
          analisis_y_plan: '',
          diagnostico_CIE_10: '',
          recomendaciones: '',
        },
      ],
    });
  };

  const removeDiagnostico = (index) => {
    const updatedDiagnosticos = newPaciente.diagnosticos.filter(
      (_, i) => i !== index
    );
    setNewPaciente({ ...newPaciente, diagnosticos: updatedDiagnosticos });
  };

  // Manejar cambios en un diagnóstico específico
  const handleDiagnosticoChange = (index, field, value) => {
    const updatedDiagnosticos = newPaciente.diagnosticos.map((diagnostico, i) =>
      i === index ? { ...diagnostico, [field]: value } : diagnostico
    );
    setNewPaciente({ ...newPaciente, diagnosticos: updatedDiagnosticos });
  };

  const fetchHistorias = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/mobile/getHistoriaClinicaOffline`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setHistorias(response.data.data);
      setFilteredHistorias(response.data.data);
    } catch (err) {
      setError('Error al obtener las historias clínicas.');
      console.error('Error al obtener los datos:', err);
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-background') {
      closeModal();
    }
  }
  const createPaciente = async () => {
    // Transforma el arreglo de diagnósticos en un objeto con claves tipo "diagnostico1", "diagnostico2", etc.
    const diagnosticosConClaves = newPaciente.diagnosticos.reduce((acc, diagnostico, index) => {
      acc[`diagnostico${index + 1}`] = diagnostico;  // Asignar claves dinámicas como 'diagnostico1', 'diagnostico2', etc.
      return acc;
    }, {});

    // Crear un nuevo objeto paciente con los diagnósticos transformados
    const pacienteConDiagnosticos = {
      ...newPaciente,
      diagnosticos: diagnosticosConClaves,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/mobile/sendHistoriaClinicaOffline`,
        pacienteConDiagnosticos,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Paciente creado correctamente',
    })
      closeCreateModal();
      fetchHistorias(); // Actualizar la lista de pacientes después de crear
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error creando el paciente',
    })
    }
  };


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = historias.filter((historia) =>
      historia.cedula.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredHistorias(filtered);
  };

  const openModal = (historia) => {
    setSelectedHistoria(historia);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedHistoria(null);
  };

  const openCreateModal = () => {
    setShowCreateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
    setNewPaciente({
      nombre: '',
      cedula: '',
      correo: '',
      telefono: '',
      direccion: '',
      sexo: '',
      diagnosticos: [],
    });
  };

  useEffect(() => {
    fetchHistorias();
  }, []);

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }


const downloadRips = (historia) => {
  // Datos estáticos que se mantendrán constantes
  const ripsData = {
    numDocumentoIdObligado: "830123986",
    numFactura: null,
    tipoNota: "NA",
    numNota: null,
    usuarios: [
      {
        tipoDocumentoIdentificacion: "CC",
        numDocumentoIdentificacion: historia.cedula,
        tipoUsuario: "11",
        fechaNacimiento: "2024-11-05", // Modifica si tienes un dato dinámico
        codSexo: historia.sexo === "Masculino" ? "M" : "F",
        codPaisResidencia: "004",
        codMunicipioResidencia: "41006",
        codZonaTerritorialResidencia: "01",
        incapacidad: "NO",
        codPaisOrigen: "010",
        consecutivo: 1,
        servicios: {
          consultas: Object.entries(historia.diagnosticos).map(
            ([key, diagnostico], index) => ({
              codPrestador: "110011973101", // Valor estático o dinámico
              fechaInicioAtencion: historia.hora, // Ajusta según tu formato
              numAutorizacion: "0",
              codConsulta: "890201",
              modalidadGrupoServicioTecSal: "01",
              grupoServicios: "01",
              codServicio: 325,
              finalidadTecnologiaSalud: "12",
              causaMotivoAtencion: "38",
              codDiagnosticoPrincipal: diagnostico.diagnostico_CIE_10.split(' - ')[0], // Usa el diagnóstico actual
              codDiagnosticoRelacionado1: null,
              codDiagnosticoRelacionado2: null,
              codDiagnosticoRelacionado3: null,
              tipoDiagnosticoPrincipal: "02",
              tipoDocumentoIdentificacion: "CC",
              numDocumentoIdentificacion: historia.cedula,
              vrServicio: 0,
              conceptoRecaudo: "05",
              valorPagoModerador: 0,
              numFEVPagoModerador: "0",
              consecutivo: index + 1, // Consecutivo basado en el índice
            })
          ),
        },
      },
    ],
  };

  // Crear un blob del archivo JSON
  const blob = new Blob([JSON.stringify(ripsData, null, 2)], {
    type: "application/json",
  });

  // Crear un enlace de descarga
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `rips_${historia.cedula}.json`;
  link.click();
};


  
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-semibold mb-6 text-center text-blue-900">Historias Clínicas Offline</h2>

      <div className="flex justify-between items-center mb-6">
        <button
          onClick={openCreateModal}
          className="bg-gradient-to-r from-green-500 to-green-700 text-white px-6 py-2 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1"
        >
          Crear Paciente
        </button>

        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Buscar por cédula"
          className="px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={fetchHistorias}
          className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-2 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1"
        >
          Actualizar
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {filteredHistorias.map((historia, index) => (
    <div
      key={index}
      className="bg-white shadow-lg rounded-xl p-6 border border-gray-300 hover:shadow-2xl transition-shadow duration-300"
    >
      <p><span className="font-bold text-gray-700">ID:</span> {historia._id}</p>
      <p><span className="font-bold text-gray-700">Nombre:</span> {historia.nombre}</p>
      <p><span className="font-bold text-gray-700">Cédula:</span> {historia.cedula}</p>
      <p><span className="font-bold text-gray-700">Correo:</span> {historia.correo}</p>
      <p><span className="font-bold text-gray-700">Teléfono:</span> {historia.telefono}</p>
      <p><span className="font-bold text-gray-700">Dirección:</span> {historia.direccion}</p>
      <p><span className="font-bold text-gray-700">Sexo:</span> {historia.sexo}</p>

      <button
        onClick={() => openModal(historia)}
        className="mt-6 px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg hover:bg-blue-600 transition-all duration-300"
      >
        Ver Diagnósticos
      </button>

      {/* Botón para generar el archivo RIPS */}
      <button
        onClick={() => downloadRips(historia)}
        className="mt-2 px-6 py-2 ml-7 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg hover:bg-orange-600 transition-all duration-300"
      >
        Descargar RIPS
      </button>
    </div>
  ))}
</div>

      {showModal && selectedHistoria && (
  <div
    id="modal-background"
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
    onClick={handleOutsideClick}
  >
    {/* Fondo opaco y modal centrado */}
    <div
      className="bg-white p-8 rounded-2xl max-w-2xl w-full max-h-[80vh] overflow-y-scroll shadow-lg relative z-50"
      onClick={(e) => e.stopPropagation()}  // Evita que el clic en el modal cierre el modal
    >
      <h3 className="text-2xl font-bold mb-6">Diagnósticos de {selectedHistoria.nombre}</h3>
      {Object.keys(selectedHistoria.diagnosticos).map((key) => {
        const diagnostico = selectedHistoria.diagnosticos[key];
        return (
          <div key={key} className="mt-4 border-t border-gray-300 pt-4">
            <p><span className="font-bold text-gray-600">Motivo de consulta:</span> {diagnostico.motivo_de_consulta}</p>
            <p><span className="font-bold text-gray-600">Resultados paraclínicos:</span> {diagnostico.resultados_paraclinicos}</p>
            <p><span className="font-bold text-gray-600">Alertas:</span> {diagnostico.alertas}</p>
            <p><span className="font-bold text-gray-600">Alergias:</span> {diagnostico.alergias}</p>
            <p><span className="font-bold text-gray-600">Análisis y plan:</span> {diagnostico.analisis_y_plan}</p>
            <p><span className="font-bold text-gray-600">Diagnóstico (CIE-10):</span> {diagnostico.diagnostico_CIE_10}</p>
            <p><span className="font-bold text-gray-600">Recomendaciones:</span> {diagnostico.recomendaciones}</p>
          </div>
        );
      })}
      <button
        onClick={closeModal}
        className="mt-6 px-6 py-2 bg-gradient-to-r from-red-500 to-red-700 text-white rounded-lg hover:bg-red-600 transition-all duration-300"
      >
        Cerrar
      </button>
    </div>
  </div>
)}




      {showCreateModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white p-8 rounded-2xl max-w-2xl w-full max-h-[80vh] overflow-y-scroll shadow-lg relative">
            <h3 className="text-2xl font-bold mb-6">Crear Paciente</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createPaciente();
              }}
            >
              <div className="mb-4">
                <label className="block font-bold mb-2">Nombre:</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded-lg"
                  value={newPaciente.nombre}
                  onChange={(e) => setNewPaciente({ ...newPaciente, nombre: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">Cédula:</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded-lg"
                  value={newPaciente.cedula}
                  onChange={(e) => setNewPaciente({ ...newPaciente, cedula: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">Correo:</label>
                <input
                  type="email"
                  className="w-full p-2 border rounded-lg"
                  value={newPaciente.correo}
                  onChange={(e) => setNewPaciente({ ...newPaciente, correo: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">Teléfono:</label>
                <input
                  type="tel"
                  className="w-full p-2 border rounded-lg"
                  value={newPaciente.telefono}
                  onChange={(e) => setNewPaciente({ ...newPaciente, telefono: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">Dirección:</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded-lg"
                  value={newPaciente.direccion}
                  onChange={(e) => setNewPaciente({ ...newPaciente, direccion: e.target.value })}
                />
              </div>
              <div className="mb-4">
                <label className="block font-bold mb-2">Sexo:</label>
                <select
                  value={newPaciente.sexo}
                  onChange={(e) => setNewPaciente({ ...newPaciente, sexo: e.target.value })}
                  className="w-full p-2 border rounded-lg"
                  required
                >
                  <option value="">Seleccionar...</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                </select>
              </div>

              {/* Agregar diagnósticos */}
              <div className="mb-4">
                <label className="block font-bold mb-2">Diagnósticos:</label>
                {newPaciente.diagnosticos.map((diagnostico, index) => (
                  <div key={index} className="mb-4">
                    <h4 className="font-bold">Diagnóstico {index + 1}</h4>

                    {/* Motivo de la consulta */}
                    <input
                      type="text"
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Motivo de la consulta"
                      value={diagnostico.motivo_de_consulta}
                      onChange={(e) => handleDiagnosticoChange(index, 'motivo_de_consulta', e.target.value)}
                    />

                    {/* Resultados paraclínicos */}
                    <textarea
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Resultados paraclinicos"
                      value={diagnostico.resultados_paraclinicos}
                      onChange={(e) => handleDiagnosticoChange(index, 'resultados_paraclinicos', e.target.value)}
                    />

                    {/* Alertas */}
                    <textarea
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Alertas"
                      value={diagnostico.alertas}
                      onChange={(e) => handleDiagnosticoChange(index, 'alertas', e.target.value)}
                    />

                    {/* Alergias */}
                    <textarea
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Alergias"
                      value={diagnostico.alergias}
                      onChange={(e) => handleDiagnosticoChange(index, 'alergias', e.target.value)}
                    />

                    {/* Análisis */}
                    <textarea
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Análisis y Plan"
                      value={diagnostico.analisis_y_plan}
                      onChange={(e) => handleDiagnosticoChange(index, 'analisis_y_plan', e.target.value)}
                    />

                  
                    {/* Diagnóstico CIE-10 */}
                    <input
                      type="text"
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Diagnóstico CIE-10"
                      value={diagnostico.diagnostico_CIE_10}
                      onChange={(e) => handleDiagnosticoChange(index, 'diagnostico_CIE_10', e.target.value)}
                    />

                    {/* Recomendaciones */}
                    <textarea
                      className="w-full p-2 border rounded-lg mb-2"
                      placeholder="Recomendaciones"
                      value={diagnostico.recomendaciones}
                      onChange={(e) => handleDiagnosticoChange(index, 'recomendaciones', e.target.value)}
                    />

                    {/* Botón de eliminar diagnóstico */}
                    <button
                      type="button"
                      onClick={() => removeDiagnostico(index)}
                      className="text-red-500"
                    >
                      Eliminar diagnóstico
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addDiagnostico}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                >
                  Agregar diagnóstico
                </button>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeCreateModal}
                  className="bg-gray-500 text-white px-6 py-2 rounded-lg mr-4"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-6 py-2 rounded-lg"
                >
                  Crear Paciente
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoriasOffline;
