import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDataUser } from '../../context/dataUserContext';
import { alertaCargandoProceso, alertaGeneral } from '../../utils/alertas';

const ReenvioDocumentos = ({ doctor, setShow }) => {
    const [files, setFiles] = useState({});
    const { token } = useDataUser();

    const handleChange = (e) => {
        setFiles({
            ...files,
            [e.target.name]: e.target.files[0]
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();

        for (const key in files) {
            formData.append(key, files[key]);
        }

        alertaCargandoProceso({
            titulo: "Reenviando documentos",
            messageHtml: "Estamos reenviando los documentos, por favor espere un momento.",
            funcionAsync: () => {
                axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/afiliado/reenvio-documentos/${doctor._id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(res => {
                        alertaGeneral({
                            titulo: "Documentos reenviados",
                            messageHtml: "Los documentos han entrado en revisión nuevamente",
                        });
                        doctor.estado = "en_revision";
                        setShow(false);
                    })
                    .catch(error => {
                        alertaGeneral({
                            titulo: "Error",
                            messageHtml: error.response?.data?.message || "Ocurrió un error al reenviar los documentos, por favor intente de nuevo.",
                            isError: true
                        });
                    });
            }
        })
    }

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-200 shadow-md rounded-lg">
            <button
                type="button"
                className="mb-8 px-3 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setShow(false)}
            >
                Volver
            </button>
            <form className="space-y-6" onSubmit={handleSubmit} encType="multipart-form-data">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                    {/* Aqui se mostraran los inputs de los archivos que no pasaron */}
                    {doctor && doctor.documentsApproved.filter(f => !f.isApproved).map((file, index) => (
                        <div className="col-span-1 my-2">
                            <label className="block text-sm font-medium text-gray-700 uppercase"> {file?.document?.replace(/_/g, ' ')} </label>
                            <input
                                type="file"
                                name={file?.document}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                                accept=".pdf, .jpg, .jpeg, .png, .svg"
                                required
                            />
                            <p>
                                <b>Observacion:</b>{" "}
                                <span className="text-red-500">{file.rejectionReason}</span>
                            </p>
                        </div>
                    ))}

                </div>

                {/* Botón de Enviar */}
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Reenviar Documentos
                    </button>
                </div>
            </form >
        </div >
    )
}

export default ReenvioDocumentos;