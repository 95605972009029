import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { useCombobox } from 'downshift';
import Modal from 'react-modal';

const HistoriaClinicaForm = ({ appointmentId }) => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const sigCanvas = useRef({});
    const [fechaActual, setFechaActual] = useState('');
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [isTableVisible1, setIsTableVisible1] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const [resultadoInput, setResultadoInput] = useState('');
    const [fechaInput, setFechaInput] = useState('');
    const [fechaActual1, setFechaActual1] = useState('');
    const [maxLength, setMaxLength] = useState(10); // Valor inicial para 'CC'
    const [selectedOption, setSelectedOption] = useState('');
    const [imc, setImc] = useState(0);
    const [diagnosticos, setDiagnosticos] = useState([]);
    const [selectedDiagnostico, setSelectedDiagnostico] = useState(null);
    const [selectedTipo, setSelectedTipo] = useState('');
    const [enfermedadesOptions, setEnfermedadesOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const formValues = watch();
    const [acompanamiento, setAcompanamiento] = useState('');
    const [equipos, setEquipos] = useState('');
    const [mostrarFamiliares, setMostrarFamiliares] = useState(false);
    const [mostrarAlertas, setMostrarAlertas] = useState(false);
    const [mostrarAlergias, setMostrarAlergias] = useState(false);
    const [mostrarPsicosociales, setMostrarPsicosociales] = useState(false);
    const [pagador, setPagador] = useState('');
    const [tipoActividad, setTipoActividad] = useState('');
    const [alertas, setAlertas] = useState([]);
    const [alergias, setAlergias] = useState([]);
    const [resultadosParaclinicos, setResultadosParaclinicos] = useState([]);
    const [antecedentes, setAntecedentes] = useState([
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" }, // Patológicos
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" }, // Farmacológicos
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" }, // Quirúrgicos
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" },
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" }, // Hospitalarios
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" }, // Transfusionales
        { personal: "no", personalDescripcion: "", alerta: "no", alertaDescripcion: "" }  // Preventivos
    ]);

    const [sistemas, setSistemas] = useState([
        { sistema: "Órganos de los sentidos", seleccion: "no", observaciones: "" },
        { sistema: "Piel y faneras", seleccion: "no", observaciones: "" },
        { sistema: "Cardiopulmonar", seleccion: "no", observaciones: "" },
        { sistema: "Gastrointestinal", seleccion: "no", observaciones: "" },
        { sistema: "Genitourinario", seleccion: "no", observaciones: "" },
        { sistema: "Musculo esquelético", seleccion: "no", observaciones: "" },
        { sistema: "Neurológico", seleccion: "no", observaciones: "" },
        // ... otros sistemas ...
    ]);
    const [appointmentInfo, setAppointmentInfo] = useState({
        idType: '',
        idNumber: '',
        dob: '',
        firstLastName: '',
        secondLastName: '',
        firstName: '',
        secondName: '',
        gender: '',
        neighborhood: '',
        email: '',
        cellNumber: '',
        notes: '',
        fullName: '',
        service: '',
        rethusNumber: '',
        nit: '',
        grupoSanguineo: '',
        rh: '',
        grupoEtnico: '',
        ocupacion: '',
        estadoCivil: '',
        escolaridad: '',
        sex: '',
        condicionDesplazamiento: '',
        zonaUbicacion: '',
        clinicName: '',
        cellNumber1: '',
        patientAddress: ''

    });

    const customStyles = { content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', }, };

    const [isModalAlergiasOpen, setIsModalAlergiasOpen] = useState(false);

    const [isModalAlertasOpen, setIsModalAlertasOpen] = useState(false);
    const openModalAlergias = () => setIsModalAlergiasOpen(true);
    const closeModalAlergias = () => setIsModalAlergiasOpen(false);
    const openModalAlertas = () => setIsModalAlertasOpen(true);
    const closeModalAlertas = () => setIsModalAlertasOpen(false);

    const translateMedicina = (service) => {
        return service === '325 medicina_familiar' ? 'Medicina Familiar' : 'Medicina General';
    };
    // Log de los valores del formulario en la consola
    useEffect(() => {
        console.log('Valores del formulario:', formValues);
    }, [formValues]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/getDocumentsAppointmentConsentimiento/${appointmentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                console.log("Documentos recibidos del backend:", response.data.documents);
                setDocumentos(response.data.documents);
            })
            .catch(error => {
                console.error("Error al obtener documentos de la cita:", error);
            });
    }, [appointmentId]);




    const normalizeAndSplit = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ");
    };

    const fetchEnfermedadOptions = async (query) => {
        setLoading(true);
        try {
            const normalizedQueryWords = normalizeAndSplit(query);
            const token = localStorage.getItem('token');

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCie10`,
                {
                    params: { query: normalizedQueryWords.join(" ") }, // Unir palabras normalizadas
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const options = response.data.map((med) => ({
                value: med.Codigo,
                label: med.Nombre,
                descripcion: med.Descripcion
            }));


            console.log('Fetched options:', options); // Log para verificar las opciones
            setEnfermedadesOptions(options);
            console.log('Updated enfermedadesOptions:', options);

        } catch (error) {
            console.error('Error al obtener las enfermedades:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDenominacionChange = (selectedOption) => {
        setSelectedDiagnostico(selectedOption);
        console.log('Selected option:', selectedOption);
    };
    const fetchAppointmentInfo = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setAppointmentInfo(response.data);
        } catch (error) {
            console.error('Error al obtener la información de la cita:', error);
        }
    };

    useEffect(() => {
        fetchAppointmentInfo();
    }, [appointmentId]);

    useEffect(() => {
        fetchAppointmentInfo();
    }, [tipoActividad]);

    useEffect(() => {
        setValue('tipoIdentificacion', appointmentInfo.idType);
        setValue('numeroIdentificacion', appointmentInfo.idNumber);
        setValue('fechaNacimiento', appointmentInfo.dob);
        setValue('primerNombre', appointmentInfo.firstName);
        setValue('segundoNombre', appointmentInfo.secondName);
        setValue('primerApellido', appointmentInfo.firstLastName);
        setValue('segundoApellido', appointmentInfo.secondLastName);
        setValue('genero', appointmentInfo.gender);
        setValue('grupoSanguineo', appointmentInfo.grupoSanguineo);
        setValue('rh', appointmentInfo.rh);
        setValue('barrio', appointmentInfo.neighborhood);
        setValue('cellNumber1', appointmentInfo.cellNumber1);
        setValue('estadoCivil', appointmentInfo.estadoCivil);
        setValue('zonaUbicacion', appointmentInfo.zonaUbicacion);
        setValue('condicionDesplazamiento', appointmentInfo.condicionDesplazamiento);
        setValue('grupoEtnico', appointmentInfo.grupoEtnico);
        setValue('patientAddress', appointmentInfo.patientAddress);
        setValue('correoElectronico', appointmentInfo.email);
        setValue('telefono1', appointmentInfo.cellNumber);
        setValue('escolaridad', appointmentInfo.escolaridad);
        setValue('sex', appointmentInfo.sex);
        setValue('ocupacion', appointmentInfo.ocupacion);
        setValue('aseguradora', appointmentInfo.aseguradora);
        setValue('tipoUsuario', appointmentInfo.tipoUsuario);
        setValue('rethusNumber', appointmentInfo.rethusNumber);
        setValue('profesional', appointmentInfo.fullName);
        setValue('imc', imc);
        setValue('especialidad', appointmentInfo.service);
    }, [appointmentInfo, setValue]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevenir el envío del formulario
        }
    };


    const agregarDiagnostico = () => {
        if (selectedDiagnostico && selectedTipo) {
            const nuevoDiagnostico = {
                id: Date.now(), // Genera un ID único para el diagnóstico
                codigo: selectedDiagnostico.value,
                nombre: selectedDiagnostico.label,
                tipo: selectedTipo,
                relacionado: '', // Inicializa como vacío
            };

            // Actualizar la lista local de diagnósticos
            const nuevosDiagnosticos = [...diagnosticos, nuevoDiagnostico];
            setDiagnosticos(nuevosDiagnosticos);

            // Actualizar el registro en react-hook-form
            setValue('diagnosticos', nuevosDiagnosticos);
            reset();

            // Limpiar los campos después de agregar
            setSelectedDiagnostico(null);
            setSelectedTipo('');
        } else {
            alert('Por favor selecciona un diagnóstico y un tipo.');
        }
    };

    const actualizarRelacionado = (index, valorNuevo) => {
        setDiagnosticos(prevDiagnosticos => {
            const nuevosDiagnosticos = [...prevDiagnosticos];
            nuevosDiagnosticos[index].relacionado = valorNuevo; // Actualiza el campo relacionado
            setValue('diagnosticos', nuevosDiagnosticos); // Actualiza el valor en react-hook-form
            return nuevosDiagnosticos; // Retorna el nuevo estado
        });
    };



    const eliminarDiagnostico = (index) => {
        const nuevosDiagnosticos = diagnosticos.filter((_, i) => i !== index);
        setDiagnosticos(nuevosDiagnosticos);
        setValue('diagnosticos', nuevosDiagnosticos); // Actualizar el registro en react-hook-form
    };
    // Observa los cambios en peso y talla
    const peso = watch('pesoKg');
    const talla = watch('tallaCm');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };


    const handleAddResultado = () => {
        // Combina el resultado y la fecha en un string o en un objeto
        if (resultadoInput && fechaInput) {
            const nuevoResultado = `${fechaInput}: ${resultadoInput}`;
            setResultadosParaclinicos(prev => [...prev, nuevoResultado]);
            // Limpia los campos después de agregar
            setResultadoInput('');
            setFechaInput('');
        }
    };

    const handleAcompanamientoChange = (event) => {
        setAcompanamiento(event.target.value);
    };
    const handleEquiposChange = (event) => {
        setEquipos(event.target.value);
    };
    const handlePagadorChange = (event) => {
        setPagador(event.target.value);
    };
    const handleActividadChange = (event) => {
        setTipoActividad(event.target.value);
    };

    useEffect(() => {
        if (peso && talla) {
            // Convertir peso y talla a números
            const pesoNum = parseFloat(peso);
            const tallaNum = parseFloat(talla) / 100; // Convertir talla a metros

            // Calcular IMC
            if (tallaNum > 0) { // Asegúrate de que la talla sea mayor que 0 para evitar divisiones por cero
                const imcCalc = pesoNum / (tallaNum * tallaNum);
                setImc(imcCalc.toFixed(2)); // Establecer el IMC calculado con dos decimales
            } else {
                setImc(0); // Restablecer el IMC a 0 si la talla es inválida
            }
        }
    }, [peso, talla]);

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };
    const toggleTableVisibility1 = () => {
        setIsTableVisible1(!isTableVisible1);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
            event.preventDefault(); // Evitar que Enter envíe el formulario
        }
    };

    const onSubmit = async (data) => {

        try {
            const token = localStorage.getItem('token');
            const clinicId = localStorage.getItem('clinicId');
            const imc1 = imc;

            // Enviar la historia clínica y generar el PDF en una sola llamada
            const pdfResponse = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generateHistoriaClinicaPDF`,
                method: 'POST',
                data: {
                    historiaClinicaData: {
                        ...data, // Desestructura todos los campos de data
                        patientId: appointmentInfo.patientId,
                        appointmentId,
                        doctorId: appointmentInfo.doctorId,
                        fechaRegistro: fechaActual,
                        imc: imc1
                    },
                    clinicId: clinicId,
                    doctorInfo: {
                        doctorId: appointmentInfo.doctorId,
                        fullName: appointmentInfo.fullName,
                        service: appointmentInfo.service,
                        idNumber: appointmentInfo.idNumber,
                        cellNumber: appointmentInfo.cellNumber,
                        notes: appointmentInfo.notes,
                    },
                },
                responseType: 'blob', // Para manejar archivos binarios como PDFs
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // Generar el enlace para descargar el PDF
            const url = window.URL.createObjectURL(new Blob([pdfResponse.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `historia-clinica-${moment().format('YYYY-MM-DD')}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Mostrar alerta de éxito
            Swal.fire({
                title: '¡PDF Generado!',
                text: 'La historia clínica ha sido creada y el archivo PDF ha sido descargado exitosamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });

        } catch (error) {
            console.error('Error al procesar la solicitud:', error);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al procesar la solicitud. Por favor, intenta nuevamente.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };
    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
        reset,
    } = useCombobox({
        items: enfermedadesOptions,
        onInputValueChange: ({ inputValue }) => {
            fetchEnfermedadOptions(inputValue); // Fetch options as user types
        },
        onSelectedItemChange: ({ selectedItem }) => {
            setSelectedDiagnostico(selectedItem);
        },
        itemToString: (item) => (item ? item.label : ''),
    });

    useEffect(() => {
        const fetchHistorialClinico = async () => {
            try {
                const token = localStorage.getItem('token');
                const patientId = appointmentInfo.patientId; // Obtener patientId de appointmentInfo
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAlertasAlergiasResultados/${patientId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Almacena los datos en los estados
                setAlertas(response.data.alertas);
                setAlergias(response.data.alergias);
                setResultadosParaclinicos(response.data.resultadosParaclinicos);
            } catch (error) {
                console.error('Error al obtener los datos de la historia clínica:', error);

            } finally {
                setLoading(false);
            }
        };

        fetchHistorialClinico();
    }, [appointmentInfo]); // Ejecutar cuando appointmentInfo cambie

    useEffect(() => {
        const ahora = new Date();
        const year = ahora.getFullYear();
        const month = String(ahora.getMonth() + 1).padStart(2, '0'); // Mes empieza en 0
        const day = String(ahora.getDate()).padStart(2, '0');
        const hours = String(ahora.getHours()).padStart(2, '0');
        const minutes = String(ahora.getMinutes()).padStart(2, '0');

        // Formato para datetime-local: YYYY-MM-DDTHH:mm
        const fechaFormateada = `${year}-${month}-${day}T${hours}:${minutes}`;
        const fechaFormateada1 = `${year}-${month}-${day}`;
        setFechaActual(fechaFormateada);
        setFechaActual1(fechaFormateada1);
    }, []);

    const handleClearSignature = () => {
        sigCanvas.current.clear();
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return ''; // Verifica que la fecha sea válida
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    };

    const handleSeleccionChange = (index, value) => {
        const nuevosSistemas = [...sistemas];
        nuevosSistemas[index] = { ...nuevosSistemas[index], seleccion: value };
        setSistemas(nuevosSistemas);
    };

    const translateGender = (gender) => {
        return gender === 'male' ? 'Masculino' : 'Femenino';
    };

    const handleDescripcionChange = (index, value) => {
        // Crear una copia del estado actual
        const nuevosSistemas = [...sistemas];

        // Verifica si 'nuevosSistemas' es un array antes de manipularlo
        if (Array.isArray(nuevosSistemas)) {
            nuevosSistemas[index].descripcion = value;
            setSistemas(nuevosSistemas);
            setValue(`sistemas.${index}.descripcion`, value); // Sincronizar con react-hook-form
        } else {
            console.error('nuevosSistemas no es un array');
        }
    };

    const handleAntecedentesChange = (e, index, field) => {
        const updatedAntecedentes = [...antecedentes];
        updatedAntecedentes[index][field] = e.target.value;
        setAntecedentes(updatedAntecedentes);
    };

    const handleCheckboxChange = (e, tipo) => {
        const { checked } = e.target;

        if (tipo === 'familiares') {
            setMostrarFamiliares(checked);
        } else if (tipo === 'psicosociales') {
            setMostrarPsicosociales(checked);
        } else if (tipo === 'alertas') {
            setMostrarAlertas(checked);
        } else if (tipo === 'alergias') {
            setMostrarAlergias(checked);
        }
    };


    const obtenerFechaActual = () => {
        const fecha = new Date();
        // Ajustar la fecha a la zona horaria local
        const timezoneOffset = fecha.getTimezoneOffset() * 60000; // Diferencia de zona horaria en milisegundos
        const fechaLocal = new Date(fecha.getTime() - timezoneOffset);
        const fechaFormateada = fechaLocal.toISOString().slice(0, 16); // Formato adecuado para datetime-local
        setFechaActual(fechaFormateada);
    };

    useEffect(() => {
        obtenerFechaActual();
    }, [appointmentInfo]);

    const onHandleClickView = (url) => {
        window.open(url, '_blank');
    };
    return (
        <div className="max-w-2xl mx-auto bg-white shadow-md p-4 ">
            <div className="fixed top-0 left-0 right-0 bg-white shadow-md z-10 p-4 mb-4">
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">
                        Paciente: {appointmentInfo.firstName} {appointmentInfo.secondName} {appointmentInfo.firstLastName} {appointmentInfo.secondLastName}
                    </h2>
                    <div>
                        <button
                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                            onClick={openModalAlergias}
                        >
                            Ver Alergias
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                            onClick={openModalAlertas}
                        >
                            Ver Alertas
                        </button>
                    </div>
                </div>
            </div>

            {/* Aquí va el contenido principal debajo del encabezado */}
            <div className="mt-24"> {/* Asegúrate de que este margen sea suficiente para que el contenido no se oculte debajo del encabezado fijo */}
                {/* Modales */}
                <Modal
                    isOpen={isModalAlergiasOpen}
                    onRequestClose={closeModalAlergias}
                    style={customStyles}
                    contentLabel="Alergias"
                >
                    <h2 className="text-2xl font-bold mb-4">Alergias</h2>
                    <button
                        onClick={closeModalAlergias}
                        className="bg-gray-500 text-white font-semibold py-1 px-3 rounded mb-4"
                    >
                        Cerrar
                    </button>
                    <ul className="list-disc pl-5">
                        {alergias.map((alergia, index) => (
                            <li key={index} className="text-lg">
                                {alergia}
                            </li>
                        ))}
                    </ul>
                </Modal>

                <Modal
                    isOpen={isModalAlertasOpen}
                    onRequestClose={closeModalAlertas}
                    style={customStyles}
                    contentLabel="Alertas"
                >
                    <h2 className="text-2xl font-bold mb-4">Alertas</h2>
                    <button
                        onClick={closeModalAlertas}
                        className="bg-gray-500 text-white font-semibold py-1 px-3 rounded mb-4"
                    >
                        Cerrar
                    </button>
                    <ul className="list-disc pl-5">
                        {alertas.map((alerta, index) => (
                            <li key={index} className="text-lg">
                                {alerta}
                            </li>
                        ))}
                    </ul>
                </Modal>
            </div>


            <h2 className="text-xl font-semibold text-center mb-4">HISTORIA CLINICA MEDICINA</h2>
            <hr className="mb-4" />

            {/* Tabla de Documentos Cargados */}
            {documentos && documentos.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-2">Documentos cargados por el paciente</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-lg">
                            <thead className="bg-gray-200 text-center">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">Documento</th>
                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">Acción</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-300">
                                {documentos.map((document, index) => (
                                    <tr key={index} className="divide-x divide-gray-200">
                                        <td className="px-4 py-2 text-center font-medium text-gray-500">Documento {index + 1}</td>
                                        <td className="px-4 py-2 flex justify-center">

                                            <button
                                                className="bg-blue-500 hover:bg-blue-700  text-white font-bold py-1 px-2 rounded"
                                                onClick={() => onHandleClickView(document)}
                                            >
                                                Ver
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
                {/* Pagador */}



                {/* Fecha y hora de registro */}
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Fecha y hora de registro</label>
                    <input
                        type="datetime-local"
                        readOnly
                        value={fechaActual}
                        {...register('fechaRegistro')}
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                    />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de Actividad</label>
                    <select {...register('tipoActividad')}
                        onChange={handleActividadChange}
                        className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Seleccione una opción</option>
                        <option value="Primera Vez">Primera Vez</option>
                        <option value="Control">Control</option>
                    </select>
                </div>
                {tipoActividad === 'Primera Vez' && (
                    <>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Pagador</label>
                            <select {...register('pagador')}
                                className="w-full p-2 border border-gray-300 rounded"
                                onChange={handlePagadorChange}
                            >

                                <option value="">Seleccione una opción</option>

                                <option value="Particular">Particular</option>
                                <option value="Convenio">Convenio</option>
                            </select>
                        </div>
                    </>
                )}
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Acompañamiento en la Consulta</label>
                    <select
                        {...register('acompanamientoEnConsulta')}
                        className="w-full p-2 border border-gray-300 rounded"
                        onChange={handleAcompanamientoChange}
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="Solo">Solo</option>
                        <option value="Acompañado con familiar">Acompañado con familiar</option>
                        <option value="Acompañado con profesional de la salud">Acompañado con profesional de la salud</option>
                        <option value="En consulta con médico">En consulta con médico</option>
                    </select>
                </div>


                <hr className="my-4" />

                {/* Datos del Paciente */}
                <h3 className="text-lg font-semibold mb-4">DATOS DEL PACIENTE</h3>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de Identificación</label>
                    <input
                        {...register('tipoIdentificacion')}
                        type="text"
                        value={appointmentInfo.idType.toUpperCase()} // Esto mostrará el valor actual
                        readOnly // Si deseas que sea de solo lectura
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                    />
                </div>


                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Número de Identificación</label>
                    <input
                        {...register('numeroIdentificacion')}
                        type="text"
                        value={appointmentInfo.documentNumber}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                    />
                </div>
                {tipoActividad === 'Primera Vez' && (
                    <>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Fecha de nacimiento</label>
                            <input type="text"

                                value={moment.utc(appointmentInfo.dob).format('DD-MM-YYYY')}
                                readOnly
                                {...register('fechaNacimiento')}
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>
                    </>
                )}
                {tipoActividad === 'Primera Vez' && (
                    <>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Grupo sanguíneo</label>
                            <input
                                {...register('grupoSanguineo')}
                                type="text"
                                value={appointmentInfo.grupoSanguineo}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                            />
                        </div>


                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">RH</label>
                            <input
                                {...register('rh')}
                                type="text"
                                value={appointmentInfo.rh}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                            />
                        </div>
                    </>
                )}

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Primer nombre *</label>
                    <input {...register('primerNombre')}
                        value={appointmentInfo.firstName}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Segundo nombre</label>
                    <input {...register('segundoNombre')}
                        value={appointmentInfo.secondName}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Primer apellido *</label>
                    <input {...register('primerApellido')}
                        value={appointmentInfo.firstLastName}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Segundo apellido</label>
                    <input {...register('segundoApellido')}
                        value={appointmentInfo.secondLastName}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Edad actual</label>
                    <input {...register('edadActual')} className="w-full p-2 border border-gray-300 rounded" />
                </div>*/}
                {tipoActividad === 'Primera Vez' && (
                    <>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Género</label>
                            <input {...register('genero')}
                                value={translateGender(appointmentInfo.gender)}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded">

                            </input>
                        </div>

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Sexo</label>
                            <input
                                {...register('sex')}
                                type="text"
                                value={appointmentInfo.sex}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                            />
                        </div>


                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Estado civil</label>
                            <input
                                {...register('estadoCivil')}
                                type="text"
                                value={appointmentInfo.estadoCivil}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                            />
                        </div>


                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Zona de ubicación</label>
                            <input
                                {...register('zonaUbicacion')}
                                type="text"
                                value={appointmentInfo.zonaUbicacion}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                            />
                        </div>

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Dirección</label>
                            <input
                                {...register('patientAddress')}
                                type="text"
                                value={appointmentInfo.patientAddress}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
                            />
                        </div>

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Barrio</label>
                            <input {...register('barrio')}
                                value={appointmentInfo.neighborhood}
                                readOnly
                                className="w-full p-2 border border-gray-300 bg-gray-200 rounded" />
                        </div>

                        {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Localidad</label>
                    <input {...register('localidad')} className="w-full p-2 border border-gray-300 rounded" />
                </div>*/}

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Correo electrónico</label>
                            <input type="email" {...register('correoElectronico')}
                                value={appointmentInfo.email}
                                readOnly
                                className="w-full p-2 border border-gray-300  bg-gray-200 rounded" />
                        </div>

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Teléfono 1 *</label>
                            <input {...register('telefono1')}
                                value={appointmentInfo.cellNumber}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>


                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Teléfono 2</label>
                            <input {...register('cellNumber1')}
                                value={appointmentInfo.cellNumber1}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />                </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Escolaridad</label>
                            <input {...register('escolaridad')}
                                value={appointmentInfo.escolaridad}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">
                                Ocupación</label>
                            <input {...register('ocupacion')}
                                value={appointmentInfo.ocupacion}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Condición de desplazamiento</label>
                            <input {...register('condicionDesplazamiento')}
                                value={appointmentInfo.condicionDesplazamiento}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Grupo étnico</label>
                            <input {...register('grupoEtnico')}
                                value={appointmentInfo.grupoEtnico}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">
                                Aseguradora
                            </label>
                            <input {...register('aseguradora')}
                                value={appointmentInfo.aseguradora}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Tipo de usuario</label>
                            <input {...register('tipoUsuario')}
                                value={appointmentInfo.tipoUsuario}
                                readOnly
                                className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                        </div>
                        {pagador === 'Convenio' && (
                            <>
                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Tipo de afiliado</label>
                                    <select {...register('tipoDeAfiliado')} className="w-full p-2 border border-gray-300 rounded">
                                        <option value="">Selecciona un tipo de usuario</option>
                                        <option value="Cotizante">Cotizante</option>
                                        <option value="Beneficiario">Beneficiario</option>
                                        <option value="Adicional">Adicional</option>

                                    </select>
                                </div>
                            </>
                        )}

                        <hr className="my-4" />
                        {acompanamiento === 'Acompañado con familiar' && (
                            <>

                                <h3 className="text-lg font-semibold mb-4">DATOS DEL ACOMPAÑANTE</h3>

                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Primer nombre </label>
                                    <input {...register('acompanantePrimerNombre')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>

                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Segundo nombre</label>
                                    <input {...register('acompananteSegundoNombre')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>

                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Primer apellido</label>
                                    <input {...register('acompanantePrimerApellido')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>

                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Segundo apellido</label>
                                    <input {...register('acompananteSegundoApellido')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>

                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Parentesco</label>
                                    <select
                                        {...register('parentesco')}
                                        onChange={handleOptionChange}
                                        className="w-full p-2 border border-gray-300 rounded"
                                    >
                                        <option value="">Selecciona un parentesco</option>
                                        <option value="Padre">Padre</option>
                                        <option value="Madre">Madre</option>
                                        <option value="Hijo/hija">Hijo/hija</option>
                                        <option value="Otro cual">Otro</option>
                                        <option value="Ninguno">Ninguno</option>
                                    </select>
                                    {selectedOption === "Otro cual" && (
                                        <input
                                            {...register('otroParentesco')}
                                            type="text"
                                            placeholder="Especifica cuál"
                                            className="mt-2 w-full p-2 border border-gray-300 rounded"
                                        />
                                    )}
                                </div>

                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Teléfono</label>
                                    <input {...register('acompananteTelefono')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>
                            </>
                        )}
                    </>
                )}
                <hr className="my-4" />
                <div className="flex flex-col mt-4">
                    <h3 className="text-lg font-semibold mb-4">MOTIVO QUE ORIGINA LA ATENCIÓN</h3>
                    <select {...register('motivoAtencion')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un motivo</option>
                        <option value="21_Accidente de trabajo">21 - Accidente de trabajo</option>
                        <option value="22_Accidente en el hogar">22 - Accidente en el hogar</option>
                        <option value="23_Accidente de tránsito de origen común">23 - Accidente de tránsito de origen común</option>
                        <option value="24_Accidente de tránsito de origen laboral">24 - Accidente de tránsito de origen laboral</option>
                        <option value="25_Accidente en el entorno educativo">25 - Accidente en el entorno educativo</option>
                        <option value="26_Otro tipo de accidente">26 - Otro tipo de accidente</option>
                        <option value="27_Evento catastrófico de origen natural">27 - Evento catastrófico de origen natural</option>
                        <option value="28_Lesión por agresión">28 - Lesión por agresión</option>
                        <option value="29_Lesión auto infligida">29 - Lesión auto infligida</option>
                        <option value="30_Sospecha de violencia física">30 - Sospecha de violencia física</option>
                        <option value="31_Sospecha de violencia psicológica">31 - Sospecha de violencia psicológica</option>
                        <option value="32_Sospecha de violencia sexual">32 - Sospecha de violencia sexual</option>
                        <option value="33_Sospecha de negligencia y abandono">33 - Sospecha de negligencia y abandono</option>
                        <option value="34_IVE relacionado con peligro a la Salud o vida de la mujer">34 - IVE relacionado con peligro a la Salud o vida de la mujer</option>
                        <option value="35_IVE por malformación congénita incompatible con la vida">35 - IVE por malformación congénita incompatible con la vida</option>
                        <option value="36_IVE por violencia sexual, incesto o por inseminación artificial o transferencia de ovulo fecundado no consentida">
                            36 - IVE por violencia sexual, incesto o por inseminación artificial o transferencia de ovulo fecundado no consentida
                        </option>
                        <option value="37_Evento adverso en salud">37 - Evento adverso en salud</option>
                        <option value="38_Enfermedad general">38 - Enfermedad general</option>
                        <option value="39_Enfermedad laboral">39 - Enfermedad laboral</option>
                        <option value="40_Promoción y mantenimiento de la salud – intervenciones individuales">
                            40 - Promoción y mantenimiento de la salud – intervenciones individuales
                        </option>
                        <option value="41_Intervención colectiva">41 - Intervención colectiva</option>
                        <option value="42_Atención de población materno perinatal">42 - Atención de población materno perinatal</option>
                        <option value="43_Riesgo ambiental">43 - Riesgo ambiental</option>
                        <option value="44_Otros eventos Catastróficos">44 - Otros eventos Catastróficos</option>
                        <option value="45_Accidente de mina antipersonal – MAP">45 - Accidente de mina antipersonal – MAP</option>
                        <option value="46_Accidente de Artefacto Explosivo Improvisado – AEI">
                            46 - Accidente de Artefacto Explosivo Improvisado – AEI
                        </option>
                        <option value="47_Accidente de Munición Sin Explotar- MUSE">47 - Accidente de Munición Sin Explotar- MUSE</option>
                        <option value="48_Otra víctima de conflicto armado colombiano">
                            48 - Otra víctima de conflicto armado colombiano
                        </option>
                    </select>
                </div>

                <hr className="my-4" />

                {/* Servicio */}
                <h3 className="text-lg font-semibold mb-4">SERVICIO</h3>
                {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Modalidad de prestación</label>
                    <select {...register('modalidad')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona una modalidad</option>
                        <option value="Telemedicina: Interactiva">Telemedicina: Interactiva</option>
                        <option value="Telemedicina: No interactiva">Telemedicina: No interactiva</option>
                        <option value="Telemedicina: Telexperticia">Telemedicina: Telexperticia</option>
                    </select>
                </div>*/}
                <div className="flex flex-col mt-4">

                    <input {...register('servicio')}
                        value={translateMedicina(appointmentInfo.service)}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                {tipoActividad === 'Primera Vez' && (
                    <>
                        <hr className="my-4" />
                        <h3 className="text-lg font-semibold mb-4">MOTIVO DE CONSULTA</h3>
                        <input
                            {...register('motivoConsulta')}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Escribe el motivo de consulta aquí..." // Añadir un placeholder opcional
                        />

                        <hr className="my-4" />
                        <h3 className="text-lg font-semibold mb-4">ENFERMEDAD ACTUAL</h3>
                        <textarea {...register('enfermedadActual')}
                            className="w-full p-2 border border-gray-300 rounded" />
                        <hr className="my-4" />
                        <div
                            className="flex justify-between items-center cursor-pointer mb-4"
                            onClick={toggleTableVisibility}
                        >
                            <h3 className="text-lg font-semibold">REVISION POR SISTEMAS</h3>
                            {/* Icono de flecha que cambia según la visibilidad */}
                            <span>{isTableVisible ? '⬇️' : '➡️'}</span>
                        </div>

                        {/* Tabla que se oculta o muestra dependiendo del estado */}
                        {isTableVisible && (
                            <table className="w-full border-collapse border border-gray-300 mb-4">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-300 p-2">Sistema</th>
                                        <th className="border border-gray-300 p-2">Sí/No</th>
                                        <th className="border border-gray-300 p-2">Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[
                                        "Órganos de los sentidos",
                                        "Piel y faneras",
                                        "Cardiopulmonar",
                                        "Gastrointestinal",
                                        "Genitourinario",
                                        "Musculo esquelético",
                                        "Neurológico",
                                    ].map((sistema, index) => (
                                        <tr key={index}>
                                            <td className="border border-gray-300 p-2">{sistema}</td>
                                            <td className="border border-gray-300 p-2">
                                                <select
                                                    {...register(`sistemas.${index}.seleccion`)}
                                                    className="w-full p-1 border border-gray-300 rounded-md"
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        setValue(`sistemas.${index}.seleccion`, newValue);
                                                        if (newValue === "Sí") {
                                                            setValue(`sistemas.${index}.observaciones`, "");
                                                        } else {
                                                            setValue(`sistemas.${index}.observaciones`, null);
                                                        }
                                                    }}
                                                >
                                                    <option value="no">No</option>
                                                    <option value="Sí">Sí</option>
                                                </select>
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                <input
                                                    {...register(`sistemas.${index}.sistema`)}
                                                    type="hidden"
                                                    defaultValue={sistema}
                                                />
                                                <input
                                                    {...register(`sistemas.${index}.observaciones`)}
                                                    type="text"
                                                    className="w-full p-1 border border-gray-300 rounded-md"
                                                    placeholder={sistema}
                                                    disabled={watch(`sistemas.${index}.seleccion`) !== "Sí"}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}



                        <hr className="my-4" />
                        <div
                            className="flex justify-between items-center cursor-pointer mb-4"
                            onClick={toggleTableVisibility1}
                        >
                            <h3 className="text-lg font-semibold">ANTECEDENTES</h3>
                            {/* Icono de flecha que cambia según la visibilidad */}
                            <span>{isTableVisible1 ? '⬇️' : '➡️'}</span>
                        </div>

                        {isTableVisible1 && (
                            <table className="w-full border-collapse border border-gray-300 mb-4">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-300 p-2"></th>
                                        <th className="border border-gray-300 p-2">Personal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[
                                        "Patológicos",
                                        "Farmacológicos",
                                        "Quirúrgicos",
                                        "Tóxicos",  // Nueva fila para Tóxicos
                                        "Alérgicos", // Nueva fila para Alérgicos
                                        "Hospitalarios",
                                        "Transfusionales",
                                        "Preventivos",
                                    ].map((category, index) => (
                                        <tr key={index}>
                                            <td className="border border-gray-300 p-2">{category}</td>
                                            <td className="border border-gray-300 p-2">
                                                <input type="hidden" {...register(`antecedentes.${index}.tipo`)} value={category} />
                                                <select
                                                    {...register(`antecedentes.${index}.personalCheck`)}
                                                    className="w-full p-1 border border-gray-300 rounded-md"
                                                    onChange={(e) => handleAntecedentesChange(e, index, 'personal')}
                                                >
                                                    <option value="no">No</option>
                                                    <option value="si">Sí</option>
                                                </select>
                                                {antecedentes[index]?.personal && antecedentes[index].personal === "si" && (
                                                    <input
                                                        {...register(`antecedentes.${index}.personalDescripcion`)}
                                                        type="text"
                                                        placeholder="Descripción"
                                                        className="w-full p-1 border border-gray-300 rounded-md mt-2"
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        {appointmentInfo.sex === 'Femenino' && (
                            <>
                                <table className="w-full border-collapse border border-gray-300 mb-4">
                                    <thead>
                                        <tr>
                                            <th className="border font-semibold border-gray-300 p-2" colSpan={9}>Ginecoobstétricos</th>
                                        </tr>
                                        <tr>
                                            <th className="border border-gray-300 p-2">G</th>
                                            <th className="border border-gray-300 p-2">P</th>
                                            <th className="border border-gray-300 p-2">A</th>
                                            <th className="border border-gray-300 p-2">C</th>
                                            <th className="border border-gray-300 p-2">V</th>
                                            <th className="border border-gray-300 p-2">M</th>
                                            <th className="border border-gray-300 p-2">FUR</th>
                                            <th className="border border-gray-300 p-2">FUP</th>
                                            <th className="border border-gray-300 p-2">FPP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="text"
                                                    {...register("ginecoobstetricos.0.g")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="text"
                                                    {...register("ginecoobstetricos.0.p")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="text"
                                                    {...register("ginecoobstetricos.0.a")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="text"
                                                    {...register("ginecoobstetricos.0.c")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="text"
                                                    {...register("ginecoobstetricos.0.v")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="text"
                                                    {...register("ginecoobstetricos.0.m")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="date"
                                                    {...register("ginecoobstetricos.0.fur")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="date"
                                                    {...register("ginecoobstetricos.0.fup")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input
                                                    type="date"
                                                    {...register("ginecoobstetricos.0.fpp")} // Cambia aquí
                                                    className="w-full p-1 border border-gray-300 rounded-md text-sm"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Planificación
                                    </label>
                                    <input {...register('planificacion')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>
                                <div className="flex flex-col mt-4">
                                    <label className="block text-gray-700">Ciclos
                                    </label>
                                    <input {...register('ciclos')} className="w-full p-2 border border-gray-300 rounded" />
                                </div>
                            </>
                        )}
                        <hr className="my-4" />
                        <h3 className="text-lg font-semibold mb-4">ANTECEDENTES FAMILIARES</h3>
                        <div className="flex items-center mb-4">
                            <label className="mr-2 text-gray-700">¿Tiene antecedentes familiares?</label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, 'familiares')}
                                checked={mostrarFamiliares}
                                className="mr-2"
                            />
                            <label>{mostrarFamiliares ? "No" : "Sí"}</label>
                        </div>

                        {mostrarFamiliares && (
                            <div className="flex flex-col mt-4">
                                <textarea
                                    {...register('familiares')}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    placeholder="Describa los antecedentes familiares"
                                />
                            </div>
                        )}

                        <hr className="my-4" />
                        <h3 className="text-lg font-semibold mb-4">ANTECEDENTES PSICOSOCIALES</h3>
                        <div className="flex items-center mb-4">
                            <label className="mr-2 text-gray-700">¿Tiene antecedentes psicosociales?</label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, 'psicosociales')}
                                checked={mostrarPsicosociales}
                                className="mr-2"
                            />
                            <label>{mostrarPsicosociales ? "No" : "Sí"}</label>
                        </div>

                        {mostrarPsicosociales && (
                            <div className="flex flex-col mt-4">
                                <textarea
                                    {...register('psicosociales')}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    placeholder="Describa los antecedentes psicosociales"
                                />
                            </div>
                        )}
                    </>
                )}


                <hr className="my-4" />


                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">EXAMEN FISICO
                </h3>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Estado de conciencia</label>
                    <select {...register('estadoDeConciencia')} className="w-full p-2 border border-gray-300 rounded">
                        <option value="">Selecciona un estado</option>
                        <option value="Alerta">Alerta</option>
                        <option value="Consiente">Consiente</option>
                        <option value="Orientado">Orientado</option>
                        <option value="Comatoso">Comatoso</option>
                        <option value="Somnolencia (obnubilación)">Somnolencia (obnubilación)</option>
                        <option value="Estupor">Estupor</option>
                        <option value="Delirio">Delirio</option>
                    </select>
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tiene equipos para tomar signos vitales</label>
                    <select
                        {...register('equiposSignos')}
                        className="w-full p-2 border border-gray-300 rounded"
                        onChange={handleEquiposChange}
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                    </select>
                </div>
                {equipos === 'Si' && (
                    <>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">TAS - mming</label>
                            <input
                                type="number"
                                {...register('tasMming', {
                                    min: { value: 60, message: 'El valor mínimo es 60' }, // Mensaje para valor mínimo
                                    max: { value: 300, message: 'El valor máximo es 300' } // Mensaje para valor máximo
                                })}
                                className="w-full p-2 border border-gray-300 rounded"
                            />

                            {errors.tasMming && <span className="text-red-500">{errors.tasMming.message}</span>}
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">TAD</label>
                            <input
                                type="number"
                                {...register('tad', {
                                    min: { value: 60, message: 'El valor mínimo es 40' }, // Mensaje para valor mínimo
                                    max: { value: 300, message: 'El valor máximo es 160' } // Mensaje para valor máximo
                                })}
                                className="w-full p-2 border border-gray-300 rounded"
                            />

                            {errors.tad && <span className="text-red-500">{errors.tad.message}</span>}
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">FC/min</label>
                            <input
                                type="number"
                                {...register('fcMin', {
                                    min: { value: 30, message: 'El valor mínimo es 30' }, // Mensaje para valor mínimo
                                    max: { value: 300, message: 'El valor máximo es 300' } // Mensaje para valor máximo
                                })}
                                className="w-full p-2 border border-gray-300 rounded"
                            />

                            {errors.fcMin && <span className="text-red-500">{errors.fcMin.message}</span>}
                        </div>


                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">FR/min

                            </label>
                            <input {...register('frMin')} className="w-full p-2 border border-gray-300 rounded" />
                        </div>
                        {/*<div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Saturación
                    </label>
                    <input {...register('saturacion')} className="w-full p-2 border border-gray-300 rounded" />
                </div>*/}
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Temperatura
                            </label>
                            <input {...register('temperatura')} className="w-full p-2 border border-gray-300 rounded" />
                        </div>

                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Peso Kg</label>
                            <input
                                {...register('pesoKg')}
                                className="w-full p-2 border border-gray-300 rounded"
                                type="number" // Asegúrate de que el tipo de entrada sea numérico
                            />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Talla cms</label>
                            <input
                                {...register('tallaCm')}
                                className="w-full p-2 border border-gray-300 rounded"
                                type="number" // Asegúrate de que el tipo de entrada sea numérico
                            />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">IMC</label>
                            <input
                                {...register('imc')}
                                className="w-full p-2 border border-gray-300 rounded"
                                value={imc} // Establecer el valor del IMC calculado
                                readOnly // Hacer que el campo sea de solo lectura
                            />
                        </div>
                    </>
                )}
                {(acompanamiento === 'Acompañado con familiar' || acompanamiento === 'Solo') && (
                    <>
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700">Inspeccion General</label>
                            <textarea
                                {...register('inspeccionGeneral')}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>
                    </>
                )}
                <hr className="my-4" />
                {acompanamiento === 'Acompañado con profesional de la salud' && (
                    <>
                        <table className="w-full border-collapse border border-gray-300 mb-4">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">Sistema</th>
                                    <th className="border border-gray-300 p-2">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                            <td className="border border-gray-300 p-2">Cabeza y cuello</td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    {...register("examenMedico.cabezaYCuello")}
                                    className="w-full p-1 border border-gray-300 rounded-md"
                                />
                            </td>
                        </tr>*/}
                                <tr>
                                    <td className="border border-gray-300 p-2">Tórax y cardio vascular</td>
                                    <td className="border border-gray-300 p-2">
                                        <input
                                            type="text"
                                            {...register("examenMedico.toraxCardioVascular")}
                                            className="w-full p-1 border border-gray-300 rounded-md"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">Abdomen</td>
                                    <td className="border border-gray-300 p-2">
                                        <input
                                            type="text"
                                            {...register("examenMedico.abdomen")}
                                            className="w-full p-1 border border-gray-300 rounded-md"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">Genitales y extremidades</td>
                                    <td className="border border-gray-300 p-2">
                                        <input
                                            type="text"
                                            {...register("examenMedico.genitalesExtremidades")}
                                            className="w-full p-1 border border-gray-300 rounded-md"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">Neurológico</td>
                                    <td className="border border-gray-300 p-2">
                                        <input
                                            type="text"
                                            {...register("examenMedico.neurologico")}
                                            className="w-full p-1 border border-gray-300 rounded-md"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 p-2">Examen mental</td>
                                    <td className="border border-gray-300 p-2">
                                        <input
                                            type="text"
                                            {...register("examenMedico.examenMental")}
                                            className="w-full p-1 border border-gray-300 rounded-md"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">RESULTADOS PARACLÍNICOS

                </h3>
                <div className="mb-4">
                    {resultadosParaclinicos && resultadosParaclinicos.length > 0 ? (
                        <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                            <p className="font-medium text-gray-600">Resultados paraclínicos anteriores:</p>
                            {resultadosParaclinicos.map((resultado, index) => (
                                <p key={index} className="text-gray-700">{resultado}</p>
                            ))}
                        </div>
                    ) : (
                        <p>No hay resultados paraclínicos registrados anteriormente.</p>
                    )}
                </div>
                <div className="flex flex-col mt-4">

                    <textarea {...register('resultadosParaclinicos')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">ALERTAS</h3>
                <div className="mb-4">
                    {alertas && alertas.length > 0 ? (
                        <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                            <p className="font-medium text-gray-600">Alertas anteriores:</p>
                            {alertas.map((resultado, index) => (
                                <p key={index} className="text-gray-700">{resultado}</p>
                            ))}
                        </div>
                    ) : (
                        <p>No hay resultados registrados anteriormente.</p>
                    )}
                </div>
                <div className="flex items-center mb-4">
                    <label className="mr-2 text-gray-700">¿Tiene alertas?</label>
                    <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, 'alertas')}
                        checked={mostrarAlertas}
                        className="mr-2"
                    />
                    <label>{mostrarAlertas ? "No" : "Sí"}</label>
                </div>

                {mostrarAlertas && (
                    <div className="flex flex-col mt-4">
                        <textarea
                            {...register('alertas')}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Describa las alertas"
                        />
                    </div>
                )}

                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">ALERGIAS</h3>
                <div className="mb-4">
                    {alergias && alergias.length > 0 ? (
                        <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                            <p className="font-medium text-gray-600">Alergias anteriores:</p>
                            {alergias.map((resultado, index) => (
                                <p key={index} className="text-gray-700">{resultado}</p>
                            ))}
                        </div>
                    ) : (
                        <p>No hay resultados registrados anteriormente.</p>
                    )}
                </div>
                <div className="flex items-center mb-4">
                    <label className="mr-2 text-gray-700">¿Tiene alergias?</label>
                    <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, 'alergias')}
                        checked={mostrarAlergias}
                        className="mr-2"
                    />
                    <label>{mostrarAlergias ? "No" : "Sí"}</label>
                </div>

                {mostrarAlergias && (
                    <div className="flex flex-col mt-4">
                        <textarea
                            {...register('alergias')}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Describa las alergias"
                        />
                    </div>
                )}
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">ANÁLISIS Y PLAN</h3>
                <div className="flex flex-col mt-4">

                    <textarea {...register('analisisyplan')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">DIAGNÓSTICOS</h3>

                <label className="block text-gray-700">CIE-10 (Código / Nombre):</label>
                <div className="mt-1 block w-full">
                    <input
                        {...getInputProps()}
                        placeholder="Buscar..."
                        className="border rounded p-2 w-full"
                    />
                    <ul {...getMenuProps()} className="border rounded max-h-48 overflow-y-auto">
                        {isOpen && (loading ? (
                            <li>Cargando...</li>
                        ) : (
                            enfermedadesOptions.map((option, index) => (
                                <li
                                    key={option.value}
                                    {...getItemProps({ item: option, index })}
                                    className={`p-2 cursor-pointer ${highlightedIndex === index ? 'bg-gray-200' : ''}`}
                                >
                                    {option.label}
                                </li>
                            ))
                        ))}
                    </ul>
                </div>

                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Tipo de diagnóstico</label>
                    <select
                        value={selectedTipo}
                        onChange={(e) => setSelectedTipo(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="">Selecciona un tipo</option>
                        <option value="01_Impresión diagnóstica">01 Impresión diagnóstica</option>
                        <option value="02_Confirmado nuevo">02 Confirmado nuevo</option>
                        <option value="03_Confirmado repetido">03 Confirmado repetido</option>
                    </select>
                </div>
 
                <button
                    type="button"
                    className="mt-4 p-2 bg-blue-500 text-white rounded"
                    onClick={agregarDiagnostico}
                >
                    Agregar diagnóstico
                </button>

                {/* Lista de diagnósticos agregados */}
                {diagnosticos.length > 0 && (
                    <div className="mt-4">
                        <h4 className="font-semibold mb-2">Diagnósticos agregados:</h4>
                        <div className="overflow-x-auto">
                            <table className="min-w-max divide-y divide-gray-200 table-fixed">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Acciones
                                        </th>
                                        <th scope="col" className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Diagnóstico
                                        </th>
                                        <th scope="col" className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Tipo de diagnóstico
                                        </th>
                                        <th scope="col" className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Relación
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {diagnosticos.map((diag, index) => (
                                        <tr key={index}>
                                            <td className="px-2 py-2 whitespace-nowrap">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center px-2 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-red-600 hover:text-red-900 focus:outline-none transition duration-150 ease-in-out"
                                                    onClick={() => eliminarDiagnostico(index)}
                                                >
                                                    Eliminar
                                                </button>
                                            </td>
                                            <td className="px-2 py-2 whitespace-normal max-w-xs break-words">
                                                <span>{diag.codigo} - {diag.nombre}</span>
                                            </td>
                                            <td className="px-2 py-2 whitespace-normal max-w-xs break-words">
                                                <span>{diag.tipo}</span>
                                            </td>
                                            <td className="px-2 py-2 whitespace-nowrap">
                                                <select
                                                    value={diag.relacionado || ''}
                                                    onChange={(e) => actualizarRelacionado(index, e.target.value)}
                                                    className="appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    <option value="Principal">Principal</option>
                                                    <option value="Relacionado 1">Relacionado 1</option>
                                                    <option value="Relacionado 2">Relacionado 2</option>
                                                    <option value="Relacionado 3">Relacionado 3</option>
                                                    <option value="Relacionado 4">Relacionado 4</option>
                                                </select>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}



                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">RECOMENDACIONES</h3>
                <div className="flex flex-col mt-4">

                    <textarea {...register('recomendaciones')} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">DATOS DEL PROFESIONAL</h3>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Profesional

                    </label>
                    <input {...register('profesional')}
                        value={appointmentInfo.fullName}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Especialidad

                    </label>
                    <input {...register('especialidad')}
                        value={translateMedicina(appointmentInfo.service)}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Numero RETHUS

                    </label>
                    <input {...register('rethusNumber')}
                        value={appointmentInfo.rethusNumber}
                        readOnly
                        className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
                </div>

                {/* 
                <div className="flex flex-col mt-4">
                    <label className="block text-gray-700">Firma</label>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ className: 'border border-gray-300 w-full h-40' }}
                    />
                    <button
                        type="button"
                        className="mt-2 p-2 bg-gray-500 text-white rounded"
                        onClick={handleClearSignature}
                    >
                        Limpiar firma
                    </button>
                </div>
*/}
                <hr className="my-4" />

                {/* Botón para enviar */}
                <div className="text-center">
                    <button
                        type="submit"
                        className="p-3 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded w-full"
                    >
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default HistoriaClinicaForm;
