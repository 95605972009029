import Select from 'react-select';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { alertaCargandoProceso, alertaGeneral, alertaToastTopEnd } from '../../utils/alertas';
import { validarInputExpresionRegular } from '../../utils/generalFunctions';

const CompleteProfilePatient = ({ patient }) => {
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedAseguradora, setSelectedAseguradora] = useState(null);
    const [selectedOcupacion, setSelectedOcupacion] = useState(null);
    const [addressData, setAddressData] = useState({
        via: "",
        numero: "",
        letra: "",
        bis: false,
        complemento: "",
        cardinalidad: "",
        otroNumero: "",
        otroLetra: "",
        otroComplemento: "",
        otroCardinalidad: "",
    });

    const [addressDisplay, setAddressDisplay] = useState("");

    const handleAddressChange = (e) => {
        setAddressData({
            ...addressData,
            [e.target.name]: e.target.value,
        });
    };


    const handleSubmitDireccion = (e) => {
        e.preventDefault();

        // Construye la dirección formateada
        const fullAddress = `${addressData.via} ${addressData.numero} ${addressData.letra || ""} ${addressData.bis ? "Bis" : ""} ${addressData.complemento || ""} ${addressData.cardinalidad || ""} ${addressData.otroNumero || ""} ${addressData.otroLetra || ""} ${addressData.otroComplemento || ""} ${addressData.otroCardinalidad || ""} `;

        setFormData((prevState) => ({
            ...prevState,
            address: fullAddress,
        }));

        // Actualiza el campo de dirección principal
        setAddressDisplay(fullAddress);

        // Cierra el modal
        setShowModal(false);
    };
    const [aseguradoraOptions, setAseguradoraOptions] = useState([]);
    const [ocupacionOptions, setOcupacionOptions] = useState([]);
    const [paises, setPaises] = useState([]);

    const [formData, setFormData] = useState({
        foto_perfil: '',
        dob: '',
        birthCountry: '',
        residenceCountry: '',
        municipio: '',
        city: '',
        neighborhood: '',
        firstName: patient.firstName || '',
        secondName: patient.secondName || '',
        firstLastName: patient.firstLastName || '',
        secondLastName: patient.secondLastName || '',
        estadoCivil: '',
        zonaUbicacion: '',
        grupoSanguineo: '',
        rh: '',
        address: '',
        grupoEtnico: '',
        gender: '',
        sex: '',
        condicionDesplazamiento: '',
        escolaridad: '',
        cellNumber: patient.cellNumber || '',
        cellNumber1: '',
        email: patient.email || '',
        idType: '',
        idNumber: '',
        tipoUsuario: '',
        cedulaPDF: '',
        hasMedicalInsurance: 'no',
        insuranceName: '',
        aseguradora: '',
        insuranceNumber: '',
        hasCarer: 'no',
        ocupacion: '',
        carerFirstName: '',
        carerSecondName: '',
        carerFirstLastName: '',
        carerSecondLastName: '',
        carerRelationship: '',
        carerIdType: '',
        carerIdNumber: '',
    });
    useEffect(() => {

        // Fetch departments when modal opens
        const fetchDepartments = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();

    }, []);

    const fetchPaises = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPaises`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });

            setPaises(response.data);
        } catch (error) {
            console.error('Error fetching países:', error);
        }
    };

    // Llama a fetchPaises cuando el componente se monte
    useEffect(() => {
        fetchPaises();
    }, []);

    const fetchAseguradoraOptions = async (query) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchAseguradora`,
                {
                    params: { query },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const options = response.data.map((med) => ({
                value: med.Os,         // Código CIE-10
                label: med.Nombre         // Nombre de la enfermedad

            }));

            setAseguradoraOptions(options);
        } catch (error) {
            console.error('Error al obtener las Aseguradoras:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchOcupacionOptions = async (query) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchOcupacion`,
                {
                    params: { query },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const options = response.data.map((med) => ({
                value: med.Indice,
                label: med.Detalle

            }));

            setOcupacionOptions(options);
        } catch (error) {
            console.error('Error al obtener las Ocupaciones:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (formData.department) {
            // Fetch municipalities when a department is selected
            const fetchCities = async () => {
                if (formData.department) {
                    try {
                        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.department}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setCities(response.data);
                    } catch (error) {
                        console.error('Error fetching cities:', error);
                    }
                }
            };

            fetchCities();
        } else {
            setCities([]);
        }
    }, [formData.department]);

    const handleDepartmentChange = (event) => {
        const selectedId = event.target.value;
        const selectedDepartment = departments.find(dept => dept._id === selectedId);

        setFormData({
            ...formData,
            department: selectedId, // ID del departamento
            departmentName: selectedDepartment ? selectedDepartment.departamento : '', // Nombre del departamento
        });
    };
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : validarTipoExpresionRegular(e),
        }));
    };

    const handleAseguradoraChange = (selectedOption) => {
        setSelectedAseguradora(selectedOption); // Mantiene el valor seleccionado en su estado individual
        setFormData({
            ...formData,
            aseguradora: selectedOption ? selectedOption.label : '', // Actualiza insuranceName en formData
        });
    };
    const handleOcupacionChange = (selectedOption) => {
        setSelectedOcupacion(selectedOption); // Mantiene el valor seleccionado en su estado individual
        setFormData({
            ...formData,
            ocupacion: selectedOption ? selectedOption.label : '', // Actualiza insuranceName en formData
        });
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [municipios, setMunicipios] = useState([]);

    const fetchMunicipios = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getMunicipios`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });
            setMunicipios(response.data);
        } catch (error) {
            console.error('Error fetching municipios:', error);
        }
    };


    useEffect(() => {
        fetchMunicipios();
    }, []);

    const handleMunicipioChange = selectedOption => {
        setFormData({ ...formData, municipio: selectedOption.value });
    };

    const opcionesPaises = paises.map(pais => ({
        value: `${pais.Codigo} - ${pais.Nombre}`,
        label: pais.Nombre,
    }));
    const opcionesMunicipios = municipios.map(municipio => ({
        value: `${municipio.Codigo} - ${municipio.Nombre}`,
        label: municipio.Nombre,
    }));


    /* Funcion guardar formulario */
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
        const token = localStorage.getItem('token');
        formDataToSend.append('clinicId', clinicId);

        alertaCargandoProceso({
            titulo: 'Guardando perfil del paciente',
            messageHtml: 'Por favor, espere un momento mientras se guarda el perfil del paciente',
            funcionAsync: () => {
                axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/patient/editProfile/${patient._id}`, formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((res) => {
                    if (res.status === 201) {
                        alertaToastTopEnd({
                            mensaje: 'Perfil del paciente guardado correctamente',
                            isError: false
                        })
                        localStorage.setItem('imageProfile', res.data.image);
                        window.location.reload();
                    }
                }).catch((error) => {
                    alertaGeneral({
                        titulo: 'Error',
                        messageHtml: error.response.data.message || 'Ocurrió un error al guardar el perfil del paciente',
                        isError: true
                    })
                })
            }
        })
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-200 shadow-md rounded-lg">
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Perfil del Paciente</h2>
            <form className="space-y-6" onSubmit={handleSubmit} encType="multipar/form-data">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                    {/* Imagen */}
                    <div className="col-span-1">

                        <label className="block text-sm font-medium text-gray-700">Imagen *</label>
                        <input
                            type="file"
                            name="foto_perfil"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept="image/*"
                        />
                    </div>

                    {/* Documento (Adjuntar) */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Documento Identidad *</label>
                        <input
                            type="file"
                            name="cedulaPDF"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept="application/pdf"
                        />
                    </div>

                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Fecha de Nacimiento *</label>
                        <input
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        />
                    </div>

                    {/* País de Nacimiento */}
                    <div className="form-group">
                        <label className="block text-gray-700">País de Nacimiento *</label>
                        <Select
                            name="birthCountry"
                            value={opcionesPaises.find(option => option.value === formData.birthCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, birthCountry: selectedOption.value })
                            }
                            options={opcionesPaises}
                            placeholder="Seleccione un país"
                            className="w-full"
                        />
                    </div>

                    {/* País de Residencia */}
                    <div className="form-group">
                        <label className="block text-gray-700">País de Residencia *</label>
                        <Select
                            name="residenceCountry"
                            value={opcionesPaises.find(option => option.value === formData.residenceCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, residenceCountry: selectedOption.value })
                            }
                            options={opcionesPaises}
                            placeholder="Seleccione un país"
                            className="w-full"
                        />
                    </div>

                    {/* Estado */}
                    <div className="form-group">
                        <label className="block text-gray-700">Municipio / Ciudad *</label>
                        <Select
                            name="municipio"
                            value={opcionesMunicipios.find(option => option.value === formData.residenceCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, municipio: selectedOption.value })
                            }
                            options={opcionesMunicipios}
                            placeholder="Seleccione un municipio"
                            className="w-full"
                        />
                    </div>

                    {/* Dirección */}
                    <div className="form-group">
                        <label className="block text-gray-700">Dirección</label>
                        <input
                            type="text"
                            name="direccion"
                            value={addressDisplay} //
                            readOnly
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Dirección"

                        />
                        <button
                            type="button"
                            onClick={() => setShowModal(true)} // Abre el modal
                            className="ml-2 px-3 py-1 mt-2 bg-blue-600 text-white rounded-md"
                        >
                            Agregar
                        </button>
                    </div>


                    {/* Barrio */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Barrio *</label>
                        <input
                            type="text"
                            name="neighborhood"
                            value={formData.neighborhood}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Barrio"
                            required
                        />
                    </div>

                    {/* Zona Ubicación */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Zona de ubicación</label>
                        <select
                            name="zonaUbicacion"
                            value={formData.zonaUbicacion}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="Urbana">Urbana</option>
                            <option value="Rural">Rural</option>
                        </select>
                    </div>


                    {/* Primer Nombre */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Primer Nombre *</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Primer Nombre"
                            required
                        />
                    </div>

                    {/* Segundo Nombre */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Segundo Nombre</label>
                        <input
                            type="text"
                            name="secondName"
                            value={formData.secondName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Segundo Nombre"
                        />
                    </div>

                    {/* Primer Apellido */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Primer Apellido *</label>
                        <input
                            type="text"
                            name="firstLastName"
                            value={formData.firstLastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Primer Apellido"
                            required
                        />
                    </div>

                    {/* Segundo Apellido */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Segundo Apellido</label>
                        <input
                            type="text"
                            name="secondLastName"
                            value={formData.secondLastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Segundo Apellido"
                        />
                    </div>

                    {/* Género */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Grupo sanguíneo</label>
                        <select
                            name="grupoSanguineo"
                            value={formData.grupoSanguineo}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="O">O</option>
                            <option value="AB">AB</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">RH</label>
                        <select
                            name="rh"
                            value={formData.rh}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >

                            <option value="">Seleccione una opción</option>
                            <option value="Positivo">Positivo</option>
                            <option value="Negativo">Negativo</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Género *</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="" disabled>Seleccione un género</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino">Femenino</option>
                            <option value="no-binario">No Binario</option>
                            <option value="Otro">Otro</option>
                            <option value="prefiero-no-decirlo">Prefiero no decirlo</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Sexo</label>
                        <select
                            name="sex"
                            value={formData.sex}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="H">Hombre</option>
                            <option value="M">Mujer</option>
                            <option value="I">Indeterminado/Intersexual</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Estado civil</label>
                        <select
                            name="estadoCivil"
                            value={formData.estadoCivil}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="Soltero">Soltero(A)</option>
                            <option value="Casado">Casado(A)</option>
                            <option value="Viudo">Viudo(A)</option>
                            <option value="Divorciado">Divorciado(A)</option>
                            <option value="Separado">Separado(A)</option>
                            <option value="Unión Libre">Unión Libre</option>
                            <option value="No Aplica">No Aplica</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Condición de desplazamiento</label>
                        <select
                            name="condicionDesplazamiento"
                            value={formData.condicionDesplazamiento}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="Desplazados internos">Desplazados internos</option>
                            <option value="Poblacion desplazada por la violencia">Población desplazada por la violencia</option>
                            <option value="Desplazamiento forzado">Desplazamiento forzado</option>
                            <option value="No aplica">No aplica</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Grupo étnico</label>
                        <select
                            name="grupoEtnico"
                            value={formData.grupoEtnico}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona un grupo étnico</option>
                            <option value="ROM (GITANOS)">ROM (GITANOS)</option>
                            <option value="AFROCOLOMBIANOS">AFROCOLOMBIANOS (Negro(a), mulato(a), afro colombiano(a) o afro descendiente)</option>
                            <option value="INDIGENAS">INDÍGENAS</option>
                            <option value="RAIZALES">RAIZALES (del archipiélago de San Andrés y Providencia)</option>
                            <option value="PALENQUERO">PALENQUERO (de San Basilio)</option>
                            <option value="Sin pertenencia étnica">Sin pertenencia étnica</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Escolaridad</label>
                        <select
                            name="escolaridad"
                            value={formData.escolaridad}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="Básica Primaria">Básica Primaria</option>
                            <option value="Básica Secundaria">Básica Secundaria</option>
                            <option value="Técnica Profesional">Técnica Profesional</option>
                            <option value="Profesional">Profesional</option>
                            <option value="Especialización">Especialización</option>
                            <option value="Ninguno">Ninguno</option>
                            <option value="Otro">Otro</option>
                            <option value="Preescolar">Preescolar</option>
                            <option value="Media Académica o Clásica">Media Académica o Clásica</option>
                            <option value="Media Técnica (Bachillerato Técnico)">Media Técnica (Bachillerato Técnico)</option>
                            <option value="Normalista">Normalista</option>
                            <option value="Tecnológica">Tecnológica</option>
                            <option value="Maestría">Maestría</option>
                            <option value="Doctorado">Doctorado</option>
                            <option value="Posgrado">Posgrado</option>
                        </select>
                    </div>

                    {/* Número de Celular */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular *</label>
                        <input
                            type="text"
                            name="cellNumber"
                            value={formData.cellNumber}
                            maxLength={10}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular 2</label>
                        <input
                            type="text"
                            name="cellNumber1"
                            maxLength={10}
                            value={formData.cellNumber1}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"

                        />
                    </div>

                    {/* Correo Electrónico */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Correo Electrónico *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Correo Electrónico"
                            required
                        />
                    </div>

                    {/* Tipo de ID */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de ID *</label>
                        <select
                            name="idType"
                            value={formData.idType}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione</option>
                            <option value="CC">CC</option>
                            <option value="TI">TI</option>
                            <option value="CE">CE</option>
                            <option value="PS">PS</option>
                            <option value="DE">DE</option>
                        </select>
                    </div>

                    {/* Número de ID */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de ID *</label>
                        <input
                            type="text"
                            name="idNumber"
                            value={formData.idNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de ID"
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de usuario</label>
                        <select
                            name="tipoUsuario"
                            value={formData.tipoUsuario}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona un tipo de usuario</option>
                            <option value="01">Contributivo cotizante</option>
                            <option value="02">Contributivo beneficiario</option>
                            <option value="03">Contributivo adicional</option>
                            <option value="04">Subsidiado</option>
                            <option value="05">No afiliado</option>
                            <option value="06">Especial o Excepción cotizante</option>
                            <option value="07">Especial o Excepción beneficiario</option>
                            <option value="08">Personas privadas de la libertad a cargo del Fondo Nacional de Salud</option>
                            <option value="09">Tomador / Amparado ARL</option>
                            <option value="10">Tomador / Amparado SOAT</option>
                            <option value="11">Tomador / Amparado Planes voluntarios de salud</option>
                            <option value="12">Particular</option>
                        </select>
                    </div>

                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Ocupación</label>
                        <Select
                            name="ocupacion"
                            value={selectedOcupacion}
                            onChange={handleOcupacionChange}
                            onInputChange={(inputValue) => {
                                if (inputValue.length > 2) {
                                    fetchOcupacionOptions(inputValue);
                                } else {
                                    setOcupacionOptions([]); // Limpiar las opciones si el input es corto
                                }
                            }}
                            options={ocupacionOptions}
                            isLoading={loading}
                            className="mt-1 block w-full"
                            placeholder="Buscar..."
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Aseguradora</label>
                        <Select
                            name="aseguradora"
                            value={selectedAseguradora}
                            onChange={handleAseguradoraChange}
                            onInputChange={(inputValue) => {
                                if (inputValue.length > 2) {
                                    fetchAseguradoraOptions(inputValue);
                                } else {
                                    setAseguradoraOptions([]); // Limpiar las opciones si el input es corto
                                }
                            }}
                            options={aseguradoraOptions}
                            isLoading={loading}
                            className="mt-1 block w-full"
                            placeholder="Buscar..."
                        />
                    </div>
                </div>

                <hr className="my-6 border-gray-300" />

                {/* Seguro Médico */}
                <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700">¿Tiene Seguro Médico?</label>
                    <select
                        name="hasMedicalInsurance"
                        value={formData.hasMedicalInsurance}
                        onChange={handleSelectChange}
                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="no">No</option>
                        <option value="yes">Sí</option>
                    </select>
                </div>

                {formData.hasMedicalInsurance === 'yes' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        {/* Nombre del Seguro */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Nombre del Seguro</label>
                            <input
                                type="text"
                                name="insuranceName"
                                value={formData.insuranceName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Nombre del Seguro"
                            />
                        </div>

                        {/* Número del Seguro */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Número del Seguro</label>
                            <input
                                type="text"
                                name="insuranceNumber"
                                value={formData.insuranceNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Número del Seguro"
                            />
                        </div>
                    </div>
                )}

                <hr className="my-6 border-gray-300" />

                {/* Cuidador o Representante */}
                <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700">¿Tiene Cuidador o Representante?</label>
                    <select
                        name="hasCarer"
                        value={formData.hasCarer}
                        onChange={handleSelectChange}
                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="no">No</option>
                        <option value="yes">Sí</option>
                    </select>
                </div>

                {formData.hasCarer === 'yes' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        {/* Primer Nombre del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Primer Nombre del Cuidador</label>
                            <input
                                type="text"
                                name="carerFirstName"
                                value={formData.carerFirstName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Primer Nombre del Cuidador"
                            />
                        </div>

                        {/* Segundo Nombre del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Segundo Nombre del Cuidador</label>
                            <input
                                type="text"
                                name="carerSecondName"
                                value={formData.carerSecondName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Segundo Nombre del Cuidador"
                            />
                        </div>

                        {/* Primer Apellido del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Primer Apellido del Cuidador</label>
                            <input
                                type="text"
                                name="carerFirstLastName"
                                value={formData.carerFirstLastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Primer Apellido del Cuidador"
                            />
                        </div>

                        {/* Segundo Apellido del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Segundo Apellido del Cuidador</label>
                            <input
                                type="text"
                                name="carerSecondLastName"
                                value={formData.carerSecondLastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Segundo Apellido del Cuidador"
                            />
                        </div>

                        {/* Parentesco */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Parentesco</label>
                            <input
                                type="text"
                                name="carerRelationship"
                                value={formData.carerRelationship}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Parentesco"
                            />
                        </div>

                        {/* Tipo de ID del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Tipo de ID del Cuidador</label>
                            <select
                                name="carerIdType"
                                value={formData.carerIdType}
                                onChange={handleSelectChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="">Seleccione</option>
                                <option value="CC">CC</option>
                                <option value="TI">TI</option>
                                <option value="CE">CE</option>
                                <option value="PS">PS</option>
                                <option value="DE">DE</option>
                            </select>
                        </div>

                        {/* Número de ID del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Número de ID del Cuidador</label>
                            <input
                                type="text"
                                name="carerIdNumber"
                                value={formData.carerIdNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Número de ID del Cuidador"
                            />
                        </div>
                    </div>
                )}

                <div className="flex justify-end mt-6">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Guardar
                    </button>
                </div>
            </form>
            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-md shadow-lg w-3/4"> {/* Ajustamos el ancho con w-3/4 */}
                        <h2 className="text-lg font-bold mb-6">Agregar Dirección</h2>
                        <form onSubmit={handleSubmitDireccion} className="grid grid-cols-2 gap-6"> {/* Layout en 2 columnas */}

                            <div>
                                <label className="block text-gray-700">Vía</label>
                                <select
                                    name="via"
                                    value={addressData.via}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                >
                                    <option value="">Seleccione Vía</option>
                                    <option value="calle">Calle</option>
                                    <option value="carrera">Carrera</option>
                                    <option value="avenida">Avenida</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-gray-700">Número</label>
                                <input
                                    type="text"
                                    name="numero"
                                    value={addressData.numero}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Letra</label>
                                <input
                                    type="text"
                                    name="letra"
                                    value={addressData.letra}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div className="flex items-center">
                                <label className="block text-gray-700">Bis</label>
                                <input
                                    type="checkbox"
                                    name="bis"
                                    checked={addressData.bis}
                                    onChange={handleAddressChange}
                                    className="ml-2"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Complemento</label>
                                <input
                                    type="text"
                                    name="complemento"
                                    value={addressData.complemento}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Cardinalidad</label>
                                <input
                                    type="text"
                                    name="cardinalidad"
                                    value={addressData.cardinalidad}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Número (otro)</label>
                                <input
                                    type="text"
                                    name="otroNumero"
                                    value={addressData.otroNumero}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Letra (otro)</label>
                                <input
                                    type="text"
                                    name="otroLetra"
                                    value={addressData.otroLetra}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Complemento (otro)</label>
                                <input
                                    type="text"
                                    name="otroComplemento"
                                    value={addressData.otroComplemento}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Cardinalidad (otro)</label>
                                <input
                                    type="text"
                                    name="otroCardinalidad"
                                    value={addressData.otroCardinalidad}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div className="col-span-2 flex justify-end mt-6">
                                <button
                                    type="button"
                                    onClick={() => setShowModal(false)} // Cierra el modal
                                    className="mr-4 px-4 py-2 bg-gray-600 text-white rounded-md"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2  bg-blue-600 text-white rounded-md"
                                >
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );

    function validarTipoExpresionRegular(e) {
        if (e.target.name.includes('Name')) {
            return validarInputExpresionRegular(e, /[^a-zA-ZñÑ\s]/g);
        } else if (e.target.name.includes('Number')) {
            return validarInputExpresionRegular(e, /[^0-9]/g);
        }
        return e.target.value;
    }
}

export default CompleteProfilePatient;