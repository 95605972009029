import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/Logo_Mozart_color.png';
import { useDataUser } from '../../context/dataUserContext';

const Header = ({ setActiveComponent }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { setIsAuth } = useDataUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setIsAuth(false); //Para limpiar el contexto
    navigate('/');
  };
  useEffect(() => {
    setActiveComponent('clinica');
  }, [setActiveComponent]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-black p-4 flex flex-col md:flex-row justify-between items-center shadow-md">
      <div className="flex items-center cursor-pointer" onClick={() => setActiveComponent('clinica')}>
        <img src={logo} alt="Logo" className="h-14 mr-4 shadow-lg border-2 border-gray-800 bg-white" />
      </div>

      {/* Opciones del menú para pantallas grandes */}
      <nav className="hidden md:flex flex-1 md:flex-row md:space-x-4">
        <div className="flex flex-grow space-x-4">
          {/* <button
            className="text-left bg-transparent text-white  hover:bg-blue-300 py-2 px-4 rounded"
            onClick={() => setActiveComponent('usuarios')}
          >
            Usuarios
          </button>*/}
          <button
            className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
            onClick={() => setActiveComponent('clinica')}
          >
            Clínica
          </button>
        </div>

        <div className="flex-none">
          <button
            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded"
            onClick={handleLogout}
          >
            Cerrar Sesión
          </button>
        </div>
      </nav>

      {/* Menú desplegable para pantallas pequeñas */}
      <div className="md:hidden relative">
        <button
          className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
          onClick={toggleMenu}
        >
          ☰
        </button>
        <div className={`absolute right-0 top-14 mt-2 w-48 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 rounded-lg shadow-lg ${isMenuOpen ? 'block' : 'hidden'}`} style={{ right: '-15px' }}>
          <nav className="flex flex-col p-2">
            {/* <button
              className="text-left bg-transparent text-white  hover:bg-blue-300 py-2 px-4 rounded"
              onClick={() => { setActiveComponent('usuarios'); toggleMenu(); }}
            >
              Usuarios
            </button>*/}
            <button
              className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
              onClick={() => { setActiveComponent('clinica'); toggleMenu(); }}
            >
              Clínica
            </button>
            <button
              className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-black py-2 px-4 rounded mt-2"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
