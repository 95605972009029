import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Header from './components/AdminComponent/Header';
import HeaderClinic from './components/ClinicComponent/Header';
import Clinica from './components/AdminComponent/Clinica';
import Patient from './components/PatientComponent/Patient';
import Doctor from './components/DoctorComponent/Doctor';
import Usuarios from './components/AdminComponent/Usuarios';
import Terminos from './components/AdminComponent/terminosycondiciones';
import Login from './components/AdminComponent/Login';
import Calendar from './components/PatientComponent/CalendarView';
import CalendarDoctor from './components/DoctorComponent/CalendarView';
import PatientPerfil from './components/PatientComponent/PatientProfile';
import DoctorControl from './components/DoctorComponent/DoctorControl';
import HistoriaOffline from './components/DoctorComponent/HistoriaOffline';
import PatientControl from './components/PatientComponent/PatientControl';
import DoctorPerfil from './components/DoctorComponent/DoctorProfile';
import VideoCall from './components/VideoCall'; // Asegúrate de importar el componente VideoCall
import { FormProvider } from './components/AdminComponent/FormContext';
import logo from './images/Personaje_analisis_data.png';
import DoctorAssignedDocuments from './components/DoctorComponent/DoctorAssignedDocuments';
import PatientAssignedDocuments from './components/PatientComponent/PatientAssignedDocuments';
import Consentimiento from './components/VideoCall/ConsentimientoInformado';
import MarketPlace from './components/PatientComponent/MarketPlace';
import CalendarDisponibility from './components/DoctorComponent/CalendarDisponibility';
import AgendarCita from './components/PatientComponent/AgendarCita';
import PagRespuestaAgendarCitaPayU from './components/PayU/PagRespuestaAgendarCitaPayU';
import { Registro } from './components/AdminComponent/Registro';
import PagRespuestaAfiliadoPayU from './components/PayU/PagRespuestaAfiliadoPayU';
import { DataUserProvider } from './context/dataUserContext';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Cursos from './components/DoctorComponent/Cursos';
import AfiliadosDisponibles from './components/DoctorComponent/AfiliadosDisponibles';
import PagRespuestaCursoPayU from './components/PayU/PagRespuestaCursoPayU';

const ProtectedRoute = ({ allowedRoles, requireRole = true, children }) => {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('role');
    const isTokenValid = !!token; // Validar si el token existe

    console.log('Token (isTokenValid):', isTokenValid);
    console.log('User Role:', userRole);
    console.log('requireRole:', requireRole);

    // Verificar autenticación y rol en tiempo real
    if (isTokenValid) {
        if (requireRole && !allowedRoles.includes(userRole)) {
            console.log('No autorizado para esta ruta');
            return <Navigate to="/" />;
        }
        return children;
    } else {
        console.log('No autenticado, redirigiendo...');
        return <Navigate to="/" />;
    }
};




function App() {
    const [activeComponent, setActiveComponent] = useState('bienvenida');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [patientName, setPatientName] = useState(localStorage.getItem('patientName'));
    const [doctorName, setDoctorName] = useState(localStorage.getItem('doctorName'));
    const [role, setRole] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        if (token && role) {
            setIsAuthenticated(true);
            setRole(role);
        } else {
            setIsAuthenticated(false); // Si el token o el rol no están, el usuario no está autenticado
        }
    }, []);

    const handleLogin = (username, role, patientName, doctorName) => {
        setIsAuthenticated(true);
        setRole(role);
        setPatientName(patientName);
        setDoctorName(doctorName);
    };

    return (
        <Router>
            <DataUserProvider>
                <FormProvider>
                    <Routes>
                        <Route path="*" element={<ErrorPage />} />
                        <Route path="/" element={<Login callback={handleLogin} />} />
                        <Route path="/registro" element={<Registro />} />
                        <Route path="/registro-afiliado" element={<PagRespuestaAfiliadoPayU />} />

                        {/* Ruta para administradores (solo accesible para el rol 'admin') */}
                        <Route
                            path="/admin"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated} allowedRoles={['admin']}>
                                    <div className="min-h-screen bg-gray-100">
                                        <Header setActiveComponent={setActiveComponent} />
                                        <main className="p-4">
                                            {activeComponent === 'clinica' && <Clinica />}
                                        </main>
                                    </div>
                                </ProtectedRoute>
                            }
                        />

                        {/* Ruta para clínicas (solo accesible para el rol 'clinic') */}
                        <Route
                            path="/clinic"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated} allowedRoles={['clinic', 'clinicAdmin']}>
                                    <div className="min-h-screen bg-gray-100">
                                        <HeaderClinic />
                                        <main className="p-4">
                                            {/* Agrega los componentes específicos para clínicas aquí */}
                                        </main>
                                    </div>
                                </ProtectedRoute>
                            }
                        />

                        {/* Ruta para pacientes (solo accesible para el rol 'patient') */}
                        <Route
                            path="/patient"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated} allowedRoles={['patient']}>
                                    <div className="min-h-screen bg-gray-100">
                                        <Patient />
                                        <main className="p-4">
                                            <Outlet />
                                        </main>
                                    </div>
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={< Navigate to="dashboard" />} />
                            <Route
                                path="dashboard"
                                element={
                                    <div className="text-center">
                                        <img src={logo} className="mx-auto mb-2 mt-20 w-28 h-30" />
                                        <h2 className="text-2xl font-bold">Bienvenido/a {patientName}</h2>
                                    </div>
                                }
                            />
                            <Route
                                path="perfil"
                                element={<PatientPerfil />}
                            />
                            <Route
                                path="calendario"
                                element={<Calendar />}
                            />
                            <Route
                                path="control"
                                element={<PatientControl />}
                            />
                            <Route
                                path="documentos-asignados"
                                element={<PatientAssignedDocuments />}
                            />
                            <Route
                                path="tienda"
                                element={<MarketPlace />}
                            />
                            <Route
                                path='agendar-cita'
                                element={<AgendarCita />}
                            />

                            <Route
                                path='/patient/aprobacion-cita'
                                element={<PagRespuestaAgendarCitaPayU />} />

                        </Route>
                        {/* FIN RUTAS PACIENTE */}

                        {/* INICIO RUTAS DOCTOR */}
                        <Route
                            path="/doctor"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated} allowedRoles={['doctor']}>
                                    <div className="min-h-screen bg-gray-100">
                                        <Doctor />
                                        <main className="p-4">
                                            <Outlet />
                                        </main>
                                    </div>
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={<Navigate to="dashboard" />} />
                            <Route
                                path="dashboard"
                                element={
                                    <div className="text-center">
                                        <img src={logo} className="mx-auto mb-2 mt-20 w-28 h-30" />
                                        <h2 className="text-2xl font-bold">Bienvenido/a {doctorName}</h2>
                                    </div>
                                }
                            />
                            <Route
                                path="perfil"
                                element={<DoctorPerfil />}
                            />
                            <Route
                                path="calendario"
                                element={<CalendarDoctor />}
                            />
                            <Route
                                path="control"
                                element={<DoctorControl />}
                            />
                            <Route
                                path="documentos-asignados"
                                element={<DoctorAssignedDocuments />}
                            />
                            <Route
                                path="disponibilidad"
                                element={<CalendarDisponibility />}
                            />
                            <Route
                                path="tienda"
                                element={<MarketPlace />}
                            />
                            <Route
                                path="afiliados"
                                element={<AfiliadosDisponibles />}
                            />
                            <Route
                                path="afiliados/:nombreAfiliado"
                                element={<Cursos />}
                            />
                            <Route
                                path="pago-curso/:cursoId"
                                element={<PagRespuestaCursoPayU />}
                            />
                            <Route
                                path="historia-offline"
                                element={<HistoriaOffline />}
                            />
                        </Route>
                        {/* FIN RUTAS DOCTOR */}

                        <Route
                            path="/consentimiento/:appointmentId"
                            element={<Consentimiento />}
                        />

                        {/* Ruta para videollamada */}
                        <Route
                            path="/video-call/:appointmentId"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated} requireRole={false}>
                                    <VideoCall />
                                </ProtectedRoute>
                            }
                        />


                        {/* Ruta para términos y condiciones */}
                        <Route path="/terminos-y-condiciones" element={<Terminos />} />
                    </Routes>
                </FormProvider>
            </DataUserProvider>
        </Router>
    );
}

export default App;
