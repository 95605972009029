import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const RipsReport = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleDownloadRips = async () => {
    try {
      if (!startDate || !endDate) {
        Swal.fire({
          icon: 'warning',
          title: 'Fechas requeridas',
          text: 'Por favor selecciona un rango de fechas.',
        });
        return;
      }

      const token = localStorage.getItem('token');
      const clinicId = localStorage.getItem('clinicId');

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/getRipsByDate`,
        {
          params: { startDate, endDate, clinicId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const cleanedRips = response.data;

      // Convertir datos a JSON y descargarlos
      const blob = new Blob([JSON.stringify(cleanedRips, null, 2)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `RIPS_${startDate}_to_${endDate}.json`;
      link.click();
      URL.revokeObjectURL(url);

      Swal.fire({
        icon: 'success',
        title: 'Descarga completada',
        text: 'El archivo RIPS ha sido descargado con éxito.',
      });
    } catch (error) {
      console.error('Error al descargar los RIPS:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al descargar los RIPS. Por favor, inténtalo nuevamente.',
      });
    }
  };

  return (
      <div className="bg-white justify-center  rounded-lg shadow-lg w-full max-w-6xl p-28">
        <h1 className="text-4xl font-bold text-center text-blue-700 mb-6">Descargar RIPS</h1>
        <p className="text-gray-600 text-center mb-8">
          Selecciona un rango de fechas para descargar el reporte RIPS.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block mb-2 text-sm font-semibold text-gray-700">Fecha Inicio</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-semibold text-gray-700">Fecha Fin</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
        </div>
        <div className="mt-8 text-center">
          <button
            onClick={handleDownloadRips}
            className="w-full md:w-auto px-6 py-3 bg-blue-600 text-white text-lg font-medium rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300"
          >
            Descargar RIPS
          </button>
        </div>
    </div>
  );
};

export default RipsReport;
