import { format } from "@formkit/tempo";
import { useEffect, useState } from "react";
import BotonSubmitPayU from "../PayU/BotonSubmitPayU";
import { useNavigate } from "react-router-dom";
import { useDataUser } from "../../context/dataUserContext";

const AgendarCita = () => {
    const preInfo = JSON.parse(localStorage.getItem('selectedDate')) || {};
    const [patientEmail] = useState(localStorage.getItem('patientEmail'));
    const [error, setError] = useState(true);
    const navigate = useNavigate();
    const { role } = useDataUser();

    if (role !== 'patient') {
        navigate('/');
    }

    const [dataCita, setDataCita] = useState({
        doctorId: preInfo.doctorId || '',
        doctor: preInfo.doctor || '',
        servicio: preInfo.servicio || '',
        precioServicio: preInfo.precioServicio || '',
        fecha: preInfo.fecha || '',
        tipoPagador: preInfo.tipoPagador || 'particular',
        tipoConvenio: preInfo.tipoConvenio || '',
        motivoConsulta: preInfo.motivoConsulta || '',
    });

    useEffect(() => {
        localStorage.setItem('selectedDate', JSON.stringify(dataCita));
    }, [dataCita]);


    useEffect(() => {
        if (dataCita.motivoConsulta == '' || dataCita.tipoPagador.length == 0 || (dataCita.tipoPagador === 'convenio' && dataCita.tipoConvenio.length === 0)) {
            setError(true);
        } else {
            setError(false);
        }
    }, [dataCita]);

    const handleClickRegresar = () => {
        navigate("/patient/tienda");
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataCita({ ...dataCita, [name]: value });
    };

    return (
        <div className="p-6 max-w-md mx-auto bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Agendar Cita</h2>
            <form className="space-y-4">
                {/* Servicio */}
                <div>
                    <label className="block text-gray-700">Servicio</label>
                    <input
                        type="text"
                        name="servicio"
                        value={dataCita.servicio}
                        readOnly
                        disabled
                        className="capitalize mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Precio del Servicio */}
                <div>
                    <label className="block text-gray-700">Precio del Servicio</label>
                    <input
                        type="text"
                        name="precioServicio"
                        value={dataCita.precioServicio}
                        readOnly
                        disabled
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Nombre Doctor */}
                <div>
                    <label className="block text-gray-700">Nombre Doctor</label>
                    <input
                        type="text"
                        name="doctor"
                        value={dataCita.doctor}
                        readOnly
                        disabled
                        className="title mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Fecha Cita */}
                <div>
                    <label className="block text-gray-700">Fecha Cita</label>
                    <input
                        type="datetime"
                        name="fecha"
                        readOnly
                        disabled
                        defaultValue={format(dataCita.fecha, 'YYYY-MM-DD HH:mm a')}
                        className="mt-1 mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Tipo Pagador */}
                <div>
                    <label className="block text-gray-700">Tipo de Pagador *</label>
                    <select
                        name="tipoPagador"
                        value={dataCita.tipoPagador}
                        onChange={handleChange}
                        required
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="" disabled>Selecciona una opción</option>
                        <option value="particular">Particular</option>
                        <option value="convenio">Convenio</option>
                    </select>
                </div>

                {/* Tipo Convenio (solo aparece si Tipo Pagador es 'convenio') */}
                {dataCita.tipoPagador === 'convenio' && (
                    <div>
                        <label className="block text-gray-700">Tipo de Convenio *</label>
                        <select
                            name="tipoConvenio"
                            value={dataCita.tipoConvenio}
                            onChange={handleChange}
                            required
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="" disabled>Selecciona un tipo</option>
                            <option value="cotizante">Cotizante</option>
                            <option value="beneficiario">Beneficiario</option>
                            <option value="adicional">Adicional</option>
                        </select>
                    </div>
                )}

                {/* Motivo de Consulta */}
                <div>
                    <label className="block text-gray-700">Motivo de Consulta</label>
                    <textarea
                        name="motivoConsulta"
                        value={dataCita.motivoConsulta}
                        onChange={handleChange}
                        rows="3"
                        required
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {error && (
                        <span className="text-sm text-red-500">Todos los campos son requeridos</span>
                    )}
                </div>

                {/* Botón de Enviar de PayU */}

                <BotonSubmitPayU
                    doctorId={dataCita.doctorId}
                    rutaPeticion={`${process.env.REACT_APP_BACKEND_URL}/api/payU/procesar-pago`}
                    descripcion={"Cita con " + dataCita.doctor + " - Motivo: " + dataCita.motivoConsulta}
                    correo={patientEmail}
                    disabled={error}
                />

                <button className="w-full px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded-md focus:outline-none"
                    onClick={handleClickRegresar}>
                    Regresar
                </button>

            </form>
        </div>
    );
};

export default AgendarCita;
