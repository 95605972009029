import axios from "axios";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { useDataUser } from "../../context/dataUserContext";
import { validarInputExpresionRegular } from "../../utils/generalFunctions";
import { alertaCargandoProceso, alertaGeneral } from "../../utils/alertas";

const CompleterProfileDoctor = ({ doctor, setDoctor, setShow }) => {
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const { service, token } = useDataUser();

    const [formData, setFormData] = useState({
        fullName: doctor.fullName || '', // Nombre Completo
        idType: doctor.idType || '', // Tipo de Documento de Identidad
        idNumber: doctor.idNumber || '', // Número de Documento de Identidad
        cedulaPDF: '', // Documento Identidad (Adjuntar)
        email: doctor.email || '', // Correo Electrónico
        cellNumber: doctor.cellNumber || '', // Número de Celular
        diploma_acta_grado: '', // Diploma o Acta de Grado de Pregrado (PDF o Imagen)
        diploma_especializacion: '', // Diploma o Acta de Grado de Especialización
        homologacion_estudios: '', // Homologación de Estudios
        tarjeta_profesional: '', // Tarjeta Profesional (PDF o Imagen)
        rethusNumber: '', // ReThus (Número)
        rethus: '', // ReThus (PDF o Imagen)
        civilLiabilityPolicyNumber: '', // Póliza de Responsabilidad Civil (Número)
        poliza_responsabilidad_civil: '', // Póliza de Responsabilidad Civil (PDF o Imagen)
        priceService: '', // Precio del servicio
        foto_perfil: '', // Foto profesional digital
        firma_profesional: '', // Firma profesional digital
    });

    useEffect(() => {

        // Fetch departments when modal opens
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();

    }, []);

    useEffect(() => {
        if (formData.department) {
            // Fetch municipalities when a department is selected
            const fetchCities = async () => {
                if (formData.department) {
                    try {
                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.department}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setCities(response.data);
                    } catch (error) {
                        console.error('Error fetching cities:', error);
                    }
                }
            };

            fetchCities();
        } else {
            setCities([]);
        }
    }, [formData.department]);


    /* FUNCION DE SUBIDA */
    const handleSubmit = (e) => {
        e.preventDefault();
        const clinicInfo = localStorage.getItem('clinicId');
        // Campos que pueden estar vacíos
        const camposOpcionales = ['diploma_especializacion', 'homologacion_estudios', 'rethusNumber', 'rethus', 'civilLiabilityPolicyNumber', 'poliza_responsabilidad_civil', 'firma_profesional'];
        // Verificar si todos los campos obligatorios de formData tienen datos
        for (const key in formData) {
            if (formData.foto_perfil && !formData.foto_perfil.type.includes('image/')) {
                alertaGeneral({
                    titulo: 'Error al enviar el perfil',
                    messageHtml: 'La foto de perfil debe ser un archivo de imagen',
                    isError: true
                });
                return;
            }

            if (!formData[key] && !camposOpcionales.includes(key)) {  // Si el campo es obligatorio y está vacío
                alertaGeneral({
                    titulo: `El campo ${key} es obligatorio`,
                    messageHtml: 'Por favor complete todos los campos obligatorios',
                    isError: true
                })
                return; // Detener el envío del formulario
            }
        }

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        formDataToSend.append('_id', doctor._id);
        formDataToSend.append('clinicInfo', clinicInfo);

        alertaCargandoProceso({
            titulo: 'Enviando perfil',
            messageHtml: 'Espere un momento por favor no cierre la ventana...',
            funcionAsync: () => {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/afiliado/prestar-servicios`, formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then((res) => {
                    Swal.fire({
                        title: 'Perfil enviado correctamente',
                        icon: 'success',
                        customClass: {
                            title: 'swal2-title-custom',
                            htmlContainer: 'swal2-html-container-custom'
                        }
                    });
                    setDoctor(res.data.afiliado);
                    localStorage.setItem('imageProfile', res.data.afiliado.urlProfessionalPhoto);
                    window.location.reload();
                }).catch((error) => {
                    alertaGeneral({
                        titulo: 'Error al enviar el perfil',
                        messageHtml: error.response?.data?.message || 'Ocurrió un error al enviar el perfil',
                        isError: true
                    })
                });
            }
        })

    };

    const validarTipoExpresionRegular = (e) => {
        switch (e.target.name) {
            case 'cellNumber':
                return validarInputExpresionRegular(e, /[^[0-9]/g);
            case 'idNumber':
                return validarInputExpresionRegular(e, /[^[0-9]/g);
            case 'priceService':
                return validarInputExpresionRegular(e, /[^[0-9]/g);
            case 'fullName':
                return validarInputExpresionRegular(e, /[^[A-Za-z\s]/g);
            default:
                return e.target.value
        }
    }

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : validarTipoExpresionRegular(e),
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-200 shadow-md rounded-lg">
            <button
                type="button"
                className="mb-8 px-3 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setShow(false)}
            >
                Volver
            </button>
            <form className="space-y-6" onSubmit={handleSubmit} encType="multipart-form-data">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                    {/* Nombre Completo */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Nombre Completo *</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Nombre Completo"
                            required
                        />
                    </div>

                    {/* Tipo de Documento de Identidad */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de Documento de Identidad *</label>
                        <select
                            name="idType"
                            value={formData.idType}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2  border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="" disabled>Seleccione</option>
                            <option value="cc">CC</option>
                            <option value="ti">TI</option>
                            <option value="ce">CE</option>
                            <option value="ps">PS</option>
                            <option value="de">DE</option>
                        </select>
                    </div>

                    {/* Número de Documento de Identidad */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Documento de Identidad *</label>
                        <input
                            type="text"
                            name="idNumber"
                            value={formData.idNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Documento de Identidad"
                            required
                        />
                    </div>

                    {/* Correo Electrónico */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Correo Electrónico *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Correo Electrónico"
                            required
                        />
                    </div>

                    {/* Número de Celular */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular *</label>
                        <input
                            type="text"
                            name="cellNumber"
                            value={formData.cellNumber}
                            onChange={handleChange}
                            maxLength={10}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"
                            required
                        />
                    </div>

                    {/* Precio de Servicio */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Precio Del Servicio *</label>
                        <input
                            type="text"
                            name="priceService"
                            value={formData.priceService}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="50000"
                            required
                        />
                    </div>

                    {/* Foto profesional digital */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Foto Profesional Digital *</label>
                        <input
                            type="file"
                            name="foto_perfil"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".jpg,.jpeg,.png"
                            required
                        />
                    </div>

                    {/* Documento (Adjuntar) */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Documento Identidad (PDF o Imagen) *</label>
                        <input
                            type="file"
                            name="cedulaPDF"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                            required
                        />
                    </div>

                    {/* Diploma o Acta de Grado de Pregrado */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Diploma o Acta de Grado de Pregrado (PDF o Imagen) *</label>
                        <input
                            type="file"
                            name="diploma_acta_grado"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                            required
                        />
                    </div>

                    {/* Tarjeta Profesional */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Tarjeta Profesional (PDF o Imagen) *</label>
                        <input
                            type="file"
                            name="tarjeta_profesional"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                            required
                        />
                    </div>

                    {/* Diploma o Acta de Grado de Especialización */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Diploma o Acta de Grado de Especialización (si aplica)</label>
                        <input
                            type="file"
                            name="diploma_especializacion"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {/* Homologación de Estudios */}
                    <div className="col-span-1 my-2">
                        <label className="block text-sm font-medium text-gray-700">Homologación de Estudios (si aplica)</label>
                        <input
                            type="file"
                            name="homologacion_estudios"
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept=".pdf,.jpg,.jpeg,.png"
                        />
                    </div>

                    {(service.includes('medicina') || service.includes('psicología') || service.includes('terapia') ||
                        service.includes('terapeuta') || service.includes('nutrición') || service.includes('enfermería') || service.includes('fonoaudiología')) && (
                            <>
                                {/* Firma del profesional */}
                                < div className="col-span-1 my-2">
                                    <label className="block text-sm font-medium text-gray-700">Firma del profesional (300px * 150px) *</label>
                                    <input
                                        type="file"
                                        name="firma_profesional"
                                        onChange={handleChange}
                                        required
                                        className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                                        accept=".jpg,.jpeg,.png"
                                    />
                                </div>
                            </>
                        )}

                    {(service.includes('medicina') || service.includes('psicología') || service.includes('terapia') ||
                        service.includes('terapeuta') || service.includes('nutrición') || service.includes('enfermería') || service.includes('fonoaudiología')) && (
                            <>
                                {/* ReThus */}
                                <div className="col-span-1 my-2">
                                    <label className="block text-sm font-medium text-gray-700">ReThus (Número y PDF o Imagen) * </label>
                                    <input
                                        type="text"
                                        name="rethusNumber"
                                        value={formData.rethusNumber}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                        placeholder="Número ReThus"
                                        required
                                    />
                                    <input
                                        type="file"
                                        name="rethus"
                                        onChange={handleChange}
                                        className="mt-2 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                                        required
                                        accept=".pdf,.jpg,.jpeg,.png"
                                    />
                                </div>

                                {/* Póliza de Responsabilidad Civil Vigente */}
                                <div className="col-span-1 my-2">
                                    <label className="block text-sm font-medium text-gray-700">Póliza de Responsabilidad Civil Vigente (Número y PDF o Imagen) *</label>
                                    <input
                                        type="text"
                                        name="civilLiabilityPolicyNumber"
                                        value={formData.civilLiabilityPolicyNumber}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                        required
                                        placeholder="Número de Póliza"
                                    />
                                    <input
                                        type="file"
                                        name="poliza_responsabilidad_civil"
                                        onChange={handleChange}
                                        className="mt-2 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                                        required
                                        accept=".pdf,.jpg,.jpeg,.png"
                                    />
                                </div>
                            </>
                        )}

                </div>

                {/* Botón de Enviar */}
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Enviar Perfil
                    </button>
                </div>
            </form >
        </div >

    );
}

export default CompleterProfileDoctor;
