import Swal from "sweetalert2";

export function alertaGeneral({titulo, messageHtml, isError = false, isWarning = false}) {
    Swal.fire({
        title: titulo,
        html: messageHtml,
        icon: isError ? 'error' : isWarning ? 'warning' : 'success',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#2664ebff',
    });
}

export function alertaToastTopEnd({mensaje, isError = false}){
    Swal.fire({
        toast: true,
        position: 'top-end',
        icon: isError ? 'error' : 'success',
        title: mensaje,
        showConfirmButton: false,
        timer: 800,
        timerProgressBar: true
    });
}

/* Esta alerta es para que no deje hacer "nada" y de un mensaje de que se está realizando el proceso */
export function alertaCargandoProceso({ titulo, messageHtml, funcionAsync, isTransparent }) {
    Swal.fire({
        title: titulo,
        html: messageHtml,
        allowOutsideClick: false,
        allowEscapeKey: false,
        // allowEnterKey: false,
        background: isTransparent ? 'transparent' : undefined,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
            // Inicia la operación del servidor
            funcionAsync(); //La funcion debe manejar el cierre de la alerta con Swal.close()
        }
    });
}