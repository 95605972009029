import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { alertaGeneral } from "../../utils/alertas";

const PagRespuestaAfiliadoPayU = () => {
    const data = JSON.parse(localStorage.getItem('registro-data')) || {};
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [transactionState] = searchParams.get('transactionState');
    const [isApproved, setIsApproved] = useState(false);

    useEffect(() => {
        if (transactionState == 4 && !isApproved) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/afiliado/registro`, data)
                .then(res => {
                    alertaGeneral({
                        titulo: 'Transacción Aprobada',
                        messageHtml: 'Se le han enviado las credenciales al correo ' + data.email,
                    });
                    setIsApproved(true);
                    localStorage.removeItem('registro-data');
                    navigate('/');
                }).catch(error => {
                    alertaGeneral({
                        titulo: 'Error',
                        messageHtml: error.response?.data?.message || 'No se pudo registrar el afiliado',
                        isError: true,
                    })
                })
        } else if (transactionState != 4) {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: 'La transaccion no se aprobo',
                isError: true
            });
            navigate('/registro', { state: { tipoRegistro: 'Afiliado', data: data } });
        }
    }, [transactionState, isApproved]);
}

export default PagRespuestaAfiliadoPayU