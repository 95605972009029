import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { alertaGeneral } from '../../utils/alertas';
import { useDataUser } from '../../context/dataUserContext';
import BotonSubmitPayU from '../PayU/BotonSubmitPayU';

const Cursos = () => {
    const { nombreAfiliado } = useParams();
    const [cargando, setCargando] = useState(true);
    const [cursos, setCursos] = useState([]);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const { token } = useDataUser();
    const [correo] = useState(localStorage.getItem('doctorEmail'));

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cursos/getCursos/${nombreAfiliado.toUpperCase()}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res.data);
                setCursos(res.data);
            })
            .catch(err => {
                alertaGeneral({
                    titulo: "Error",
                    messageHtml: "Ocurrió un error al cargar los cursos",
                    isError: true
                });
            })
            .finally(() => {
                setCargando(false);
            });
    }, []);

    const handleVerMas = (index) => {
        setCursoSeleccionado(index === cursoSeleccionado ? null : index);
    };

    const handleImagenClick = (url) => {
        const newWindow = window.open(url, "_blank");
        newWindow.focus();
    }

    if (cargando) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="p-4">
            <div className="flex flex-col items-center justify-center p-2">
                <h1 className="text-2xl font-bold py-2 px-5 text-center text-white bg-blue-600 rounded-lg shadow-md uppercase">Cursos Afiliado - {nombreAfiliado}</h1>
            </div>
            <div>
                {cursos && cursos.map((curso, index) => (
                    <div key={index} className="my-5 border p-4 rounded-lg bg-white shadow-md flex flex-col">
                        <h2 className="text-xl font-semibold">{curso.nombre}</h2>
                        <p className="text-gray-700"><b>COP</b> {curso.precio}</p>
                        <div className="flex justify-end">
                            <button
                                onClick={() => handleVerMas(index)}
                                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                            >
                                {cursoSeleccionado === index ? 'Ver Menos' : 'Ver Más'}
                            </button>
                        </div>
                        {cursoSeleccionado === index && (
                            <div className="mt-2 flex justify-end">
                                {/* <button
                                    className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                >
                                    Ir a Pagar
                                </button> */}
                                <BotonSubmitPayU
                                    rutaPeticion={`${process.env.REACT_APP_BACKEND_URL}/api/payU/procesar-pago-curso/${curso._id}`}
                                    correo={correo}
                                    descripcion={`Pago Curso ${curso.nombre}`}
                                    redireccion={`https://doctyai.com/doctor/pago-curso/${curso._id}`}
                                    //redireccion={`http://localhost:3000/doctor/pago-curso/${curso._id}`}
                                />
                            </div>
                        )}
                        <div
                            className={`mt-4 overflow-hidden transition-all duration-300 ease-in-out ${cursoSeleccionado === index ? 'max-h-screen' : 'max-h-0'}`}
                        >
                            
                            <img
                                src={curso.urlImage}
                                alt={curso.nombre}
                                className="w-full h-auto rounded-lg transition-opacity duration-300 cursor-pointer"
                                onClick={() => handleImagenClick(curso.urlImage)}
                            />
                        </div>
                    </div>
                ))}
                {cursos && cursos.length === 0 && (
                    <div className="text-center text-gray-600">
                        No hay cursos disponibles del afiliado {nombreAfiliado}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Cursos;