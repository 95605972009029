import { createContext, useContext, useMemo, useState } from "react";
import { jwtDecode } from 'jwt-decode';

const DataUserContext = createContext({
    isAuth: false,
    setIsAuth: () => { },
    token: '',
    service: '',
    role: '',
});

export const useDataUser = () => useContext(DataUserContext);

export function DataUserProvider({ children }) {
    const [isAuth, setIsAuth] = useState(localStorage.getItem('token'));

    const userData = useMemo(() => {
        if (isAuth) {
            const token = localStorage.getItem('token');
            const decode = jwtDecode(token);
            return {
                token: token,
                role: decode.role,
                service: decode.service || '',
            };
        }
        return {
            token: '',
            service: '',
            role: '',
        }
    }, [isAuth]);

    return (
        <DataUserContext.Provider value={{ isAuth, setIsAuth, ...userData }}>
            {children}
        </DataUserContext.Provider>
    )
}
