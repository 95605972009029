export const validarInputExpresionRegular = (e, regularExpression) => {
    const input = e.target.value.replace(regularExpression, '');
    return input;
}
/* Como llamarla: se llama asi:
    Tenemos la siguiente variable:
        - const [user, setUser] = useState('');
    Queremos actualizar solo su valor pero permitir solo letras, entonces en el input o 
    donde lo estemos manejando hacemos lo siguiente:
        <input
            id="user"
            name="user"
            value={user}
            onInput={(e) => setUser(validarInputExpresionRegular(e, /[^a-zA-Z\s]/g))}
        />
    Y ya asi bloquea los caracteres que no pertenezcan a la expresión regular, sirve para evitar 
    malos inputs y ahorrarse los mensajes de errores
*/