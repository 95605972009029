import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import image1 from '../../images/Logo_Mozart_color.png'; // Logo
import backgroundImage from '../../images/background2.jpg'; // Nueva imagen de fondo
import { validarInputExpresionRegular } from "../../utils/generalFunctions";
import BotonSubmitPayU from "../PayU/BotonSubmitPayU";
import axios from "axios";
import { alertaGeneral } from "../../utils/alertas";
import Swal from "sweetalert2";

export const Registro = () => {

    useEffect(() => {
        localStorage.removeItem('registro-data');
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const [tipoRegistro] = useState(location.state?.tipoRegistro);
    const [preInfo] = useState(location.state?.data);

    const [data, setData] = useState(null);
    const [error, setError] = useState(true);

    const onHandleSubmit = (e) => {
        e.preventDefault();
        tipoRegistro === 'Paciente' && registroPaciente();
    }

    const registroPaciente = async () => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/patientRegister/register`, data)
            .then((response) => {
                if (response.status === 201) {
                    alertaGeneral({
                        titulo: 'Registro Exitoso',
                        messageHtml: 'Las credenciales se enviaron al correo: ' + data.email,
                    })
                    navigate('/');
                }
            })
            .catch((error) => {
                alertaGeneral({
                    titulo: 'Error',
                    messageHtml: error?.response?.data?.message || 'Ocurrió un error al registrar el paciente',
                    isError: true
                });
            })
    }

    const validateEmailAfiliado = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/afiliado/validar-email/${data.email}`);
            if (res.data.isExists) {
                alertaGeneral({
                    titulo: 'Error',
                    messageHtml: 'El correo ya está registrado',
                    isError: true,
                });
                return true; // Indica que el correo ya existe
            }
            return false; // El correo no está registrado
        } catch (error) {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: error?.response?.data?.message || 'Ocurrió un error al validar el correo',
                isError: true,
            });
            return true; // Asume error como un caso inválido
        }
    };


    return (
        <div
            className="flex items-center justify-center min-h-screen bg-cover bg-center"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="w-full max-w-md bg-white bg-opacity-80 rounded-xl shadow-lg p-8 md:p-10 max-h-[95vh] overflow-y-auto backdrop-filter backdrop-blur-lg">
                <img src={image1} alt="Logo" className="w-32 mx-auto mb-6" />
                <h3 className="text-2xl font-semibold text-center mb-3 text-gray-800 title"> Registrarse Como {tipoRegistro} </h3>

                <form onSubmit={onHandleSubmit}>
                    {tipoRegistro === 'Paciente' && <RegistroPaciente setData={setData} />}
                    {tipoRegistro === 'Afiliado' && <RegistroAfiliado preInfo={preInfo} setData={setData} setError={setError} />}

                    <div className="flex justify-center mb-3">
                        <button
                            type="button"
                            className="text-blue-500 underline text-xs"
                            onClick={() => navigate('/')}
                        >
                            Ir a Iniciar Sesión
                        </button>
                    </div>
                    {tipoRegistro === 'Paciente' && (
                        <button
                            type="submit"
                            className="w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-3 focus:outline-none focus:ring-2 focus:ring-blue-9 00 px-4 rounded-lg hover:from-blue-600 hover:to-blue-800 transition duration-300"
                        >
                            Registrarse
                        </button>
                    )}

                    {tipoRegistro === 'Afiliado' && (
                        <BotonSubmitPayU
                            rutaPeticion={`${process.env.REACT_APP_BACKEND_URL}/api/payU/registro-afiliado`}
                            descripcion={'Pago Membresia'}
                            precio={3000}
                            correo={data?.email}
                            disabled={error}
                            disabledFunction={validateEmailAfiliado}
                            // redireccion={'http://localhost:3000/registro-afiliado'}
                            redireccion={'https://doctyai.com/registro-afiliado'}
                        />
                    )}
                </form>
            </div>
        </div>

    )
}

const RegistroPaciente = ({ setData }) => {

    const [formData, setFormData] = useState({
        firstName: '',
        secondName: '',
        firstLastName: '',
        secondLastName: '',
        email: '',
        cellNumber: '',
    });

    const validarTipoExpresionRegular = (e) => {
        if (e.target.name.includes('Name')) {
            return validarInputExpresionRegular(e, /[^a-zA-ZñÑ\s]/g);
        } else if (e.target.name === 'email') {
            return e.target.value;
        } else if (e.target.name === 'cellNumber') {
            return validarInputExpresionRegular(e, /[^0-9]/g);
        }
    }

    const onHandleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: validarTipoExpresionRegular(e),
        });
    }

    useEffect(() => {
        setData(formData);
    }, [formData])

    return (
        <>
            <div className="mb-3 relative">
                <label htmlFor="firstName" className="block text-gray-700 font-semibold">Primer Nombre</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Primer Nombre"
                        value={formData.firstName}
                        required
                        onInput={onHandleChange}
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
            </div>
            <div className="mb-3 relative">
                <label htmlFor="secondName" className="block text-gray-700 font-semibold">Segundo Nombre</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="secondName"
                        name="secondName"
                        placeholder="Segundo Nombre"
                        value={formData.secondName}
                        onInput={onHandleChange}
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
            </div>
            <div className="mb-3 relative">
                <label htmlFor="firstLastName" className="block text-gray-700 font-semibold">Primer Apellido</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="firstLastName"
                        name="firstLastName"
                        placeholder="Primer Apellido"
                        value={formData.firstLastName}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
            </div>
            <div className="mb-3 relative">
                <label htmlFor="secondLastName" className="block text-gray-700 font-semibold">Segundo Apellido</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="secondLastName"
                        name="secondLastName"
                        placeholder="Segundo Apellido"
                        value={formData.secondLastName}
                        onInput={onHandleChange}
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
            </div>
            <div className="mb-3 relative">
                <label htmlFor="email" className="block text-gray-700 font-semibold">Correo</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="tucorreo@ejemplo.com"
                        value={formData.email}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
            </div>
            <div className="mb-3 relative">
                <label htmlFor="cellNumber" className="block text-gray-700 font-semibold">Celular</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="cellNumber"
                        name="cellNumber"
                        maxLength={10}
                        placeholder="3212345678"
                        value={formData.cellNumber}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
            </div>
        </>
    )
}

const RegistroAfiliado = ({ preInfo, setData, setError }) => {
    const [formData, setFormData] = useState({
        fullName: preInfo?.fullName || '',
        idNumber: preInfo?.idNumber || '',
        email: preInfo?.email || '',
        cellNumber: preInfo?.cellNumber || '',
        service: preInfo?.service || 'jefe_enfermería',
    });

    const [inputsSelect, setInputsSelect] = useState({});
    const [errors, setErrors] = useState({});
    const [isSomeError, setIsSomeError] = useState(false);

    const validarTipoExpresionRegular = (e) => {
        switch (e.target.name) {
            case 'fullName':
                return e.target.value.replace(/[^a-zA-ZñÑ\s]/g, '');
            case 'idNumber':
                return e.target.value.replace(/[^0-9]/g, '');
            case 'email':
                return e.target.value;
            case 'cellNumber':
                return e.target.value.replace(/[^0-9]/g, '');
            default:
                return e.target.value;
        }
    }

    const validate = () => {
        let newErrors = {};

        if (!formData.fullName.trim()) {
            newErrors.fullName = 'El nombre completo es obligatorio.';
        } else if (formData.fullName.length < 8 || formData.fullName.length > 50) {
            newErrors.fullName = 'El nombre debe tener entre 8 y 50 caracteres.';
        }

        if (!formData.idNumber.trim()) {
            newErrors.idNumber = 'La cédula es obligatoria.';
        } else if (formData.idNumber.length < 7 || formData.idNumber.length > 10) {
            newErrors.idNumber = 'La cédula debe tener entre 7 y 10 caracteres.';
        }

        if (!formData.email.trim()) {
            newErrors.email = 'El correo es obligatorio.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'El correo no es válido.';
        }

        if (!formData.cellNumber.trim()) {
            newErrors.cellNumber = 'El celular es obligatorio.';
        } else if (!formData.cellNumber.startsWith('3')) {
            newErrors.cellNumber = 'El celular debe iniciar con 3.';
        } else if (formData.cellNumber.length !== 10) {
            newErrors.cellNumber = 'El celular debe tener 10 caracteres.';
        }

        if (!formData.service.trim()) {
            newErrors.service = 'El servicio es obligatorio.';
        }

        setErrors(newErrors);
        setIsSomeError(Object.keys(newErrors).length > 0);
        return Object.keys(newErrors).length === 0;
    }

    const onHandleChange = (e) => {
        setInputsSelect({ ...inputsSelect, [e.target.name]: { name: e.target.name, state: true } });
        setFormData({
            ...formData,
            [e.target.name]: validarTipoExpresionRegular(e),
        });
    }

    //Cada que cambie el formulario lo validamos
    useEffect(() => {
        validate();
    }, [formData])

    //Enviamos los datos al componente padre el formData y si hay un error
    useEffect(() => {
        setData(formData);
        setError(isSomeError);
        localStorage.setItem('registro-data', JSON.stringify(formData));
    }, [formData, errors, isSomeError]);

    return (
        <>
            <div className="mb-3 relative">
                <label htmlFor="fullName" className="block text-gray-700 font-semibold">Nombre Completo *</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        placeholder="Tu Nombre Completo"
                        value={formData.fullName}
                        required
                        onInput={onHandleChange}
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
                {inputsSelect['fullName']?.state && errors.fullName && <span className="text-red-500 text-sm">{errors.fullName}</span>}
            </div>
            <div className="mb-3 relative">
                <label htmlFor="idNumber" className="block text-gray-700 font-semibold">Cédula *</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="idNumber"
                        name="idNumber"
                        maxLength={10}
                        placeholder="123498765"
                        value={formData.idNumber}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
                {inputsSelect['idNumber']?.state && errors.idNumber && <span className="text-red-500 text-sm">{errors.idNumber}</span>}
            </div>
            <div className="mb-3 relative">
                <label htmlFor="email" className="block text-gray-700 font-semibold">Correo *</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="tucorreo@ejemplo.com"
                        value={formData.email}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
                {inputsSelect['email']?.state && errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
            </div>
            <div className="mb-3 relative">
                <label htmlFor="cellNumber" className="block text-gray-700 font-semibold">Celular *</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <input
                        type="text"
                        id="cellNumber"
                        name="cellNumber"
                        maxLength={10}
                        placeholder="3212345678"
                        value={formData.cellNumber}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    />
                </div>
                {inputsSelect['cellNumber']?.state && errors.cellNumber && <span className="text-red-500 text-sm">{errors.cellNumber}</span>}
            </div>
            <div className="mb-3 relative">
                <label htmlFor="idNumber" className="block text-gray-700 font-semibold">Servicio *</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <select
                        type="text"
                        id="service"
                        name="service"
                        value={formData.service}
                        onInput={onHandleChange}
                        required
                        className="w-full px-4 py-2 bg-transparent focus:outline-none"
                    >
                        <option value="jefe_enfermería"> Jefe de Enfermería </option>
                        <option value="auxiliar_enfermería"> Auxiliar Enfermería </option>
                        <option value="328 medicina_general"> Medicina General </option>
                        <option value="325 medicina_familiar"> Medicina Familiar </option>
                        <option value="cuidador"> Cuidador </option>
                        <option value="fonoaudiología"> Fonoaudiología </option>
                        <option value="ingeniero_sistemas"> Ingeniero Sistemas </option>
                        <option value="nutrición"> Nutrición </option>
                        <option value="psicología"> Psicología </option>
                        <option value="terapia_física"> Terapia Física </option>
                        <option value="terapia_integral"> Terapia Integral </option>
                        <option value="trabajador_social"> Trabajador Social </option>
                        <option value="auxiliar_administrativo"> Auxiliar Administrativo </option>
                        <option value="terapeuta_respiratorio"> Terapeuta Respiratorio </option>
                        <option value="terapeuta_ocupacional"> Terapeuta Ocupacional </option>
                        <option value="abogado"> Abogado </option>
                    </select>
                </div>
                {inputsSelect['service']?.state && errors.service && <span className="text-red-500 text-sm">{errors.service}</span>}
            </div>
        </>
    );
}
export default RegistroAfiliado;
