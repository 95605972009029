import React, { useState, useEffect } from 'react';
import { alertaGeneral } from '../../utils/alertas';

const ReviewProfile = ({ doctor, onGoBack, fetchDoctors }) => {
    const [updatedDoctor, setUpdatedDoctor] = useState(null);
    useEffect(() => {
        // Agregamos las URLs a cada documento para facilitar el manejo
        const mappedDocuments = doctor.documentsApproved.map((doc) => {
            const urlMapping = {
                foto_perfil: doctor.urlProfessionalPhoto,
                cedulaPDF: doctor.urlIdDocument,
                diploma_acta_grado: doctor.urlUndergraduateDiploma,
                tarjeta_profesional: doctor.urlProfessionalCard,
                homologacion_estudios: doctor.urlStudyHomologation,
                poliza_responsabilidad_civil: doctor.urlCivilLiabilityPolicyDocument,
                rethus: doctor.urlRethusDocument,
                diploma_especializacion: doctor.urlSpecializationDiploma,
                firma_profesional: doctor.urlProfessionalSignature,
            };
            return {
                ...doc,
                url: urlMapping[doc.document] || '',
            };
        });

        setUpdatedDoctor({ ...doctor, documentsApproved: mappedDocuments });
    }, [doctor]);

    const handleDocumentChange = (index, action, reason = '') => {
        const updatedDocuments = [...updatedDoctor.documentsApproved];
        updatedDocuments[index].isApproved = action === 'approved';
        if (action === 'rejected') {
            updatedDocuments[index].rejectionReason = reason;
        } else {
            updatedDocuments[index].rejectionReason = '';
        }
        setUpdatedDoctor({ ...updatedDoctor, documentsApproved: updatedDocuments });
    };

    const handleSaveChanges = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/updateDoctorDocuments/${updatedDoctor._id}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        documentsApproved: updatedDoctor.documentsApproved,
                    }),
                }
            );

            if (response.ok) {
                alertaGeneral({
                    titulo: 'Cambios Guardados',
                    texto: 'Los cambios se guardaron correctamente.',
                });
                fetchDoctors(); // Actualizar la lista principal
                onGoBack(); // Volver a la lista principal
            } else {
                alertaGeneral({
                    titulo: 'Error',
                    messageHtml: 'No se pudieron guardar los cambios.',
                    isError: true,
                });
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            alertaGeneral({
                titulo: 'Error',
                messageHtml: 'Ocurrió un error al guardar los cambios.',
                isError: true,
            });
        }
    };

    const handleViewDocument = (url) => {
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: 'El documento no tiene una URL válida.',
                isError: true
            });
        }
    };

    if (!updatedDoctor) return <div>Cargando...</div>;

    return (
        <div className="flex flex-col min-h-screen">
            {/* Header */}
            <div className="flex p-4 items-center justify-between bg-white shadow-md border-b border-gray-200">
                <h1 className="text-2xl font-semibold text-gray-800">Revisión de Perfil y Documentos</h1>
                <button
                    onClick={onGoBack}
                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                >
                    Volver
                </button>
            </div>

            <main className="max-w-full mt-6">
                {/* Datos del Perfil */}
                <div className="max-w-full mb-8">
                    <h2 className="text-xl font-semibold mb-4">Datos del Perfil</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Nombre Completo</label>
                            <input
                                type="text"
                                value={updatedDoctor.fullName}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm bg-gray-100"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Correo Electrónico</label>
                            <input
                                type="email"
                                value={updatedDoctor.email}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm bg-gray-100"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Número de Teléfono</label>
                            <input
                                type="tel"
                                value={updatedDoctor.cellNumber}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm bg-gray-100"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Tipo de ID</label>
                            <input
                                type="text"
                                value={updatedDoctor.idType}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm bg-gray-100"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Número de ID</label>
                            <input
                                type="text"
                                value={updatedDoctor.idNumber}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm bg-gray-100"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Servicio</label>
                            <input
                                type="text"
                                value={updatedDoctor.service}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm bg-gray-100"
                            />
                        </div>
                    </div>
                </div>


                {/* Documentos */}
                <div className="max-w-full">
                    <h2 className="text-2xl font-bold mb-6">Documentos</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y border border-gray-200 divide-gray-200 shadow-lg rounded-lg">
                            <thead className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900  text-center">
                                <tr>
                                    <th className="px-6 py-4 text-lg font-medium text-white">Nombre del Documento</th>
                                    <th className="px-6 py-4 text-lg font-medium text-white">Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {updatedDoctor.documentsApproved.map((doc, index) => (
                                    <tr key={index} className="divide-x divide-gray-200 hover:bg-gray-100 transition duration-300">
                                        <td className="px-6 py-4">
                                            <p className="text-md text-wrap text-center font-medium text-gray-700 uppercase"> {doc.document?.replace(/_/g, ' ')} </p>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
                                                <button
                                                    onClick={() => handleViewDocument(doc.url)}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                                                >
                                                    Ver
                                                </button>
                                                <select
                                                    value={doc.isApproved ? 'approved' : 'rejected'}
                                                    onChange={(e) => handleDocumentChange(index, e.target.value)}
                                                    className="px-3 py-2 border rounded-md text-gray-700"
                                                >
                                                    <option value="approved">Aprobar</option>
                                                    <option value="rejected">Rechazar</option>
                                                </select>
                                                {!doc.isApproved && (
                                                    <textarea
                                                        placeholder="Motivo del rechazo"
                                                        value={doc.rejectionReason || ''}
                                                        onChange={(e) => handleDocumentChange(index, 'rejected', e.target.value)}
                                                        className="mt-2 w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-200 transition duration-300"
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex justify-end mt-6">
                    <button
                        onClick={handleSaveChanges}
                        className="px-5 py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                    >
                        Guardar Cambios
                    </button>
                </div>
            </main>
        </div>
    );
};

export default ReviewProfile;
