import { useEffect, useState } from 'react';
import axios from 'axios';
import { alertaGeneral } from '../../utils/alertas';

const BotonSubmitPayU = ({ doctorId = '', descripcion, correo, rutaPeticion, disabled = false, redireccion, disabledFunction }) => {
  const [botonEnviar, setBotonEnviar] = useState(false);
  // Variables de PayU
  const [referenceCode, setReferenceCode] = useState('');
  const [signature, setSignature] = useState('');

  const [merchantId, setMerchantId] = useState('');
  const [accountId, setAccountId] = useState('');
  const [precio, setPrecio] = useState('');

  const [isPrePagoComplete, setIsPrePagoComplete] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.post(rutaPeticion, { correo, doctorId },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(res => {
      const { merchantId, accountId, referenceCode, signature, precio } = res.data;
      setMerchantId(merchantId);
      setAccountId(accountId);
      setReferenceCode(referenceCode);
      setSignature(signature);
      setPrecio(precio);
      setIsPrePagoComplete(true); //La peticion se completó
    }).catch(err => {
      alertaGeneral({
        titulo: 'Error',
        messageHtml: 'Ocurrio un error al pre-procesar el pago, intente nuevamente',
        isError: true
      });
    })
  }, [botonEnviar]);

  const onHandlePago = async (e) => {
    e.preventDefault();

    if (disabledFunction) {
      const isDisabled = await disabledFunction();
      if (isDisabled) {
        return;
      }
    }

    // Redirige manualmente a la URL de PayU
    const form = e.target.closest('form');
    form.submit();
  }

  // Tarjeta Credito Sandbox: 5471300000000003 - Vencimiento: 6/25 - CVV: 777 - Para aprobado
  // CVV: 666 para declinado y Vencimiento: 12/24

  return (
    <>
      <div>
        {/* <form method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu/"> */}
        <form method="post" action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/">

          <input name="merchantId" type="hidden" value={merchantId} />

          <input name="accountId" type="hidden" value={accountId} />

          <input name="description" type="hidden" value={descripcion} />

          <input name="referenceCode" type="hidden" value={referenceCode} />

          <input name="amount" type="hidden" value={precio} />

          <input name="tax" type="hidden" value="0" />

          <input name="taxReturnBase" type="hidden" value="0" />

          <input name="currency" type="hidden" value="COP" />

          <input name="signature" type="hidden" value={signature} />

          <input name="test" type="hidden" value="0" />

          <input name="buyerEmail" type="hidden" value={correo} />

          <input name="responseUrl" type="hidden" value={redireccion ? redireccion : "https://doctyai.com/patient/aprobacion-cita"} />
          {/* <input name="responseUrl" type="hidden" value={redireccion ? redireccion : "http://localhost:3000/patient/aprobacion-cita"} /> */}

          <input name="confirmationUrl" type="hidden" value="https://doctyai.com/confirmacion" />
          {/* <input name="confirmationUrl" type="hidden" value="http://localhost:3000/confirmacion" /> */}


          <button
            className={`w-full px-3 py-1 ${disabled ? 'bg-green-300' : 'bg-green-500 hover:bg-green-600'} text-white rounded focus:outline-none`}
            name="Submit"
            type="submit"
            onClick={(e) => { onHandlePago(e) }}
            disabled={disabled || !isPrePagoComplete}
          >
            Ir a Pagar
          </button>

        </form>
      </div >
    </>
  )
}

export default BotonSubmitPayU;
