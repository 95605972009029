import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import DoctorCard from './DoctorCard';
import Swal from 'sweetalert2';
import { alertaGeneral } from '../../utils/alertas';
import { useDataUser } from '../../context/dataUserContext';

const Marketplace = () => {
    const [servicio, setServicio] = useState('');
    const [orden, setOrden] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [cargando, setCargando] = useState(true);

    const { role } = useDataUser();
    useEffect(() => {
        localStorage.removeItem('selectedDate');
        const token = localStorage.getItem('token');
        const clinicId = localStorage.getItem('clinicId');
        const patientId = localStorage.getItem('patientId');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patient/getDoctors/${clinicId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.data) {
                setDoctors(response.data);
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                toast: true,
                position: 'top-right',
                text: error?.response?.data?.message || 'Ocurrió un error al cargar los doctores',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            })
        }).finally(() => {
            setCargando(false);
        });
        if (role === 'patient') {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patient/isCompleteProfile/${patientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => setIsProfileComplete(response.data.isComplete))
                .catch(error => alertaGeneral({
                    titulo: 'Error',
                    messageHtml: 'Ocurrió un error al cargar el perfil',
                    isError: true
                }));
        }
    }, [])

    const doctorsFiltrados = doctors
        .filter(doc => (servicio ? doc.service.includes(servicio) : true))
        .sort((a, b) => {
            if (orden === 'precio-asc') return a.priceService - b.priceService;
            if (orden === 'precio-desc') return b.priceService - a.priceService;
            return 0;
        });

    const resetFiltros = () => {
        setServicio('');
        setOrden('');
    };

    if (cargando) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (

        <div className="flex flex-col md:flex-row p-4">
            <Filtros
                orden={orden} setOrden={setOrden}
                servicio={servicio} setServicio={setServicio}
                resetFiltros={resetFiltros}
            />

            <div className="container mx-auto px-0 py-8">
                <div className="max-w-full mx-auto">
                    {/* Aqui se renderiza el card del doctor */}
                    {doctorsFiltrados && doctorsFiltrados.length > 0 ? doctorsFiltrados.map(doc => (
                        <DoctorCard key={doc._id} doctor={doc} isProfileComplete={isProfileComplete} />
                    )) : <h1 className="text-center text-2xl">No hay afiliados con este servicio disponibles</h1>}

                </div>
            </div>
        </div>
    );
};

/* Mini componente para filtro */
const Filtros = ({ orden, setOrden, setServicio, resetFiltros }) => {
    return (
        <div className="w-full md:w-1/4 p-4 bg-gray-100">
            <h2 className="text-xl font-bold mb-4">Filtros</h2>
            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Servicio</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                    <select
                        type="text"
                        id="service"
                        name="service"
                        onChange={e => setServicio(e.target.value)}
                        className="w-full px-4 py-2 bg-white border-none rounded-lg focus:outline-none"
                    >
                        <option value="">Todos</option>
                        <option value="jefe enfermería"> Jefe de Enfermería </option>
                        <option value="auxiliar enfermería"> Auxiliar Enfermería </option>
                        <option value="medicina general"> Medicina General </option>
                        <option value="medicina familiar"> Medicina Familiar </option>
                        <option value="cuidador"> Cuidador </option>
                        <option value="fonoaudiología"> Fonoaudiología </option>
                        <option value="ingeniero sistemas"> Ingeniero Sistemas </option>
                        <option value="nutrición"> Nutrición </option>
                        <option value="psicología"> Psicología </option>
                        <option value="terapia física"> Terapia Física </option>
                        <option value="terapia integral"> Terapia Integral </option>
                        <option value="trabajador social"> Trabajador Social </option>
                        <option value="auxiliar administrativo"> Auxiliar Administrativo </option>
                        <option value="terapeuta respiratorio"> Terapeuta Respiratorio </option>
                        <option value="terapeuta ocupacional"> Terapeuta Ocupacional </option>
                        <option value="abogado"> Abogado </option>
                    </select>
                </div>
            </div>

            <h2 className="text-xl font-bold mb-4">Ordenar por</h2>
            <div>
                <label className="block text-gray-700 font-bold mb-2">Precio</label>
                <div className="flex flex-wrap">
                    <label className="mr-4 mb-2">
                        <input
                            type="radio"
                            name="orden"
                            value="precio-asc"
                            onChange={() => setOrden('precio-asc')}
                            checked={orden === 'precio-asc'}
                            className="mr-2"
                        />
                        Más Bajo
                    </label>
                    <label className="mr-4 mb-2">
                        <input
                            type="radio"
                            name="orden"
                            value="precio-desc"
                            onChange={() => setOrden('precio-desc')}
                            checked={orden === 'precio-desc'}
                            className="mr-2"
                        />
                        Más Alto
                    </label>
                </div>
            </div>
            <button
                onClick={resetFiltros}
                className="bg-red-500 text-white px-4 py-2 rounded w-full mt-2"
            >
                Resetear Filtros
            </button>
        </div>
    )
}

export default Marketplace;
