import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { format } from '@formkit/tempo';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { alertaGeneral } from '../../utils/alertas';
import '../../styles/Patient.css';
import { useNavigate } from 'react-router-dom';
import { useDataUser } from '../../context/dataUserContext';

const DoctorCalendar = ({ doctor, show, isProfileComplete }) => {
    const { role } = useDataUser();
    const [cargando, setCargando] = useState(true);
    const [diasOcupados, setDiasOcupados] = useState([]);
    const [calendarioOficina, setCalendarioOficina] = useState([]);
    const [calendarView, setCalendarView] = useState('timeGridWeek');
    const navigate = useNavigate();

    useEffect(() => {
        // Detectar tamaño de pantalla y ajustar la vista
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setCalendarView('timeGrid3Days'); // Vista personalizada para móviles
            } else {
                setCalendarView('timeGridWeek'); // Vista normal para pantallas grandes
            }
        };

        // Configurar el evento resize
        window.addEventListener('resize', handleResize);

        // Ejecutar la función de detección al montar
        handleResize();

        // Limpiar el evento resize al desmontar
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patient/getCalendarDoctor/${doctor._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                if (response) {
                    setCalendarioOficina(response.data.calendarioOficina);
                    setDiasOcupados(response.data.diasOcupados);
                }
                setCargando(false);
            }).catch(error => {
                alertaGeneral({ titulo: 'Error', messageHtml: 'Ocurrió un error al cargar el calendario', isError: true });
                setCargando(false);
            })
    }, [doctor]);

    if (!show) return null;

    if (cargando) {
        return (
            <p> Cargando Calendario... </p>
        )
    }

    /* Esta es la funcion encargada de mostrar los horarios en intervalos, genera los eventos en 
    el calendario, tiene toda la logica dentro, usando funciones internas para llamarse internamente, verifica
    que la hora no haya pasado ya, si ya paso la hora de la disponibilidad, no la muestra y muestra la siguiente,
    es decir, si un doctor trabaja los lunes a las 8:00 hasta la 13:00 y yo como paciente entro a la tienda un lunes y son las 9:00
    ya el horario de las 8:00 no lo muestra porque ya pasó. También verifica que días ya tiene cita el doctor y demás */
    const generateEvents = (calendarioOficina) => {
        const events = [];
        const ocupadosDates = diasOcupados.map(dateStr => new Date(dateStr));
        const now = new Date();

        const createOccupiedEvent = (start, end) => {
            return {
                title: '',
                start: start,
                end: end,
                classNames: ['marketplace-event-ocupado'],
                display: 'block',
                editable: false,
                selectable: false,
                durationEditable: false,
            };
        };

        const isIntervalOccupied = (start) => {
            return ocupadosDates.some(ocupado => {
                return (
                    ocupado.getDate() === start.getDate() &&
                    ocupado.getMonth() === start.getMonth() &&
                    ocupado.getFullYear() === start.getFullYear() &&
                    ocupado.getHours() === start.getHours() &&
                    ocupado.getMinutes() === start.getMinutes()
                );
            });
        };

        const currentDate = new Date();
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);

        for (let d = new Date(currentDate); d <= lastDayOfMonth; d.setDate(d.getDate() + 1)) {
            calendarioOficina.length > 0 && calendarioOficina.forEach(({ dia, intervalos }) => {
                const dayOfWeek = getDayOfWeek(dia);

                if (d.getDay() !== dayOfWeek) return;

                intervalos.forEach(({ inicio, fin }) => {
                    const start = new Date(d);
                    start.setHours(parseInt(inicio.split(':')[0]), parseInt(inicio.split(':')[1]));
                    const end = new Date(d);
                    end.setHours(parseInt(fin.split(':')[0]), parseInt(fin.split(':')[1]));

                    // Si es el día actual y el inicio ya pasó, lo excluimos
                    if (
                        d.toDateString() === now.toDateString() &&
                        start <= now
                    ) return;

                    const isOccupied = isIntervalOccupied(start);

                    if (isOccupied) {
                        const occupiedEvent = createOccupiedEvent(start, end);
                        events.push(occupiedEvent);
                    } else {
                        events.push({
                            title: '',
                            start,
                            end,
                            classNames: ['marketplace-event-disponibilidad'],
                            display: 'block',
                        });
                    }
                });
            });
        }

        return events;
    };


    const getDayOfWeek = (dia) => {
        const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
        return days.indexOf(dia.toLowerCase());
    };

    const handleEventClick = async (info) => {
        if(role !== 'patient') {
            return;
        }
        //Primero verificamos que el paciente tenga su perfil completo
        if (!isProfileComplete) {
            Swal.fire({
                icon: 'error',
                title: 'Perfil incompleto',
                text: 'Por favor completa tu perfil antes de agendar una cita.',
                confirmButtonText: 'Ir a perfil',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/patient/perfil');
                }
            })
            return;
        }


        // Verifica si el evento es ocupado
        if (info.event.classNames.includes('marketplace-event-ocupado')) {
            Swal.fire({
                icon: 'error',
                title: 'Cita ocupada',
                text: 'Este horario ya está reservado.',
                confirmButtonText: 'Cerrar',
            });
            return; // No continuar si el evento es ocupado
        }

        const inicio = format(info.event.start, 'HH:mm A');
        const fin = format(info.event.end, 'HH:mm A');
        Swal.fire({
            icon: 'info',
            title: 'Disponibilidad seleccionada',
            text: `Has seleccionado un horario disponible: ${inicio} - ${fin}`,
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(info.event.start)
                const selectedDate = {
                    doctorId: doctor._id,
                    doctor: doctor.fullName,
                    servicio: doctor.service,
                    precioServicio: doctor.priceService,
                    fecha: info.event.start,
                };
                localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
                navigate('/patient/agendar-cita');
            }
        });
    };

    return (
        <div className="calendar-container">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={calendarView}
                events={generateEvents(calendarioOficina)}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: `${calendarView}`,
                }}
                locale={esLocale}
                selectable={true}
                weekends={true}
                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'short',
                    hour12: true,
                }}
                slotDuration={"00:20:00"}
                slotEventOverlap={false}
                allDaySlot={false}
                eventClick={handleEventClick}
                buttonText={{
                    timeGrid3Days: '3 Días'
                }}
                views={{
                    timeGridWeek: {
                        dayCount: 14,
                    },
                    timeGrid3Days: {
                        type: 'timeGrid',
                        duration: { days: 3 },
                    }
                }}
            />
        </div>
    );

};

export default DoctorCalendar;
