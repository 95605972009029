import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { alertaGeneral } from '../../utils/alertas';
import { useDataUser } from '../../context/dataUserContext';

const CursosPagos = () => {
    const [pagos, setPagos] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const { token } = useDataUser();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cursos/getCursosPagos`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                setPagos(response.data);
            })
            .catch((error) => {
                alertaGeneral({
                    titulo: 'Error',
                    messageHtml: error.response?.data?.message || 'Error al obtener los cursos pagos',
                    isError: true
                });
            });
    }, []);

    const filtrarPagosPorFecha = () => {
        if (startDate && endDate) {
            const start = moment(startDate).startOf('day');
            const end = moment(endDate).endOf('day');
            const pagosFiltrados = pagos.filter(pago => {
                const fechaPago = moment(pago.date);
                return fechaPago.isBetween(start, end);
            });
            return pagosFiltrados;
        }
        return pagos;
    };

    const pagosFiltrados = filtrarPagosPorFecha();

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">Cursos Pagos</h1>
            <div className="mb-4 flex justify-center space-x-4">
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="px-4 py-2 border border-gray-300 rounded-md"
                />
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="px-4 py-2 border border-gray-300 rounded-md"
                />
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                >
                    Filtrar
                </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {pagosFiltrados.map((pago) => (
                    <div key={pago._id} className="bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold">{pago.nombreCurso}</h2>
                        <p className="text-gray-700"><strong>Pagador:</strong> {pago.doctorId.fullName}</p>
                        <p className="text-gray-700"><strong>Afiliado:</strong> {pago.nombreAfiliado}</p>
                        <p className="text-gray-700"><strong>Fecha:</strong> {pago.date}</p>
                        <p className="text-gray-700"><strong>Código de Referencia:</strong> {pago.referenceCode}</p>
                        <p className="text-gray-700"><strong>Precio:</strong> COP {pago.precio}</p>
                    </div>
                ))}
                {pagosFiltrados.length === 0 && (
                    <div className="text-center text-gray-600 col-span-3">
                        No hay pagos disponibles en el rango de fechas seleccionado
                    </div>
                )}
            </div>
        </div>
    );
};

export default CursosPagos;
