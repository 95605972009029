import React, { useState } from 'react';
import RevisionDocumentos from './RevisionDocumentos';

const RevisionAfiliados = () => {
  const [vistaSeleccionada, setVistaSeleccionada] = useState(null); // Estado para manejar la vista activa

  const profesionalesEnRevision = [
    { id: 1, nombre: "Dr. Juan Pérez", especialidad: "Cardiología" },
    { id: 2, nombre: "Dra. María González", especialidad: "Pediatría" },
  ];

  return (
    <div className="p-6  min-h-screen flex flex-col items-center">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Revisión de Afiliados</h1>

      {/* Mostrar la lista de profesionales */}
      {vistaSeleccionada === null && (
        <div className="grid gap-6 w-full max-w-4xl">
          {profesionalesEnRevision.map((profesional) => (
            <div
              key={profesional.id}
              className="bg-white rounded-xl shadow-lg p-6 flex justify-between items-center transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            >
              <div>
                <h2 className="text-xl font-semibold text-gray-800">{profesional.nombre}</h2>
                <p className="text-md text-gray-600">{profesional.especialidad}</p>
              </div>
              <div className="flex gap-2">
                {/* Botón para revisar documentos */}
                <button
                  onClick={() => setVistaSeleccionada({ tipo: 'documentos', id: profesional.id })}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Revisión
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Renderizar la vista de documentos o perfil según lo seleccionado */}
      {vistaSeleccionada !== null && vistaSeleccionada.tipo === 'documentos' && (
        <RevisionDocumentos id={vistaSeleccionada.id} setVistaSeleccionada={setVistaSeleccionada} />
      )}
    </div>
  );
};

export default RevisionAfiliados;
