import React, { useRef, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import Swal from 'sweetalert2';
import { format } from '@formkit/tempo';
import { alertaCargandoProceso, alertaGeneral } from '../../utils/alertas';
import axios from 'axios';
import '../../styles/CalendarDisponibility.css';
import { useDataUser } from '../../context/dataUserContext';
import { useNavigate } from 'react-router-dom';

const CalendarDisponibility = () => {
    const bigCalendarRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [diasSemana] = useState(['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']);
    const [showExternalEvents, setShowExternalEvents] = useState(false);
    const [horarioOficina, setHorarioOficina] = useState([]);
    const [horarioEspecifico, setHorarioEspecifico] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [cargando, setCargando] = useState(true);

    const navigate = useNavigate();
    const { token } = useDataUser();
    const [isAuthorized, setIsAuthorized] = useState(false);

    console.log("Horario Oficina:", horarioOficina);
    console.log("Horario Especifico:", horarioEspecifico);

    useEffect(() => {
        const doctorId = localStorage.getItem('doctorId');

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/afiliado/getState/${doctorId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if(res.data){
                    switch (res.data.state) {
                        case 'aprobado':
                            setIsAuthorized(true);
                            break;
                        case 'no_aprobado':
                            alertaGeneral({
                                titulo: 'Prestación de servicios inhabilitada',
                                messageHtml: 'Dirigete a tú perfil para habilitar esta opción',
                                isWarning: true
                            })
                            navigate('/doctor/perfil');
                            break;
                        case 'en_revision':
                            alertaGeneral({
                                titulo: 'Tu solicitud de prestación de servicios está en revisión',
                                isWarning: true
                            });
                            navigate('/doctor/perfil');
                            break;
                        case 'rechazado':
                            alertaGeneral({
                                titulo: 'Tu solicitud de prestación de servicios ha sido rechazada',
                                messageHtml: 'Reenvía los documentos nuevamente',
                                isWarning: true
                            });
                            navigate('/doctor/perfil');
                            break;
                    }
                }
            })
            .catch(error => {
                alertaGeneral({
                    titulo: "Error",
                    messageHtml: error.response?.data?.message || "Ocurrió un error inesperado",
                    isError: true
                });
            })
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('token');
        const doctorId = localStorage.getItem('doctorId');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/getCalendar/${doctorId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            setHorarioOficina(res.data.horariosOficina);
            setHorarioEspecifico(res.data.horariosEspecificos);
            // console.log(res);
            setCargando(false);
        })
    }, [refresh]);

    useEffect(() => {
        const newEvents = [];
        const diasSemanaLocal = {
            "lunes": 1,
            "martes": 2,
            "miercoles": 3,
            "jueves": 4,
            "viernes": 5,
            "sabado": 6,
            "domingo": 0
        };

        // Procesar horarios de oficina
        horarioOficina && horarioOficina.forEach(horario => {
            const dia = diasSemanaLocal[horario.dia.toLowerCase()];
            horario.intervalos.forEach(intervalo => {
                newEvents.push({
                    id: new Date().toLocaleString() + Math.random(999), // ID único
                    title: 'Horario Fijo',
                    tipo: 'fijo',
                    daysOfWeek: [dia],
                    startTime: intervalo.inicio,
                    endTime: intervalo.fin,
                    className: 'wrap-event-oficina',
                    backgroundColor: 'rgb(33, 196, 93)',
                    borderColor: 'rgb(33, 196, 93)',
                    textColor: '#ffffff',
                    editable: true,
                });
            });
        });

        // Procesar horarios específicos
        horarioEspecifico && horarioEspecifico.forEach(horario => {
            const diaEspecifico = new Date(horario.dia);
            const fechaISO = diaEspecifico.toISOString().split('T')[0]; // Obtén la fecha en formato "YYYY-MM-DD"
            horario.intervalos.forEach(intervalo => {
                newEvents.push({
                    id: new Date().toLocaleString() + Math.random(999), // ID único
                    title: 'Horario Especifico',
                    tipo: 'una vez',
                    start: `${fechaISO}T${intervalo.inicio}:00`,
                    end: `${fechaISO}T${intervalo.fin}:00`,
                    className: 'wrap-event-especifico',
                    backgroundColor: '#d33',
                    borderColor: '#d33',
                    textColor: '#ffffff',
                    editable: true,
                });
            });
        });

        setEvents(newEvents);
    }, [cargando]);

    useEffect(() => {
        //Cada vez que cambie events, se actualiza el horario de oficina y el horario especifico
        console.log("Cambiando events:", events);
        setHorarioEspecifico(validateHorario('una vez'));
        setHorarioOficina(validateHorario('fijo'));
    }, [events]);

    useEffect(() => {
        let draggableEl = document.getElementById('external-events');
        if (draggableEl) {
            new Draggable(draggableEl, {
                itemSelector: '.fc-event',
                eventData: function (eventEl) {
                    return {
                        title: eventEl.innerText.trim(),
                        id: Date.now().toString(), // Generar un ID único para cada evento
                        backgroundColor: '#000',
                        borderColor: '#000',
                        textColor: '#ffffff'
                    };
                }
            });
        }
    }, [showExternalEvents]);

    const handleDateClick = (info) => {
        const calendarApi = bigCalendarRef.current.getApi();
        calendarApi.gotoDate(info.date);
    };

    const handleEventClick = (info) => {
        const dia = diasSemana[info.event.start.getDay()];

        Swal.fire({
            title: 'Información Horario',
            html: mensajeHtmlParaAlerta({
                dia: dia,
                tipoHorario: info.event.extendedProps.tipo,
                horaInicio: format(info.event.start, 'HH:mm'),
                horaFin: (info.event.end && format(info.event.end, 'HH:mm') || 'Indefinido')
            }),
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#D33',
            cancelButtonText: 'Salir'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: '¿Está seguro?',
                    text: 'No podrá revertir esta acción',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, eliminar',
                    confirmButtonColor: '#D33',
                    cancelButtonText: 'Cancelar',
                    cancelButtonColor: '#3085d6'
                }).then((result) => {
                    if (result.isConfirmed) {
                        info.event.remove();
                        setEvents(prevEvents => prevEvents.filter(event => event.id !== info.event.id));
                    }
                })
            }
        });
    };

    const isCalendarioOficinaOEspecifico = async (info) => {
        const dia = diasSemana[info.event.start.getDay()];
        const result = await Swal.fire({
            title: 'Tipo de horario',
            confirmButtonText: `Repetir Dias ${dia}`,
            confirmButtonColor: '#3085d6',
            showCancelButton: true,
            cancelButtonText: 'Evento una vez',
            cancelButtonColor: 'rgb(125, 168, 197)',
        });
        if (result.isConfirmed) {
            return 'fijo';
        } else {
            return 'una vez';
        }
    }

    /* **** INICIO MANEJO DE EVENTOS, (PONER, ARRASTRAR Y REDIMENSIONAR ) ****   */
    //Evento Cuando recien se agrega un horario
    const handleEventReceive = async (info) => {
        console.log("Evento recibido", info.event);
        const tipo = await isCalendarioOficinaOEspecifico(info);
        if (tipo) {
            info.event.setEnd(new Date(info.event.start.getTime() + 60 * 60000)); // Añade 60 minutos
            setEvents(prevEvents => [
                ...prevEvents,
                tipo === 'fijo' ? {
                    id: info.event.id,
                    title: info.event.title + ' ' + tipo,
                    start: info.event.start,
                    end: info.event.end,
                    startTime: format(info.event.start, 'HH:mm'),
                    endTime: format(info.event.end, 'HH:mm'),
                    daysOfWeek: [info.event.start.getDay()],
                    tipo: tipo,
                    className: `wrap-event-${tipo === 'fijo' ? 'oficina' : 'especifico'}`,
                    backgroundColor: `${tipo === 'fijo' ? 'rgb(33, 196, 93)' : '#d33'} `,
                    borderColor: `${tipo === 'fijo' ? 'rgb(33, 196, 93)' : '#d33'} `,
                    textColor: '#ffffff'
                } : {
                    id: info.event.id,
                    title: info.event.title + ' ' + tipo,
                    start: info.event.start,
                    end: info.event.end,
                    tipo: tipo,
                    className: `wrap-event-${tipo === 'fijo' ? 'oficina' : 'especifico'}`,
                    backgroundColor: `${tipo === 'fijo' ? 'rgb(33, 196, 93)' : '#d33'} `,
                    borderColor: `${tipo === 'fijo' ? 'rgb(33, 196, 93)' : '#d33'} `,
                    textColor: '#ffffff'
                }
            ]);
        }
    };

    //Evento Cuando se arrastra un evento a otra posición
    const handleEventDrop = (info) => {
        console.log("Evento arrastrado", info.event);
        console.log("Evento arrastrado start y end", info.event.start, info.event.end);

        // Actualizamos el evento con la nueva posición
        setEvents(prevEvents => {
            return prevEvents.map(event => {
                if (event.id === info.event.id && event.tipo === 'fijo') {
                    return {
                        ...event,
                        start: info.event.start,
                        end: info.event.end,
                        startTime: format(info.event.start, 'HH:mm'),
                        endTime: format(info.event.end, 'HH:mm'),
                        daysOfWeek: [info.event.start.getDay()]
                    };
                } else if (event.id === info.event.id && event.tipo === 'una vez') {
                    return {
                        ...event,
                        start: info.event.start,
                        end: info.event.end,
                    };
                }
                return event;
            });
        });
    };


    //Evento Cuando se redimensiona un horario
    const handleEventResize = (info) => {
        console.log("Evento redimensionado", info.event);
        setEvents(prevEvents => {
            return prevEvents.map(event => {
                if (event.id === info.event.id && event.tipo === 'fijo') {
                    return {
                        ...event,
                        start: info.event.start,
                        end: info.event.end,
                        startTime: format(info.event.start, 'HH:mm'),
                        endTime: format(info.event.end, 'HH:mm'),
                        daysOfWeek: [info.event.start.getDay()]
                    };
                } else if (event.id === info.event.id && event.tipo === 'una vez') {
                    return {
                        ...event,
                        start: info.event.start,
                        end: info.event.end,
                    };
                }
                return event;
            });
        });
    };
    /* **** FIN MANEJO DE EVENTOS, (PONER, ARRASTRAR Y REDIMENSIONAR ) **** */

    //Esto es para que se vea el evento arrastrado (estilos)
    const handleEventDragStart = (info) => {
        console.log("Evento arrastrado start", info.event.extendedProps.tipo);
        info.el.classList.add(`fc-event-dragging-${info.event.extendedProps.tipo === 'fijo' ? 'oficina' : 'especifico'}`);
    };

    //Esto es para que se quite el evento arrastrado (estilos)
    const handleEventDragStop = (info) => {
        info.el.classList.remove(`fc-event-dragging-${info.event.extendedProps.tipo === 'fijo' ? 'oficina' : 'especifico'}`);
    };

    //Esto es para que se muestren los eventos en el calendario en la vista de semana y día, no en el del mes
    const handleViewDidMount = (view) => {
        setShowExternalEvents(view.view.type === 'timeGridWeek' || view.view.type === 'timeGridDay');
    };

    const onHandleGuardarCambios = () => {
        const token = localStorage.getItem('token');
        const doctorId = localStorage.getItem('doctorId');
        alertaCargandoProceso({
            titulo: 'Guardando cambios',
            messageHtml: 'Espere un momento por favor',
            funcionAsync: () => {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/createCalendar/${doctorId}`, { calendarioOficina: horarioOficina, calendarioEspecifico: horarioEspecifico }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((res) => {
                    console.log(res);
                    alertaGeneral({
                        titulo: 'Calendario guardado',
                        messageHtml: 'Se han guardado los cambios correctamente',
                    });
                    setRefresh(!refresh);
                }).catch((error) => {
                    alertaGeneral({
                        titulo: 'Error',
                        messageHtml: error.response?.data?.message || 'No se pudieron guardar los cambios',
                        isError: true
                    })
                });
            },
        })

    }

    if(!isAuthorized){
        return null;
    }

    return (
        <main className="disponibility flex-grow flex items-center justify-center p-2 min-h-screen">
            <div className="w-full max-w-5xl bg-white shadow-md rounded-lg p-3">
                <h1 className="text-lg font-bold text-black text-center uppercase">Calendario disponibilidad</h1>
                <div className="flex flex-col md:flex-row justify-center p-4"> {/* Adaptación para pantallas pequeñas */}
                    {showExternalEvents && (
                        <div className="w-full md:w-1/4 md:mr-10 mb-4 md:mb-0"> {/* Adaptación de ancho y espaciado en móvil */}
                            <div id="external-events" className="p-4 border border-gray-400 rounded bg-gray-100">
                                <p className="mb-1">Arrastra tu disponibilidad al calendario</p>
                                <span className="texto-informativo text-sm m-0 text-gray-500">El formato es de 24 horas</span>
                                <div className="fc-event px-2 py-1 mt-2 mb-2 bg-green-500 text-white text-center rounded">Horario</div>
                                <button className="bg-blue-600 px-2 py-1 text-white text-center rounded w-full" onClick={onHandleGuardarCambios}>Guardar Horarios</button>
                            </div>
                        </div>
                    )}
                    <div className="w-full md:w-3/4">
                        <FullCalendar
                            ref={bigCalendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="timeGridWeek"
                            locale={esLocale}
                            dateClick={handleDateClick}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            allDaySlot={false} // Ocultar la fila de todo el día
                            editable={true}
                            droppable={true}
                            events={events}
                            eventResizableFromStart={true}
                            eventReceive={handleEventReceive}
                            eventClick={handleEventClick}
                            eventDrop={handleEventDrop}
                            eventResize={handleEventResize}
                            eventDragStart={handleEventDragStart}
                            eventDragStop={handleEventDragStop}
                            eventOverlap={false} // Evitar superposición de eventos
                            viewDidMount={handleViewDidMount}
                            slotDuration={'00:20:00'} // Intervalos de 20 minutos
                            snapDuration={'00:20:00'} // Snap a intervalos de 20 minutos
                            views={{
                                dayGridMonth: {
                                    eventStartEditable: false,
                                    eventDurationEditable: false,
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </main>
    );

    function mensajeHtmlParaAlerta({ dia, tipoHorario, horaInicio, horaFin }) {
        return `
            <b> Dia: </b> ${dia} <br>
            <b> Tipo Horario: </b> ${tipoHorario} <br>
            <b> Hora Inicio: </b> ${horaInicio} <br>
            <b> Hora Fin: </b> ${horaFin} <br>
        `;
    }

    function validateHorario(tipo) {
        let horarios = {};
        events.filter(event => event.tipo === tipo).forEach(horario => {
            if (tipo === 'fijo') {
                const diaSemana = diasSemana[horario.daysOfWeek ? horario.daysOfWeek[0] : new Date(horario.start).getDay()];
                const intervalo = {
                    inicio: horario.startTime,
                    fin: horario.endTime
                };

                if (horarios[diaSemana]) {
                    horarios[diaSemana].intervalos.push(intervalo);
                } else {
                    horarios[diaSemana] = {
                        dia: diaSemana,
                        intervalos: [intervalo]
                    };
                }
            } else if (tipo === 'una vez') {
                const dia = new Date(horario.start).toISOString().split('T')[0]; // Obtén la fecha en formato "YYYY-MM-DD"
                const intervalo = { inicio: format(new Date(horario.start), 'HH:mm'), fin: format(new Date(horario.end), 'HH:mm') };

                if (horarios[dia]) {
                    horarios[dia].intervalos.push(intervalo);
                } else {
                    horarios[dia] = {
                        dia: dia,
                        intervalos: [intervalo]
                    };
                }
            }
        });
        return Object.values(horarios);
    }

};
export default CalendarDisponibility;