import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { alertaGeneral } from "../../utils/alertas";
import { useDataUser } from "../../context/dataUserContext";
import Swal from "sweetalert2";

const PagRespuestaCursoPayU = () => {
    const [searchParams] = useSearchParams();
    const [transactionState] = searchParams.get('transactionState');
    const referenceCode = searchParams.get('reference_pol');
    const [isApproved, setIsApproved] = useState(false);
    const { token } = useDataUser();
    const { cursoId } = useParams();
    const navigate = useNavigate();

    console.log('transactionState', transactionState);
    console.log('referenceCode', referenceCode);

    useEffect(() => {
        if (transactionState == 4 && !isApproved) {
            alertaGeneral({
                titulo: 'Transacción aprobada',
                messageHtml: 'Su inscripción al curso ha sido exitosa',
            });
            onApproved();
            setIsApproved(true);
        } else if (transactionState != 4) {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: 'La transacción no fue aprobada',
                isError: true
            });
        }
    }, [transactionState, isApproved]);

    const onApproved = () => {
        console.log('Transacción aprobada');
        const doctorId = localStorage.getItem('doctorId');
        const dataToSend = {
            doctorId,
            referenceCode
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cursos/registrarPagoCurso/${cursoId}`, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(res => {
                navigate("/doctor");
            })
            .catch(error => {
                alertaGeneral({
                    titulo: 'Error',
                    messageHtml: error.response?.data?.message || 'Ocurrió un error al registrar el pago del curso',
                    isError: true
                });
                navigate("/doctor");
            });
    };
}

export default PagRespuestaCursoPayU;