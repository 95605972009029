import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AgendarCita from "../PatientComponent/AgendarCita";
import { alertaGeneral } from "../../utils/alertas";
import axios from "axios";
import Swal from "sweetalert2";

const PagRespuestaAgendarCitaPayU = () => {
    const preInfo = JSON.parse(localStorage.getItem('selectedDate')) || {};
    const [searchParams] = useSearchParams();
    const [transactionState] = searchParams.get('transactionState');
    const [isApproved, setIsApproved] = useState(false);
    const navigate = useNavigate();

    const [dataCita, setDataCita] = useState({
        doctorId: preInfo.doctorId || '',
        doctor: preInfo.doctor || '',
        servicio: preInfo.servicio || '',
        precioServicio: preInfo.precioServicio || '',
        fecha: preInfo.fecha || '',
        tipoPagador: preInfo.tipoPagador || '',
        tipoConvenio: preInfo.tipoConvenio || 'sin convenio',
        motivoConsulta: preInfo.motivoConsulta || '',
    });

    useEffect(() => {
        return () => {
            localStorage.removeItem("selectedDate");
        };
    }, []);

    useEffect(() => {
        if (transactionState == 4 && !isApproved) {
            Swal.fire({
                title: 'Transacción Aprobada',
                text: 'La transacción fue aprobada, su cita se agendará en breve',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then(() => {
                onApproved();
                setIsApproved(true);
            });
        } else if (transactionState != 4) {
            Swal.fire({
                title: 'Error',
                text: 'La transacción no fue aprobada',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    }, [transactionState, isApproved]);

    const onApproved = () => {
        console.log(dataCita);
        const token = localStorage.getItem('token');
        const patientId = localStorage.getItem('patientId');
        const fecha = new Date(dataCita.fecha);
        fecha.setMinutes(fecha.getMinutes() - fecha.getTimezoneOffset());
        const fechaFormateada = fecha.toISOString();

        try {
            axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/scheduleAppointment`,
                {
                    patientId: patientId,
                    date: fechaFormateada,
                    notes: dataCita.motivoConsulta,
                    doctorId: dataCita.doctorId, // Enviando el ID del doctor
                    service: dataCita.servicio.replace(/ /g, '_'), // Enviando el servicio seleccionado
                    payerType: dataCita.tipoPagador,
                    covenantType: dataCita.tipoConvenio,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            ).then((response) => {
                if (response.status === 200) {
                    alertaGeneral({ titulo: 'Cita Agendada', messageHtml: 'La cita ha sido agendada correctamente' });
                    navigate('/patient/control');

                }
            }).catch((error) => {
                alertaGeneral({ titulo: 'Error', messageHtml: 'Ocurrió un error al agendar la cita', isError: true });
            });

        } catch (error) {
            alertaGeneral({ titulo: 'Error', messageHtml: 'Ocurrió un error al agendar la cita', isError: true });
        }
    };

    if (transactionState != 4) {
        return <AgendarCita />;
    }
};

export default PagRespuestaAgendarCitaPayU;
