import React, { useState } from 'react';

const RevisionDocumentos = ({ id, setVistaSeleccionada }) => {
  const [motivos, setMotivos] = useState({});
  const documentos = [
    { id: 1, nombre: 'Documento de Identidad', estado: 'Pendiente' },
    { id: 2, nombre: 'Título Profesional', estado: 'Pendiente' },
    { id: 3, nombre: 'Certificado de Ética', estado: 'Pendiente' },
  ];

  const handleRechazar = (docId, motivo) => {
    if (motivo.trim() === '') {
      alert('Por favor ingresa un motivo para rechazar.');
      return;
    }
    alert(`Documento ${docId} rechazado por: ${motivo}`);
  };

  return (
    <div className="p-10 bg-white h-full w-full flex flex-col items-center">
      <h1 className="text-1xl font-semibold text-gray-800 mb-6">
        Revisión de Documentos - Profesional {id}
      </h1>
      <button
        onClick={() => setVistaSeleccionada(null)} // Volver a la lista principal
        className="mb-6 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition duration-300"
      >
        Volver
      </button>

      <div className="w-full max-w-6xl grid gap-6">
        {documentos.map((documento) => (
          <div key={documento.id} className="bg-gray-100 rounded-lg shadow p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
            <h3 className="text-ls font-semibold mb-4">{documento.nombre}</h3>
            <div className="flex gap-4 mt-2">
              <button
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-300"
                onClick={() => alert(`Documento ${documento.id} aprobado`)}
              >
                Aprobar
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                onClick={() => setMotivos({ ...motivos, [documento.id]: '' })}
              >
                Rechazar
              </button>
            </div>

            {/* Si está rechazado, mostrar un campo para ingresar el motivo */}
            {motivos[documento.id] !== undefined && (
              <div className="mt-4">
                <textarea
                  placeholder="Motivo del rechazo"
                  value={motivos[documento.id]}
                  onChange={(e) => setMotivos({ ...motivos, [documento.id]: e.target.value })}
                  className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-200 transition duration-300"
                />
                <button
                  onClick={() => handleRechazar(documento.id, motivos[documento.id])}
                  className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                >
                  Confirmar Rechazo
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevisionDocumentos;
