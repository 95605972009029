import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import CreateClinic from './CreateClinic';

const ClinicComponent = () => {
    const [showForm, setShowForm] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedClinic, setSelectedClinic] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [creatingClinic, setCreatingClinic] = useState(false);

    const [filesToEdit, setFilesToEdit] = useState([]); // Variable para manejar los archivos que se van a subir

    const fetchClinics = async () => {
        try {
            const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
            const token = localStorage.getItem('token'); // Obtén el token si es necesario para autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/getClinic`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Si usas autenticación basada en tokens
                },
            });

            setClinics(response.data);
        } catch (error) {
            console.error('Error fetching clinics:', error);
        }
    };
    useEffect(() => {
        fetchClinics();
    }, [])

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleCreateClinic = () => {
        setCreatingClinic(true);
    };

    const handleCancel = () => {
        setCreatingClinic(false); // Cancelar la creación y volver a la vista de listado
    };

    if (creatingClinic) {
        return <CreateClinic handleCancel={handleCancel} fetchClinics={fetchClinics} />;
    }


    const handleGoBack = () => {
        setShowForm(false);
        setShowCalendar(false); // Oculta el calendario al volver
        setShowChangePassword(false);
        setShowProfile(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };
    const filteredclinics = clinics.filter(doctor => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            doctor.providerName?.toLowerCase().includes(searchTermLower) ||
            doctor.email?.toLowerCase().includes(searchTermLower) ||
            doctor.legalNature?.toLowerCase().includes(searchTermLower) ||
            doctor.mainCity?.toLowerCase().includes(searchTermLower) ||
            doctor.identification?.toLowerCase().includes(searchTermLower)
        );
    });

    const toggleMenu = (doctor) => {
        setSelectedClinic(doctor);
        setMenuVisible((prevVisible) => !prevVisible);
    };

    const handleMenuOptionClick = (option) => {
        console.log(`Option selected: ${option}`);
        setMenuVisible(false);

        if (option === 'Eliminar Clinica') {
            handleDeleteClinic();
        }
        if (option === 'Perfil') {
            setShowProfile(true);
        }
    };

    const handleDeleteClinic = async () => {
        try {
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Una vez eliminado, no podrás recuperar esta información.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                const token = localStorage.getItem('token');

                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/admin/deleteClinic/${selectedClinic._id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    Swal.fire('Eliminado', 'La clinica ha sido eliminada exitosamente.', 'success');
                    setClinics(clinics.filter(doctor => doctor._id !== selectedClinic._id));
                } else {
                    Swal.fire('Error', 'No se pudo eliminar la clinica. Inténtalo de nuevo.', 'error');
                }
            }
        } catch (error) {
            console.error('Error deleting doctor:', error);
            Swal.fire('Error', 'Ocurrió un error al eliminar la clinica.', 'error');
        }
    };





    const handleEditDoctor = async () => {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            // console.log(filesToEdit);

            const dataDoctorToSend = new FormData();
            for (const key in selectedClinic) {
                dataDoctorToSend.append(key, selectedClinic[key]);
            }

            for (const key in filesToEdit) {
                dataDoctorToSend.append(key, filesToEdit[key]);
            }

            // dataDoctorToSend.forEach((value, key) => {
            //     console.log('Key:', key, 'Value:', value);
            // });

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/editDoctor/${selectedClinic._id}`,
                dataDoctorToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Doctor Editado', 'El Doctor ha sido editado exitosamente.', 'success');
                setShowProfile(false);
                fetchClinics();

            } else {
                Swal.fire('Error editando Doctor', 'El Doctor no pudo ser editado', 'error');
            }
        } catch (error) {
            console.error('Error updating patient information:', error);
            Swal.fire('Error editando Doctor', 'El Doctor no pudo ser editado', 'error');

        }
    };

    const lstDocumentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Profesional', url: selectedClinic?.urlProfessionalPhoto || '', isEdit: true },
        { nameToSave: 'cedulaPDF', name: 'Documento Identidad', url: selectedClinic?.urlIdDocument || '', isEdit: true },
        { nameToSave: 'diploma_acta_grado', name: 'Diploma Grado', url: selectedClinic?.urlUndergraduateDiploma || '', isEdit: true },
        { nameToSave: 'diploma_especializacion', name: 'Diploma Especialización', url: selectedClinic?.urlSpecializationDiploma || '', isEdit: true },
        { nameToSave: 'homologacion_estudios', name: 'Homologación Estudios', url: selectedClinic?.urlStudyHomologation || '', isEdit: true },
        { nameToSave: 'tarjeta_profesional', name: 'Tarjeta Profesional', url: selectedClinic?.urlProfessionalCard || '', isEdit: true },
        { nameToSave: 'firma_profesional', name: 'Firma Profesional', url: selectedClinic?.urlProfessionalSignature || '', isEdit: true },
        { nameToSave: 'rethus', name: 'ReTHUS', url: selectedClinic?.urlRethusDocument || '', isEdit: true },
        { nameToSave: 'poliza_responsabilidad_civil', name: 'Poliza Responsabilidad Civil', url: selectedClinic?.urlCivilLiabilityPolicyDocument || '', isEdit: true },
    ];

    if (showProfile) {
        return (
            <div className="flex flex-col min-h-screen">
                <div className="flex p-4 items-center justify-between bg-white shadow-md border-b border-gray-200">
                    <h1 className="text-2xl font-semibold text-gray-800">Editar Perfil del Doctor</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="max-w-full mt-6">
                    <div className="max-w-full">
                        <form>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* Nombre Completo */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Nombre Completo</label>
                                    <input
                                        type="text"
                                        value={selectedClinic.fullName}
                                        onChange={(e) => setSelectedClinic({ ...selectedClinic, fullName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Tipo de ID */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tipo de ID</label>
                                    <select
                                        value={selectedClinic.idType}
                                        onChange={(e) => setSelectedClinic({ ...selectedClinic, idType: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    >

                                        <option value="CC">CC</option>
                                        <option value="TI">TI</option>
                                        <option value="CE">CE</option>
                                        <option value="PS">PS</option>
                                        <option value="DE">DE</option>
                                    </select>
                                </div>

                                {/* Número de ID */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de ID</label>
                                    <input
                                        type="text"
                                        value={selectedClinic.idNumber}
                                        onChange={(e) => setSelectedClinic({ ...selectedClinic, idNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Correo Electrónico */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Correo Electrónico</label>
                                    <input
                                        type="email"
                                        value={selectedClinic.email}
                                        onChange={(e) => setSelectedClinic({ ...selectedClinic, email: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Número de Teléfono */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de Teléfono</label>
                                    <input
                                        type="tel"
                                        value={selectedClinic.cellNumber}
                                        onChange={(e) => setSelectedClinic({ ...selectedClinic, cellNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Servicio */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Servicio</label>
                                    <select
                                        value={selectedClinic.service}
                                        onChange={(e) => setSelectedClinic({ ...selectedClinic, service: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    >
                                        <option value={selectedClinic.service}>{selectedClinic.service}</option>
                                        <option value="328 medicina_general">Medicina General</option>
                                        <option value="325 medicina_familiar">Medicina Familiar</option>
                                    </select>
                                </div>

                            </div>

                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={handleEditDoctor}
                                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                                >
                                    Guardar Cambios
                                </button>
                            </div>
                        </form>
                        {/* Tabla Visualizacion Y Descargas De Los Documentos 
                        <TableDocuments
                            lstDocuments={lstDocumentsExists ? lstDocumentsExists : null}
                            filesToEdit={filesToEdit}
                            setFilesToEdit={setFilesToEdit}
                        />*/}
                    </div>
                </main>
            </div>
        );
    }



    return (
        <div className="p-6">
            {!showForm ? (
                <>
                    <h1 className="text-2xl mb-4 font-semibold">Clinicas</h1>
                    <hr className="my-4" />
                    <div className="flex flex-col justify-end md:flex-row items-center mb-4 space-y-4 md:space-y-0 md:space-x-4">
                        <input
                            type="text"
                            placeholder="Buscar Clinicas"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-64 px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleCreateClinic}
                            className="px-5 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
                        >
                            Crear Clinica
                        </button>
                    </div>
                    <hr className="my-4" />
                    <div className="relative max-h-80 overflow-y-auto"> {/* Contenedor con posición relativa */}
                        <table className="w-full border-collapse border border-gray-200">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Nombre</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Correo Electrónico</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Naturaleza</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Ciudad</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">No. Identificación</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="w-full border-collapse border border-gray-200">
                                {filteredclinics.map((doctor) => (
                                    <tr key={doctor.id}>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.providerName}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.email}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.legalNature}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.mainCity}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{doctor.identification}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center relative"> {/* Aquí añadimos `relative` */}
                                            <button
                                                onClick={() => toggleMenu(doctor)}
                                                className="px-3 py-1 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                                            >
                                                ...
                                            </button>
                                            {selectedClinic === doctor && menuVisible && (
                                                <div className="mt-1 bg-white shadow-md rounded-md p-2 absolute left-0 transform translate-x-0 z-10"> {/* Ajuste para centrar */}
                                                    {/*<button
                                    onClick={() => handleMenuOptionClick('Perfil')}
                                    className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                >
                                    Editar Clinica
                                </button>*/}

                                                    <button
                                                        onClick={() => handleMenuOptionClick('Eliminar Clinica')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Eliminar Clinica
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </>
            ) : (
                null
            )}

        </div>
    );
};



export default ClinicComponent;
