import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useCombobox } from 'downshift';

const OrdenInterconsultas = ({ appointmentId }) => {
  const [appointmentInfo, setAppointmentInfo] = useState(null);
  const [interconsulta, setInterconsulta] = useState({
    servicio: null,
    especialidad: null,
    motivoConsulta: '', // Agregado nuevo campo
  });
  const [cupsOptions, setCupsOptions] = useState([]); 
  const [searchQueryCups, setSearchQueryCups] = useState('');
  const [loading, setLoading] = useState(false);


  const fetchAppointmentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentInfo();
  }, [appointmentId]);

  useEffect(() => {
    if (appointmentInfo) {
      setInterconsulta({
        servicio: translateGender(appointmentInfo.service) || ''
      });
    }
  }, [appointmentInfo]);
  
  const handleChangeServicio = (selectedOption) => {
    setInterconsulta({
      ...interconsulta,
      servicio: selectedOption ? selectedOption.value : null, // Extrae solo el valor
    });
  };
  
  const normalizeAndSplit = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ");
  };

  const fetchCups = async (query) => {
    setLoading(true);

    try {
      const normalizedQueryWords = normalizeAndSplit(query); // Normaliza y divide el query
      const token = localStorage.getItem('token');

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCups`,
        {
          params: { query: normalizedQueryWords.join(" ") }, // Unir las palabras normalizadas para enviar al backend
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const options = response.data.map((cup) => ({
        value: cup.Codigo,
        label: cup.Nombre,
        descripcion: cup.Descripcion
      }));
      setCupsOptions(options);
    } catch (error) {
      console.error('Error al obtener la lista de CUPS:', error);
    } finally {
      setLoading(false);
    }
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: cupsOptions,
    onInputValueChange: ({ inputValue }) => {
      fetchCups(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setInterconsulta(prevState => ({
          ...prevState,
          especialidad: selectedItem.label, // Guarda la especialidad seleccionada
        }));
      }
    },
    itemToString: (item) => (item ? item.label : ''),
  });
  

  const handleSearchQueryChangeCups = async (inputValue) => {
    setSearchQueryCups(inputValue);
    if (inputValue.length > 2) {
      await fetchCups(inputValue);
    } else {
      setCupsOptions([]);
    }
  };

  const translateGender = (service) => {
    return service === '325 medicina_familiar' ? 'Medicina Familiar' : 'Medicina General';
  };
  
  const handleCupsChange = (selectedOption) => {
    setInterconsulta(prevState => ({
      ...prevState,
      especialidad: selectedOption ? selectedOption.label : '',
    }));
  };

  const handleChangeEspecialidad = (selectedOption) => {
    setInterconsulta({
      ...interconsulta,
      especialidad: selectedOption ? selectedOption.value : null, // Extrae solo el valor
    });
  };

  const handleMotivoConsultaChange = (e) => {
    setInterconsulta({
      ...interconsulta,
      motivoConsulta: e.target.value, // Actualiza el motivo de consulta
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!interconsulta.especialidad) {
      Swal.fire({
        title: 'Error',
        text: 'El campo Servicio al que se remite, es obligatorio.',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    if (!interconsulta.motivoConsulta) {
      Swal.fire({
        title: 'Error',
        text: 'El campo Motivo, es obligatorio.',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const clinicId = localStorage.getItem('clinicId');

      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generateInterconsultasPDF`,
        method: 'POST',
        data: {
          appointmentId,
          clinicId: clinicId,
          patientInfo: {
            patientId: appointmentInfo.patientId,
            firstName: appointmentInfo.firstName,
            secondName: appointmentInfo.secondName,
            firstLastName: appointmentInfo.firstLastName,
            secondLastName: appointmentInfo.secondLastName,
            documentNumber: appointmentInfo.documentNumber,
            dob: appointmentInfo.dob,
            idType: appointmentInfo.idType,
            gender: appointmentInfo.gender,
            cellNumber: appointmentInfo.cellNumber,
          },
          interconsulta: {
            servicio: interconsulta.servicio, // Asegúrate de enviar solo el valor
            especialidad: interconsulta.especialidad, // Asegúrate de enviar solo el valor
            motivoConsulta: interconsulta.motivoConsulta, // Agregado nuevo campo
          },
          doctorInfo: {
            doctorId: appointmentInfo.doctorId,
            fullName: appointmentInfo.fullName,
            service: appointmentInfo.service,
            idNumber: appointmentInfo.idNumber,
            cellNumber: appointmentInfo.cellNumber,
            notes: appointmentInfo.notes,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // Para manejar archivos binarios como PDFs
      });

      // Crear un enlace y descargar el archivo PDF
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `orden-interconsultas-${appointmentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      Swal.fire({
        title: '¡PDF Generado!',
        text: 'El archivo PDF ha sido generado y descargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      });
    } catch (error) {
      console.error('Error al generar el PDF de interconsultas:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al generar el PDF.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };

 
 

  return (
    <div className="h-[400px] overflow-y-auto p-4">
      <h2 className="text-center font-bold bg-blue-600 p-2 text-white  text-lg">Orden de Interconsultas</h2>
      <hr className="my-4" />

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Servicio que solicita la interconsulta:</label>
        <input
          type="text"
          value={translateGender(interconsulta.servicio)}
          readOnly
          placeholder="Selecciona el Servicio"
          className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
      </div>

      <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Servicio al que se remite</label>
              <div className="mt-1 block w-full">
                <input
                  {...getInputProps()}
                  placeholder="Buscar..."
                  className="border rounded p-2 w-full"
                />
                <ul {...getMenuProps()} className="border rounded max-h-48 overflow-y-auto">
                  {isOpen && (loading ? (
                    <li>Cargando...</li>
                  ) : (
                    cupsOptions.map((option, optionIndex) => (
                      <li
                        key={option.value}
                        {...getItemProps({ item: option, index: optionIndex })}
                        data-index={optionIndex} // Asegúrate de que esto sea el índice correcto
                        className={`p-2 cursor-pointer ${highlightedIndex === optionIndex ? 'bg-gray-200' : ''}`}
                      >
                        {option.label}
                      </li>
                    ))
                  ))}
                </ul>

              </div>
            </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Motivo:</label>
        <textarea
          value={interconsulta.motivoConsulta}
          onChange={handleMotivoConsultaChange}
          rows="4"
          required
          className="w-full border border-gray-300 rounded-md shadow-sm p-2"
          placeholder="Escribe el motivo de la consulta"
        />
      </div>

      <button
        type="submit"
        onClick={handleSubmit}
        className="w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white py-2 rounded-md hover:bg-blue-700"
      >
  Enviar      </button>
    </div>
  );
};

export default OrdenInterconsultas;
