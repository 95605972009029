import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment-timezone';
import TableDocuments from './TableDocuments';
import Select from 'react-select';

const AdminComponent = () => {
    const [showForm, setShowForm] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [appointmentNotes, setAppointmentNotes] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [appointmentDate, setAppointmentDate] = useState('');
    const [appointments, setAppointments] = useState([]); // Estado para almacenar las citas del administrador
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [filesToEdit, setFilesToEdit] = useState([]);


    const fetchAdmins = async () => {
        try {
            const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
            const token = localStorage.getItem('token'); // Obtén el token si es necesario para autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAdmins/${clinicId}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Si usas autenticación basada en tokens
                },
            });

            setAdmins(response.data);
        } catch (error) {
            console.error('Error fetching admins:', error);
        }
    };



    /* const fetchAppointments = async (adminId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAppointments/${adminId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const appointmentsData = response.data.map(appointment => {

                const utcDate = moment.utc(appointment.date);
                return {
                    title: 'Cita de control',
                    start: utcDate.toISOString(),
                    allDay: false,
                    backgroundColor: '#000aff',
                    borderColor: '#000aff',
                    extendedProps: {
                        notes: appointment.notes,
                        startTime: utcDate.format('HH:mm') // Formato de hora en UTC
                    }
                };
            });

            setAppointments(appointmentsData);
        } catch (error) {
            console.error('Error fetching appointments:', error);
            Swal.fire('Error', 'No se pudieron cargar las citas del administrador.', 'error');
        }
    }; */


    useEffect(() => {
        fetchAdmins();
    }, []);

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleGoBack = () => {
        setShowForm(false);
        //setShowCalendar(false); // Oculta el calendario al volver
        setShowChangePassword(false);
        setShowProfile(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };

    const filteredAdmins = admins.filter(admin => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            admin.firstName?.toLowerCase().includes(searchTermLower) ||
            admin.firstLastName?.toLowerCase().includes(searchTermLower) ||
            admin.email?.toLowerCase().includes(searchTermLower) ||
            admin.cellNumber?.toLowerCase().includes(searchTermLower) ||
            admin.idNumber?.toLowerCase().includes(searchTermLower)
        );
    });

    const toggleMenu = (admin) => {
        setSelectedAdmin(admin);
        setMenuVisible((prevVisible) => !prevVisible);
    };

    const handleMenuOptionClick = (option) => {
        console.log(`Option selected: ${option}`);
        setMenuVisible(false);

        if (option === 'Eliminar Administrador') {
            handleDeleteAdmin();
        }
        /* if (option === 'Calendario') {
            setShowCalendar(true);
            fetchAppointments(selectedAdmin._id); // Carga las citas del administrador seleccionado
        } */
        /* if (option === 'Agendar Cita') {
            setShowAppointmentModal(true); // Muestra el modal de agendar cita
        } */
        if (option === 'Cambiar Contraseña') {
            setShowChangePassword(true);
        }
        if (option === 'Perfil') {
            setShowProfile(true);
        }
    };

    const handleDeleteAdmin = async () => {
        try {
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Una vez eliminado, no podrás recuperar esta información.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                const token = localStorage.getItem('token');

                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/clinic/deleteAdmin/${selectedAdmin._id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    Swal.fire('Eliminado', 'El administrador ha sido eliminado exitosamente.', 'success');
                    setAdmins(admins.filter(admin => admin._id !== selectedAdmin._id));
                } else {
                    Swal.fire('Error', 'No se pudo eliminar el administrador. Inténtalo de nuevo.', 'error');
                }
            }
        } catch (error) {
            console.error('Error deleting admin:', error);
            Swal.fire('Error', 'Ocurrió un error al eliminar el administrador.', 'error');
        }
    };



    /* const handleScheduleAppointment = async () => {
        try {
            const token = localStorage.getItem('token'); // Obtener el token para autenticación
            const date = new Date(appointmentDate);

            // Obtener el offset en minutos de la zona horaria local respecto a UTC
            const timezoneOffset = date.getTimezoneOffset();

            // Ajustar la fecha para la zona horaria local
            date.setMinutes(date.getMinutes() - timezoneOffset);

            // Convertir la fecha a formato ISO 8601
            const formattedDate = date.toISOString(); // Esto incluirá la zona horaria

            // Ahora envía formattedDate al backend

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/scheduleAppointment`,
                {
                    adminId: selectedAdmin._id, // ID del administrador
                    date: formattedDate, // Fecha y hora de la cita
                    notes: appointmentNotes,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Cita Agendada', 'La cita ha sido agendada exitosamente.', 'success');
                setShowAppointmentModal(false);
                setAppointmentDate(''); // Limpia la fecha de la cita
                setAppointmentNotes('');
            } else {
                Swal.fire('Error', 'No se pudo agendar la cita. Inténtalo de nuevo.', 'error');
            }
        } catch (error) {
            console.error('Error scheduling appointment:', error);
            Swal.fire('Error', 'Ocurrió un error al agendar la cita.', 'error');
        }
    }; */

    /*
    if (showCalendar) {
        return (
            <div className="flex flex-col  min-h-screen">
                <div className="flex p-2 items-center justify-between">
                    <h1 className="text-lg font-bold text-black ">Calendario</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="flex-grow flex items-center justify-center p-2 -mt-2">
                    <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-3">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={appointments}
                            locales={[esLocale]}
                            locale="es"
                            timeZone="local"
                            dayCellClassNames={(date) =>
                                date.date.getDate() === new Date().getDate() &&
                                    date.date.getMonth() === new Date().getMonth()
                                    ? 'current-day'
                                    : ''
                            }
                            eventContent={(eventInfo) => {
                                const startTime = moment.utc(eventInfo.event.start).format('HH:mm');
                                return (
                                    <div style={{ backgroundColor: eventInfo.event.backgroundColor }}>
                                        <div className="font-semibold text-white text-xs">{eventInfo.event.title}</div>
                                        <div className=" text-white text-xs ">{startTime}</div>
                                    </div>
                                );
                            }}
                            eventClick={(info) => handleEventClick(info)}
                        />
                    </div>
                </main>
                {selectedEvent && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                        <div className="bg-white rounded-lg text-center shadow-md p-4 max-w-md w-full">
                            <h2 className="text-lg font-bold">{selectedEvent.title}</h2>
                            <p className="text-sm text-gray-800 font-semibold">Fecha: {moment(selectedEvent.start).format('YYYY-MM-DD')}</p>
                            <p className="text-sm text-gray-800 font-semibold">Hora: {moment.utc(selectedEvent.start).format('HH:mm')}</p>
                            <p className="text-sm mt-2">{selectedEvent.extendedProps.notes}</p>
                            <button
                                onClick={handleCloseModal}
                                className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                )}
                <style>
                    {`
                    .fc-event {
                        background-color: #000aff !important; 
                        border: 1px solid #000aff;
                    }
                    .fc-daygrid-day {
                        visibility: visible;
                    }
                    .current-day {
                        background-color: #e7e6e6 !important;
                        border: 2px solid #fbc02d;
                    }
                    `}
                </style>
            </div>
        );
    }*/
    const handleEditAdmin = async () => {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage

            const dataAdminToSend = new FormData();

            for(const key in selectedAdmin){
                dataAdminToSend.append(key, selectedAdmin[key]);
            }

            for(const key in filesToEdit){
                dataAdminToSend.append(key, filesToEdit[key]);
            }

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/editAdmin/${selectedAdmin._id}`,
                dataAdminToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Administrador Editado', 'El Administrador ha sido editado exitosamente.', 'success');
                setShowProfile(false);
                fetchAdmins();

            } else {
                Swal.fire('Error editando Administrador', 'El administrador no pudo ser editado', 'error');

            }
        } catch (error) {
            console.error('Error updating patient information:', error);
            Swal.fire('Error editando Administrador', 'El administrador no pudo ser editado', 'error');

        }
    };

    const lstDocumentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Del Perfil', url: selectedAdmin?.urlPictureProfile || '', isEdit: true },
        { nameToSave: 'cedulaPDF', name: 'Documento Identidad', url: selectedAdmin?.urlFileDocument || '', isEdit: true }
    ]

    if (showProfile) {
        return (
            <div className="flex flex-col min-h-screen">
                <div className="flex p-4 items-center justify-between bg-white shadow-md border-b border-gray-200">
                    <h1 className="text-2xl font-semibold text-gray-800">Editar Perfil</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="max-w-full mt-6">
                    <div className="max-w-full">
                        <form>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                                {/* Personal Information Fields */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Nombre</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.firstName}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, firstName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Segundo Nombre</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.secondName}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, secondName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Primer Apellido</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.firstLastName}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, firstLastName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Segundo Apellido</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.secondLastName}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, secondLastName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Correo Electrónico</label>
                                    <input
                                        type="email"
                                        value={selectedAdmin.email}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, email: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de Teléfono</label>
                                    <input
                                        type="tel"
                                        value={selectedAdmin.cellNumber}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, cellNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>


                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Calle</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.street}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, street: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número Exterior</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.exteriorNumber}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, exteriorNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número Interior</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.interiorNumber}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, interiorNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Barrio</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.neighborhood}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, neighborhood: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Identification Documents Fields */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tipo de Identificación</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.idType}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, idType: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de Identificación</label>
                                    <input
                                        type="text"
                                        value={selectedAdmin.idNumber}
                                        onChange={(e) => setSelectedAdmin({ ...selectedAdmin, idNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={handleEditAdmin}
                                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                                >
                                    Guardar Cambios
                                </button>
                            </div>
                        </form>
                        <TableDocuments 
                            lstDocuments={lstDocumentsExists ? lstDocumentsExists : null}
                            filesToEdit={filesToEdit}
                            setFilesToEdit={setFilesToEdit}
                        />
                    </div>
                </main>
            </div>
        );
    }
    const closeChangePasswordModal = () => {
        setShowChangePassword(false);
    };

    const handleChangePassword = async () => {
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/changePasswordPatient`, {
                username: selectedAdmin.email,
                newPassword: newPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    title: 'Contraseña cambiada con exito!.',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
                setNewPassword('');
                closeChangePasswordModal();
            } else {
                Swal.fire({
                    title: 'Contraseña actual incorrecta.',
                    icon: 'error',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            }

        } catch (error) {

        }
    };

    return (
        <div className="p-6">
            {!showForm ? (
                <>
                    <h1 className="text-2xl mb-4 font-semibold">Administradores</h1>
                    <hr className="my-4" />
                    <div className="flex flex-col justify-end md:flex-row items-center mb-4 space-y-4 md:space-y-0 md:space-x-4">
                        <input
                            type="text"
                            placeholder="Buscar administradores"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-64 px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleShowForm}
                            className="px-2 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600 "
                        >
                            Crear administrador
                        </button>
                    </div>
                    <hr className="my-4" />
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse border border-gray-200">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Nombre</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Correo Electrónico</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Número de Celular</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Tipo de ID</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">No. Identificación</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAdmins.map((admin) => (
                                    <tr key={admin.id}>
                                        <td className="border border-gray-300 px-4 py-2 text-center">
                                            {admin.firstName} {admin.firstLastName}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{admin.email}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{admin.cellNumber}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{admin.idType}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{admin.idNumber}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">
                                            <button
                                                onClick={() => toggleMenu(admin)}
                                                className="px-3 py-1 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                                            >
                                                ...
                                            </button>
                                            {selectedAdmin === admin && menuVisible && (
                                                <div className="mt-2 bg-white shadow-md rounded-md p-2 absolute">
                                                    {/* <button
                                                    onClick={() => handleMenuOptionClick('Calendario')}
                                                    className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                >
                                                    Calendario
                                                </button>
                                                <button
                                                    onClick={() => handleMenuOptionClick('Agendar Cita')}
                                                    className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                >
                                                    Agendar Cita
                                                </button> */}
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Perfil')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Editar Perfil
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Cambiar Contraseña')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Cambiar Contraseña
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Eliminar Administrador')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Eliminar Administrador
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <Admin onGoBack={handleGoBack} fetchAdmins={fetchAdmins} />

            )}



            {showChangePassword && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50" >
                    <div className="bg-white p-6 rounded-lg shadow-lg" >
                        <h2 className="text-lg font-bold mb-4">Cambiar contraseña para {selectedAdmin.firstName} {selectedAdmin.firstLastName}</h2>
                        <p className="mb-4 font-bold">Correo electrónico:</p>
                        <p className="mb-4 ">{selectedAdmin.email}</p>
                        <div className="mb-4">
                            <label className="block font-bold mb-2">Nueva contraseña:</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-between">
                            <button
                                onClick={() => setShowChangePassword(false)}
                                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow-md hover:bg-gray-400"
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                                onClick={handleChangePassword}
                            >
                                Cambiar contraseña
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de Agendar Cita */}
            {/* {showAppointmentModal && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50" >
                    <div className="bg-white p-6 rounded-lg shadow-lg" >
                        <h2 className="text-lg font-bold mb-4">Agendar Cita para {selectedAdmin.firstName} {selectedAdmin.firstLastName}</h2>

                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Fecha de la cita:
                            <input
                                type="datetime-local"
                                value={appointmentDate}
                                onChange={(e) => setAppointmentDate(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </label>

                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Notas:
                            <textarea
                                value={appointmentNotes}
                                onChange={(e) => setAppointmentNotes(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                rows="4"
                                placeholder="Añadir notas adicionales..."
                            />
                        </label>

                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setShowAppointmentModal(false)}
                                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow-md hover:bg-gray-400"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleScheduleAppointment}
                                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Agendar
                            </button>
                        </div>
                    </div>
                </div>
            )} */}

        </div>
    );
};



const Admin = ({ onGoBack, fetchAdmins }) => {
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        foto_perfil: '',
        dob: '',
        birthCountry: '',
        residenceCountry: '',
        municipio: '',
        city: '',
        street: '',
        exteriorNumber: '',
        interiorNumber: '',
        neighborhood: '',
        firstName: '',
        secondName: '',
        firstLastName: '',
        secondLastName: '',
        gender: '',
        cellNumber: '',
        email: '',
        idType: '',
        idNumber: '',
        cedulaPDF: '',
        /* hasMedicalInsurance: 'no',
        insuranceName: '',
        insuranceNumber: '',
        hasCarer: 'no',
        carerFirstName: '',
        carerSecondName: '',
        carerFirstLastName: '',
        carerSecondLastName: '',
        carerRelationship: '',
        carerIdType: '',
        carerIdNumber: '', */
    });

    useEffect(() => {
        // Fetch departments when modal opens
        const fetchDepartments = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();

    }, []);

    useEffect(() => {
        if (formData.department) {
            // Fetch municipalities when a department is selected
            const fetchCities = async () => {
                if (formData.department) {
                    try {
                        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.department}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setCities(response.data);
                    } catch (error) {
                        console.error('Error fetching cities:', error);
                    }
                }
            };

            fetchCities();
        } else {
            setCities([]);
        }
    }, [formData.department]);

    const handleDepartmentChange = (event) => {
        const selectedId = event.target.value;
        const selectedDepartment = departments.find(dept => dept._id === selectedId);

        setFormData({
            ...formData,
            department: selectedId, // ID del departamento
            departmentName: selectedDepartment ? selectedDepartment.departamento : '', // Nombre del departamento
        });
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        const clinicId = localStorage.getItem('clinicId');
        formDataToSend.append('clinicId', clinicId);
    
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/adminInfo`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (response.status >= 200 && response.status < 300) {
                Swal.fire({
                    title: '¡Administrador creado con éxito!',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom',
                    },
                }).then(() => onGoBack());
                fetchAdmins();
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error al crear el administrador';
            Swal.fire({
                title: errorMessage,
                icon: 'error',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom',
                },
            });
        }
    };
    
    const [municipios, setMunicipios] = useState([]);
    const [paises, setPaises] = useState([]);

    const fetchMunicipios = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getMunicipios`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });
            setMunicipios(response.data);
        } catch (error) {
            console.error('Error fetching municipios:', error);
        }
    };
    useEffect(() => {
        fetchMunicipios();
    }, []);
    const fetchPaises = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPaises`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });

            setPaises(response.data);
        } catch (error) {
            console.error('Error fetching países:', error);
        }
    };

    // Llama a fetchPaises cuando el componente se monte
    useEffect(() => {
        fetchPaises();
    }, []);

    const opcionesPaises = paises.map(pais => ({
        value: `${pais.Codigo} - ${pais.Nombre}`,
        label: pais.Nombre,
    }));
    const opcionesMunicipios = municipios.map(municipio => ({
        value: `${municipio.Codigo} - ${municipio.Nombre}`,
        label: municipio.Nombre,
    }));

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-2xl font-semibold">Agregar nuevo administrador</h2>
                <button
                    type="submit"
                    className="px-3 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onGoBack}
                >
                    Volver
                </button>
            </div>
            <br />
            <form className="space-y-6" onSubmit={handleSubmit} enctype="multipart/form-data" >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                    {/* Imagen */}
                    <div className="col-span-1">

                        <label className="block text-sm font-medium text-gray-700">Imagen</label>
                        <input
                            type="file"
                            name="foto_perfil"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept="image/*"
                        />

                    </div>

                    {/* Documento (Adjuntar) */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Documento Identidad </label>
                        <input
                            type="file"
                            name="cedulaPDF"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept="application/pdf"
                        />
                    </div>

                    {/* Fecha de Nacimiento */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Fecha de Nacimiento *</label>
                        <input
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        />
                    </div>

                    {/* País de Nacimiento */}
                    <div className="form-group">
                        <label className="block text-gray-700">País de Nacimiento *</label>
                        <Select
                            name="birthCountry"
                            value={opcionesPaises.find(option => option.value === formData.birthCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, birthCountry: selectedOption.value })
                            }
                            options={opcionesPaises}
                            placeholder="Seleccione un país"
                            className="w-full"
                        />
                    </div>

                    {/* País de Residencia */}
                    <div className="form-group">
                        <label className="block text-gray-700">País de Residencia *</label>
                        <Select
                            name="residenceCountry"
                            value={opcionesPaises.find(option => option.value === formData.residenceCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, residenceCountry: selectedOption.value })
                            }
                            options={opcionesPaises}
                            placeholder="Seleccione un país"
                            className="w-full"
                        />
                    </div>

                    {/* Estado */}
                    <div className="form-group">
                        <label className="block text-gray-700">Municipio / Ciudad *</label>
                        <Select
                            name="municipio"
                            value={opcionesMunicipios.find(option => option.value === formData.residenceCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, municipio: selectedOption.value })
                            }
                            options={opcionesMunicipios}
                            placeholder="Seleccione un municipio"
                            className="w-full"
                        />
                    </div>

                   {/* <div className="form-group">
                        <label className="block text-gray-700">Ciudad *</label>
                        <select
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Ciudad</option>
                            {cities.length > 0 ? (
                                cities.map((city, index) => (
                                    <option key={index} value={city}>
                                        {city}
                                    </option>
                                ))
                            ) : (
                                <option value="">No hay ciudades disponibles</option>
                            )}
                        </select>
                    </div>

                    
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Calle *</label>
                        <input
                            type="text"
                            name="street"
                            value={formData.street}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Calle"
                            required
                        />
                    </div>

                    
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número Exterior *</label>
                        <input
                            type="text"
                            name="exteriorNumber"
                            value={formData.exteriorNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número Exterior"
                            required
                        />
                    </div>

                    
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número Interior *</label>
                        <input
                            type="text"
                            name="interiorNumber"
                            value={formData.interiorNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número Interior"
                            required
                        />
                    </div>

                   
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Barrio *</label>
                        <input
                            type="text"
                            name="neighborhood" 
                            value={formData.neighborhood}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Barrio"
                            required
                        />
                    </div>*/}

                    {/* Primer Nombre */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Primer Nombre *</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Primer Nombre"
                            required
                        />
                    </div>

                    {/* Segundo Nombre */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Segundo Nombre</label>
                        <input
                            type="text"
                            name="secondName"
                            value={formData.secondName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Segundo Nombre"
                        />
                    </div>

                    {/* Primer Apellido */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Primer Apellido *</label>
                        <input
                            type="text"
                            name="firstLastName"
                            value={formData.firstLastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Primer Apellido"
                            required
                        />
                    </div>

                    {/* Segundo Apellido */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Segundo Apellido</label>
                        <input
                            type="text"
                            name="secondLastName"
                            value={formData.secondLastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Segundo Apellido"
                        />
                    </div>

                    {/* Género */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Género *</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="" disabled>Seleccione un género</option>
                            <option value="male">Masculino</option>
                            <option value="female">Femenino</option>
                            <option value="non-binary">No Binario</option>
                            <option value="other">Otro</option>
                            <option value="prefer-not-to-say">Prefiero no decirlo</option>
                        </select>
                    </div>

                    {/* Número de Celular */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular *</label>
                        <input
                            type="text"
                            name="cellNumber"
                            value={formData.cellNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"
                            required
                        />
                    </div>

                    {/* Correo Electrónico */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Correo Electrónico *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Correo Electrónico"
                            required
                        />
                    </div>

                    {/* Tipo de ID */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de ID *</label>
                        <select
                            name="idType"
                            value={formData.idType}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione</option>
                            <option value="CC">CC</option>
                            <option value="TI">TI</option>
                            <option value="CE">CE</option>
                            <option value="PS">PS</option>
                            <option value="DE">DE</option>
                        </select>
                    </div>

                    {/* Número de ID */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de ID *</label>
                        <input
                            type="text"
                            name="idNumber"
                            value={formData.idNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de ID"
                            required
                        />
                    </div>

                </div>

                {/* <hr className="my-6 border-gray-300" /> */}

                {/* Seguro Médico */}
                {/* <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700">¿Tiene Seguro Médico?</label>
                    <select
                        name="hasMedicalInsurance"
                        value={formData.hasMedicalInsurance}
                        onChange={handleSelectChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="no">No</option>
                        <option value="yes">Sí</option>
                    </select>
                </div> */}

                {/* {formData.hasMedicalInsurance === 'yes' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Nombre del Seguro</label>
                            <input
                                type="text"
                                name="insuranceName"
                                value={formData.insuranceName}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Nombre del Seguro"
                            />
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Número del Seguro</label>
                            <input
                                type="text"
                                name="insuranceNumber"
                                value={formData.insuranceNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Número del Seguro"
                            />
                        </div>
                    </div>
                )} */}

                {/* <hr className="my-6 border-gray-300" /> */}

                {/* Cuidador o Representante */}
                {/* <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700">¿Tiene Cuidador o Representante?</label>
                    <select
                        name="hasCarer"
                        value={formData.hasCarer}
                        onChange={handleSelectChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="no">No</option>
                        <option value="yes">Sí</option>
                    </select>
                </div> */}

                {/* {formData.hasCarer === 'yes' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Primer Nombre del Cuidador</label>
                            <input
                                type="text"
                                name="carerFirstName"
                                value={formData.carerFirstName}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Primer Nombre del Cuidador"
                            />
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Segundo Nombre del Cuidador</label>
                            <input
                                type="text"
                                name="carerSecondName"
                                value={formData.carerSecondName}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Segundo Nombre del Cuidador"
                            />
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Primer Apellido del Cuidador</label>
                            <input
                                type="text"
                                name="carerFirstLastName"
                                value={formData.carerFirstLastName}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Primer Apellido del Cuidador"
                            />
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Segundo Apellido del Cuidador</label>
                            <input
                                type="text"
                                name="carerSecondLastName"
                                value={formData.carerSecondLastName}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Segundo Apellido del Cuidador"
                            />
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Parentesco</label>
                            <input
                                type="text"
                                name="carerRelationship"
                                value={formData.carerRelationship}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Parentesco"
                            />
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Tipo de ID del Cuidador</label>
                            <select
                                name="carerIdType"
                                value={formData.carerIdType}
                                onChange={handleSelectChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="">Seleccione</option>
                                <option value="cc">CC</option>
                                <option value="ti">TI</option>
                                <option value="ce">CE</option>
                                <option value="ps">PS</option>
                                <option value="de">DE</option>
                            </select>
                        </div>

                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Número de ID del Cuidador</label>
                            <input
                                type="text"
                                name="carerIdNumber"
                                value={formData.carerIdNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                placeholder="Número de ID del Cuidador"
                            />
                        </div>
                    </div>
                )} */}

                <div className="flex justify-end mt-6">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AdminComponent;
