import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';

import Modal from 'react-modal';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment-timezone';
import TableDocuments from './TableDocuments';

const PatientComponent = () => {
    const [showForm, setShowForm] = useState(false);
    const [patients, setPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [appointmentNotes, setAppointmentNotes] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedService, setSelectedService] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [appointmentDate, setAppointmentDate] = useState('');
    const [paises, setPaises] = useState([]);

    const [appointments, setAppointments] = useState([]);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [filesToEdit, setFilesToEdit] = useState([]);

    const fetchPatients = async () => {
        try {
            const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
            const token = localStorage.getItem('token'); // Obtén el token si es necesario para autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPatients/${clinicId}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Si usas autenticación basada en tokens
                },
            });

            setPatients(response.data);
        } catch (error) {
            console.error('Error fetching patients:', error);
        }
    };

    const fetchPaises = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPaises`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });

            setPaises(response.data);
        } catch (error) {
            console.error('Error fetching países:', error);
        }
    };
    useEffect(() => {
        fetchPaises();
    }, []);

    const fetchDoctorsByService = async (service) => {
        try {
            const clinicId = localStorage.getItem('clinicId');
            const token = localStorage.getItem('token');

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/getDoctorsByService/${clinicId}?service=${service}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setDoctors(response.data);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        }
    };



    const handleCancel = () => {
        setShowAppointmentModal(false)
        setSelectedService('');
        setSelectedDoctor(null);
        setDoctors([]);
    };

    const fetchAppointments = async (patientId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAppointments/${patientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const appointmentsData = response.data.map(appointment => {
                const utcDate = moment.utc(appointment.date);

                // Incluimos el nombre del doctor
                return {
                    title: 'Cita de control',
                    start: utcDate.toISOString(),
                    allDay: false,
                    backgroundColor: '#000aff',
                    borderColor: '#000aff',
                    extendedProps: {
                        notes: appointment.notes,
                        startTime: utcDate.format('HH:mm'),
                        doctorName: appointment.doctor.fullName
                    }
                };
            });

            setAppointments(appointmentsData);
        } catch (error) {
            console.error('Error fetching appointments:', error);
            Swal.fire('Error', 'No se pudieron cargar las citas del paciente.', 'error');
        }
    };



    useEffect(() => {
        fetchPatients();
    }, []);

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleGoBack = () => {
        setShowForm(false);
        setShowCalendar(false); // Oculta el calendario al volver
        setShowChangePassword(false);
        setShowProfile(false);

    };

    const opcionesPaises = paises.map(pais => ({
        value: `${pais.Codigo} - ${pais.Nombre}`,
        label: pais.Nombre,
    }));

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };
    const filteredPatients = patients.filter(patient => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            patient.firstName?.toLowerCase().includes(searchTermLower) ||
            patient.firstLastName?.toLowerCase().includes(searchTermLower) ||
            patient.email?.toLowerCase().includes(searchTermLower) ||
            patient.cellNumber?.toLowerCase().includes(searchTermLower) ||
            patient.idNumber?.toLowerCase().includes(searchTermLower)
        );
    });

    const toggleMenu = (patient) => {
        setSelectedPatient(patient);
        // setFilesToEdit([]);
        setMenuVisible((prevVisible) => !prevVisible);
    };

    const handleMenuOptionClick = (option) => {
        console.log(`Option selected: ${option}`);
        setMenuVisible(false);

        if (option === 'Eliminar Paciente') {
            handleDeletePatient();
        }
        if (option === 'Calendario') {
            setShowCalendar(true);
            fetchAppointments(selectedPatient._id);
        }
        if (option === 'Agendar Cita') {
            setShowAppointmentModal(true);
        }
        if (option === 'Cambiar Contraseña') {
            setShowChangePassword(true);
        }
        if (option === 'Perfil') {
            setShowProfile(true);
        }
    };

    const handleDeletePatient = async () => {
        try {
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Una vez eliminado, no podrás recuperar esta información.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                const token = localStorage.getItem('token');

                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/clinic/deletePatient/${selectedPatient._id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    Swal.fire('Eliminado', 'El paciente ha sido eliminado exitosamente.', 'success');
                    setPatients(patients.filter(patient => patient._id !== selectedPatient._id));
                } else {
                    Swal.fire('Error', 'No se pudo eliminar el paciente. Inténtalo de nuevo.', 'error');
                }
            }
        } catch (error) {
            console.error('Error deleting patient:', error);
            Swal.fire('Error', 'Ocurrió un error al eliminar el paciente.', 'error');
        }
    };

    const handleScheduleAppointment = async () => {
        try {
            const token = localStorage.getItem('token');
            const date = new Date(appointmentDate);
            const timezoneOffset = date.getTimezoneOffset();
            date.setMinutes(date.getMinutes() - timezoneOffset);
            const formattedDate = date.toISOString();

            // Verifica si el doctor está asignado
            console.log("Selected Doctor:", selectedDoctor);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/scheduleAppointment`,
                {
                    patientId: selectedPatient._id,
                    date: formattedDate,
                    notes: appointmentNotes,
                    doctorId: selectedDoctor.value,
                    service: selectedService,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Cita Agendada', 'La cita ha sido agendada exitosamente.', 'success');
                setShowAppointmentModal(false);
                setAppointmentDate('');
                setAppointmentNotes('');
                setDoctors([]);
                setSelectedDoctor(null);
                setSelectedService(null);
            } else {
                Swal.fire('Error', 'No se pudo agendar la cita. Inténtalo de nuevo.', 'error');
            }
        } catch (error) {
            console.error('Error scheduling appointment:', error);
            Swal.fire('Error', 'Ocurrió un error al agendar la cita.', 'error');
        }
    };

    const doctorOptions = doctors.map(doctor => ({
        value: doctor._id,
        label: `${doctor.fullName} | Documento | ${doctor.idNumber}`
    }));

    const handleEditPatient = async () => {
        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage

            const dataPatientToSend = new FormData();
            for (const key in selectedPatient) {
                dataPatientToSend.append(key, selectedPatient[key]);
            }
            for (const key in filesToEdit) {
                dataPatientToSend.append(key, filesToEdit[key]);
            }

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/editPatient/${selectedPatient._id}`,
                dataPatientToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                Swal.fire('Paciente Editado', 'El paciente ha sido editado exitosamente.', 'success');
                setShowProfile(false);
                fetchPatients();

            } else {
                Swal.fire('Error editando Paciente', 'El Paciente no pudo ser editado', 'error');

            }
        } catch (error) {
            console.error('Error updating patient information:', error);
            Swal.fire('Error editando Paciente', 'El Paciente no pudo ser editado', 'error');

        }
    };

    const lstDocumentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Del Usuario', url: selectedPatient?.urlPictureProfile || '', isEdit: true },
        { nameToSave: 'cedulaPDF', name: 'Documento de Identidad', url: selectedPatient?.urlFileDocument || '', isEdit: true },
    ]


    if (showProfile) {
        return (
            <div className="flex flex-col min-h-screen">
                <div className="flex p-4 items-center justify-between bg-white shadow-md border-b border-gray-200">
                    <h1 className="text-2xl font-semibold text-gray-800">Editar Perfil</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="max-w-full mt-6">
                    <div className="max-w-full">
                        <form>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                                {/* Personal Information Fields */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Nombre</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.firstName}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, firstName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Segundo Nombre</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.secondName}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, secondName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Primer Apellido</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.firstLastName}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, firstLastName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Segundo Apellido</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.secondLastName}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, secondLastName: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Correo Electrónico</label>
                                    <input
                                        type="email"
                                        value={selectedPatient.email}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, email: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de Teléfono</label>
                                    <input
                                        type="tel"
                                        value={selectedPatient.cellNumber}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, cellNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>


                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Calle</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.street}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, street: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número Exterior</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.exteriorNumber}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, exteriorNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número Interior</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.interiorNumber}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, interiorNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Barrio</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.neighborhood}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, neighborhood: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>

                                {/* Identification Documents Fields */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tipo de Identificación</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.idType}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, idType: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Número de Identificación</label>
                                    <input
                                        type="text"
                                        value={selectedPatient.idNumber}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, idNumber: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>


                                {/* Insurance and Caregiver Information Fields */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">¿Tiene Seguro Médico?</label>
                                    <select
                                        value={selectedPatient.hasMedicalInsurance}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, hasMedicalInsurance: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    >
                                        <option value="No">No</option>
                                        <option value="Si">Sí</option>

                                    </select>
                                </div>
                                {selectedPatient.hasMedicalInsurance === 'Si' && (
                                    <>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Nombre del Seguro</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.insuranceName}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, insuranceName: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Número de Seguro</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.insuranceNumber}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, insuranceNumber: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                    </>
                                )}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">¿Tiene Cuidador?</label>
                                    <select
                                        value={selectedPatient.hasCarer}
                                        onChange={(e) => setSelectedPatient({ ...selectedPatient, hasCarer: e.target.value })}
                                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                    >
                                        <option value="No">No</option>
                                        <option value="Si">Sí</option>
                                    </select>

                                </div>
                                {selectedPatient.hasCarer === 'Si' && (
                                    <>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Nombre del Cuidador</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerFirstName}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerFirstName: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Segundo Nombre del Cuidador</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerSecondName}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerSecondName: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Apellido Paterno del Cuidador</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerFirstLastName}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerFirstLastName: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Apellido Materno del Cuidador</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerSecondLastName}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerSecondLastName: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Relación con el Paciente</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerRelationship}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerRelationship: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Tipo de Identificación del Cuidador</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerIdType}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerIdType: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Número de Identificación del Cuidador</label>
                                            <input
                                                type="text"
                                                value={selectedPatient.carerIdNumber}
                                                onChange={(e) => setSelectedPatient({ ...selectedPatient, carerIdNumber: e.target.value })}
                                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={handleEditPatient}
                                    className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                                >
                                    Guardar Cambios
                                </button>
                            </div>
                        </form>
                        <TableDocuments
                            lstDocuments={lstDocumentsExists ? lstDocumentsExists : null}
                            filesToEdit={filesToEdit}
                            setFilesToEdit={setFilesToEdit}
                        />
                    </div>
                </main>
            </div>
        );
    }


    if (showCalendar) {
        return (
            <div className="flex flex-col  min-h-screen">
                <div className="flex p-2 items-center justify-between">
                    <h1 className="text-lg font-bold text-black">Calendario</h1>
                    <button
                        onClick={handleGoBack}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                    >
                        Volver
                    </button>
                </div>
                <main className="flex-grow flex items-center justify-center p-2 -mt-2">
                    <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-3">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={appointments} // Asegúrate de que appointments tenga el campo doctorName
                            locales={[esLocale]}
                            locale="es"
                            timeZone="local"
                            dayCellClassNames={(date) =>
                                date.date.getDate() === new Date().getDate() &&
                                    date.date.getMonth() === new Date().getMonth()
                                    ? 'current-day'
                                    : ''
                            }
                            eventContent={(eventInfo) => {
                                const startTime = moment.utc(eventInfo.event.start).format('HH:mm');

                                return (
                                    <div style={{ backgroundColor: eventInfo.event.backgroundColor }}>
                                        <div className="font-semibold text-white text-xs">{eventInfo.event.title}</div>
                                        <div className=" text-white text-xs ">{startTime}</div>

                                    </div>
                                );
                            }}
                            eventClick={(info) => handleEventClick(info)}
                        />
                    </div>
                </main>
                {selectedEvent && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                        <div className="bg-white rounded-lg text-center shadow-md p-4 max-w-md w-full">
                            <h2 className="text-lg font-bold">{selectedEvent.title}</h2>
                            <p className="text-sm text-gray-800 font-semibold">
                                Fecha: {moment(selectedEvent.start).format('YYYY-MM-DD')}
                            </p>
                            <p className="text-sm text-gray-800 font-semibold">
                                Hora: {moment.utc(selectedEvent.start).format('HH:mm')}
                            </p>
                            <p className="text-sm text-gray-800 font-semibold">
                                Doctor: {selectedEvent.extendedProps.doctorName}
                            </p>
                            <p className="text-sm mt-2">{selectedEvent.extendedProps.notes}</p>
                            <button
                                onClick={handleCloseModal}
                                className="mt-4 px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                )}
                <style>
                    {`
                    .fc-event {
                        background-color: #000aff !important; /* Asegúrate de que el color de fondo se aplique */
                        border: 1px solid #000aff;
                    }
                    .fc-daygrid-day {
                        visibility: visible;
                    }
                    .current-day {
                        background-color: #e7e6e6 !important;
                        border: 2px solid #fbc02d;
                    }
                    `}
                </style>
            </div>
        );
    }

    const closeChangePasswordModal = () => {
        setShowChangePassword(false);
    };

    const handleChangePassword = async () => {
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/changePasswordPatient`, {
                username: selectedPatient.email,
                newPassword: newPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    title: 'Contraseña cambiada con exito!.',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
                setNewPassword('');
                closeChangePasswordModal();
            } else {
                Swal.fire({
                    title: 'Contraseña actual incorrecta.',
                    icon: 'error',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            }

        } catch (error) {

        }
    };
    return (
        <div className="p-6">
            {!showForm ? (
                <>
                    <h1 className="text-2xl mb-4 font-semibold ">Pacientes</h1>
                    <hr className="my-4" />
                    <div className="flex flex-col justify-end md:flex-row items-center mb-4 space-y-4 md:space-y-0 md:space-x-4">
                        <input
                            type="text"
                            placeholder="Buscar pacientes"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full md:w-64 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            onClick={handleShowForm}
                            className="w-full md:w-auto px-5 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
                        >
                            Crear Paciente
                        </button>
                    </div>

                    <hr className="my-4" />
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse border border-gray-200">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Nombre</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Correo Electrónico</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Número de Celular</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Tipo de ID</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">No. Identificación</th>
                                    <th className="border border-gray-300 px-4 py-2 font-semibold">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPatients.map((patient) => (
                                    <tr key={patient._id}>
                                        <td className="border border-gray-300 px-4 py-2 text-center">
                                            {patient.firstName} {patient.firstLastName}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{patient.email}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{patient.cellNumber}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{patient.idType}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">{patient.idNumber}</td>
                                        <td className="border border-gray-300 px-4 py-2 text-center">
                                            <button
                                                onClick={() => toggleMenu(patient)}
                                                className="px-3 py-1 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                                            >
                                                ...
                                            </button>
                                            {selectedPatient === patient && menuVisible && (
                                                <div className="mt-2 bg-white shadow-md rounded-md p-2 absolute">
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Perfil')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Editar Perfil
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Calendario')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Calendario
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Agendar Cita')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Agendar Cita
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Cambiar Contraseña')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Cambiar Contraseña
                                                    </button>
                                                    <button
                                                        onClick={() => handleMenuOptionClick('Eliminar Paciente')}
                                                        className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
                                                    >
                                                        Eliminar Paciente
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </>
            ) : (
                <Patient onGoBack={handleGoBack} fetchPatients={fetchPatients} />

            )}



            {showChangePassword && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50" >
                    <div className="bg-white p-6 rounded-lg shadow-lg" >
                        <h2 className="text-lg font-bold mb-4">Cambiar Contraseña para {selectedPatient.firstName} {selectedPatient.firstLastName}</h2>

                        <p className="mb-4 font-bold">Correo electrónico:</p>
                        <p className="mb-4 ">{selectedPatient.email}</p>

                        <div className="mb-4">
                            <label className="block font-bold mb-2">Nueva Contraseña</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-between">
                            <button
                                onClick={() => setShowChangePassword(false)}
                                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow-md hover:bg-gray-400"
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                                onClick={handleChangePassword}
                            >
                                Cambiar Contraseña
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de Agendar Cita */}
            {showAppointmentModal && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">
                            Agendar Cita para {selectedPatient.firstName} {selectedPatient.firstLastName}
                        </h2>

                        {/* Service Selection */}
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Seleccione Servicio:
                            <select
                                value={selectedService}
                                onChange={(e) => {
                                    setSelectedService(e.target.value);
                                    fetchDoctorsByService(e.target.value); // Fetch doctors when service changes
                                }}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option value="">Seleccione un servicio</option>
                                <option value="328 medicina_general">Medicina General</option>
                                <option value="325 medicina_familiar">Medicina Familiar</option>
                            </select>
                        </label>

                        {/* Doctor Selection */}
                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Seleccione Doctor:
                            <Select
                                value={selectedDoctor}
                                onChange={(option) => setSelectedDoctor(option)}
                                options={doctorOptions}
                                isDisabled={!doctors.length}
                                placeholder="Seleccione un doctor"
                            />
                        </label>

                        {/* Appointment Date */}
                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Fecha de la cita:
                            <input
                                type="datetime-local"
                                value={appointmentDate}
                                onChange={(e) => setAppointmentDate(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </label>

                        {/* Appointment Notes */}
                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">
                            Motivo de la consulta:
                            <textarea
                                value={appointmentNotes}
                                onChange={(e) => setAppointmentNotes(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                rows="4"
                                placeholder="Añadir Motivo de la consulta..."
                            />
                        </label>

                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleCancel}
                                className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow-md hover:bg-gray-400"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleScheduleAppointment}
                                className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Agendar
                            </button>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};



const Patient = ({ onGoBack, fetchPatients }) => {
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedAseguradora, setSelectedAseguradora] = useState(null);
    const [selectedOcupacion, setSelectedOcupacion] = useState(null);
    const [addressData, setAddressData] = useState({
        via: "",
        numero: "",
        letra: "",
        bis: false,
        complemento: "",
        cardinalidad: "",
        otroNumero: "",
        otroLetra: "",
        otroComplemento: "",
        otroCardinalidad: "",
    });

    const [addressDisplay, setAddressDisplay] = useState("");

    const handleAddressChange = (e) => {
        setAddressData({
            ...addressData,
            [e.target.name]: e.target.value,
        });
    };


    const handleSubmitDireccion = (e) => {
        e.preventDefault();

        // Construye la dirección formateada
        const fullAddress = `${addressData.via} ${addressData.numero} ${addressData.letra || ""} ${addressData.bis ? "Bis" : ""} ${addressData.complemento || ""} ${addressData.cardinalidad || ""} ${addressData.otroNumero || ""} ${addressData.otroLetra || ""} ${addressData.otroComplemento || ""} ${addressData.otroCardinalidad || ""} `;

        setFormData((prevState) => ({
            ...prevState,
            address: fullAddress,
        }));

        // Actualiza el campo de dirección principal
        setAddressDisplay(fullAddress);

        // Cierra el modal
        setShowModal(false);
    };
    const [aseguradoraOptions, setAseguradoraOptions] = useState([]);
    const [ocupacionOptions, setOcupacionOptions] = useState([]);
    const [paises, setPaises] = useState([]);

    const [formData, setFormData] = useState({
        foto_perfil: '',
        dob: '',
        birthCountry: '',
        residenceCountry: '',
        municipio: '',
        city: '',
        neighborhood: '',
        firstName: '',
        secondName: '',
        firstLastName: '',
        estadoCivil: '',
        zonaUbicacion: '',
        secondLastName: '',
        grupoSanguineo: '',
        rh: '',
        address: '',
        grupoEtnico: '',
        gender: '',
        sex: '',
        condicionDesplazamiento: '',
        escolaridad: '',
        cellNumber: '',
        cellNumber1: '',
        email: '',
        idType: '',
        idNumber: '',
        tipoUsuario: '',
        cedulaPDF: '',
        hasMedicalInsurance: 'no',
        insuranceName: '',
        aseguradora: '',
        insuranceNumber: '',
        hasCarer: 'no',
        ocupacion: '',
        carerFirstName: '',
        carerSecondName: '',
        carerFirstLastName: '',
        carerSecondLastName: '',
        carerRelationship: '',
        carerIdType: '',
        carerIdNumber: '',
    });
    useEffect(() => {

        // Fetch departments when modal opens
        const fetchDepartments = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();

    }, []);

    const fetchPaises = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPaises`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });

            setPaises(response.data);
        } catch (error) {
            console.error('Error fetching países:', error);
        }
    };

    // Llama a fetchPaises cuando el componente se monte
    useEffect(() => {
        fetchPaises();
    }, []);

    const fetchAseguradoraOptions = async (query) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchAseguradora`,
                {
                    params: { query },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const options = response.data.map((med) => ({
                value: med.Os,         // Código CIE-10
                label: med.Nombre         // Nombre de la enfermedad

            }));

            setAseguradoraOptions(options);
        } catch (error) {
            console.error('Error al obtener las Aseguradoras:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchOcupacionOptions = async (query) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchOcupacion`,
                {
                    params: { query },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const options = response.data.map((med) => ({
                value: med.Indice,
                label: med.Detalle

            }));

            setOcupacionOptions(options);
        } catch (error) {
            console.error('Error al obtener las Ocupaciones:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (formData.department) {
            // Fetch municipalities when a department is selected
            const fetchCities = async () => {
                if (formData.department) {
                    try {
                        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.department}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setCities(response.data);
                    } catch (error) {
                        console.error('Error fetching cities:', error);
                    }
                }
            };

            fetchCities();
        } else {
            setCities([]);
        }
    }, [formData.department]);

    const handleDepartmentChange = (event) => {
        const selectedId = event.target.value;
        const selectedDepartment = departments.find(dept => dept._id === selectedId);

        setFormData({
            ...formData,
            department: selectedId, // ID del departamento
            departmentName: selectedDepartment ? selectedDepartment.departamento : '', // Nombre del departamento
        });
    };
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleAseguradoraChange = (selectedOption) => {
        setSelectedAseguradora(selectedOption); // Mantiene el valor seleccionado en su estado individual
        setFormData({
            ...formData,
            aseguradora: selectedOption ? selectedOption.label : '', // Actualiza insuranceName en formData
        });
    };
    const handleOcupacionChange = (selectedOption) => {
        setSelectedOcupacion(selectedOption); // Mantiene el valor seleccionado en su estado individual
        setFormData({
            ...formData,
            ocupacion: selectedOption ? selectedOption.label : '', // Actualiza insuranceName en formData
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        const clinicId = localStorage.getItem('clinicId'); // Obtén el clinicId del localStorage
        formDataToSend.append('clinicId', clinicId);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/patientInfo`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }

            });
            if (response.status >= 200 && response.status < 300) {
                Swal.fire({
                    title: '¡Paciente creado con éxito!',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                }).then(() => {
                    // Ejecutar onGoBack cuando el usuario haga clic en OK
                    onGoBack();
                });
                fetchPatients();
            } else {
                console.error('Error al guardar los datos:', response.statusText);
            }
        } catch (error) {
            Swal.fire({
                title: error.response?.data?.message || 'Error al crear el paciente',
                icon: 'error',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom'
                }
            });
        }
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [municipios, setMunicipios] = useState([]);

    const fetchMunicipios = async () => {
        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getMunicipios`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Incluye el token si es necesario
                },
            });
            setMunicipios(response.data);
        } catch (error) {
            console.error('Error fetching municipios:', error);
        }
    };



    useEffect(() => {
        fetchMunicipios();
    }, []);

    const handleMunicipioChange = selectedOption => {
        setFormData({ ...formData, municipio: selectedOption.value });
    };


    const opcionesPaises = paises.map(pais => ({
        value: `${pais.Codigo} - ${pais.Nombre}`,
        label: pais.Nombre,
    }));
    const opcionesMunicipios = municipios.map(municipio => ({
        value: `${municipio.Codigo} - ${municipio.Nombre}`,
        label: municipio.Nombre,
    }));

    return (
        <div className="">
            <div className="flex item-center justify-between mb-4">
                <h2 className="text-2xl font-semibold">Agregar nuevo paciente</h2>
                <button
                    type="submit"
                    className="px-3 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onGoBack}
                >
                    Volver
                </button>
            </div>
            <br />

            <form className="space-y-6" onSubmit={handleSubmit} encType="multipar/form-data">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                    {/* Imagen */}
                    <div className="col-span-1">

                        <label className="block text-sm font-medium text-gray-700">Imagen</label>
                        <input
                            type="file"
                            name="foto_perfil"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept="image/*"
                        />
                    </div>

                    {/* Documento (Adjuntar) */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Documento Identidad</label>
                        <input
                            type="file"
                            name="cedulaPDF"
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full px-3 py-2 text-gray-500 border-gray-300 rounded-md shadow-sm"
                            accept="application/pdf"
                        />
                    </div>

                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Fecha de Nacimiento *</label>
                        <input
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        />
                    </div>

                    {/* País de Nacimiento */}
                    <div className="form-group">
                        <label className="block text-gray-700">País de Nacimiento *</label>
                        <Select
                            name="birthCountry"
                            value={opcionesPaises.find(option => option.value === formData.birthCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, birthCountry: selectedOption.value })
                            }
                            options={opcionesPaises}
                            placeholder="Seleccione un país"
                            className="w-full"
                        />
                    </div>

                    {/* País de Residencia */}
                    <div className="form-group">
                        <label className="block text-gray-700">País de Residencia *</label>
                        <Select
                            name="residenceCountry"
                            value={opcionesPaises.find(option => option.value === formData.residenceCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, residenceCountry: selectedOption.value })
                            }
                            options={opcionesPaises}
                            placeholder="Seleccione un país"
                            className="w-full"
                        />
                    </div>

                    {/* Estado */}
                    <div className="form-group">
                        <label className="block text-gray-700">Municipio / Ciudad *</label>
                        <Select
                            name="municipio"
                            value={opcionesMunicipios.find(option => option.value === formData.residenceCountry)}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, municipio: selectedOption.value })
                            }
                            options={opcionesMunicipios}
                            placeholder="Seleccione un municipio"
                            className="w-full"
                        />
                    </div>

                    {/*<div className="form-group">
                        <label className="block text-gray-700">Ciudad *</label>
                        <select
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Ciudad</option>
                            {cities.length > 0 ? (
                                cities.map((city, index) => (
                                    <option key={index} value={city}>
                                        {city}
                                    </option>
                                ))
                            ) : (
                                <option value="">No hay ciudades disponibles</option>
                            )}
                        </select>
                    </div>*/}

                    <div className="form-group">
                        <label className="block text-gray-700">Dirección</label>
                        <input
                            type="text"
                            name="direccion"
                            value={addressDisplay} //
                            readOnly
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Dirección"

                        />
                        <button
                            type="button"
                            onClick={() => setShowModal(true)} // Abre el modal
                            className="ml-2 px-3 py-1 mt-2 bg-blue-600 text-white rounded-md"
                        >
                            Agregar
                        </button>
                    </div>


                    {/* Barrio */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Barrio *</label>
                        <input
                            type="text"
                            name="neighborhood"
                            value={formData.neighborhood}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Barrio"
                            required
                        />
                    </div>

                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Zona de ubicación</label>
                        <select
                            name="zonaUbicacion"
                            value={formData.zonaUbicacion}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="01_Rural">Rural</option>
                            <option value="02_Urbana">Urbana</option>
                        </select>
                    </div>


                    {/* Primer Nombre */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Primer Nombre *</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Primer Nombre"
                            required
                        />
                    </div>

                    {/* Segundo Nombre */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Segundo Nombre</label>
                        <input
                            type="text"
                            name="secondName"
                            value={formData.secondName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Segundo Nombre"
                        />
                    </div>

                    {/* Primer Apellido */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Primer Apellido *</label>
                        <input
                            type="text"
                            name="firstLastName"
                            value={formData.firstLastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Primer Apellido"
                            required
                        />
                    </div>

                    {/* Segundo Apellido */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Segundo Apellido</label>
                        <input
                            type="text"
                            name="secondLastName"
                            value={formData.secondLastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Segundo Apellido"
                        />
                    </div>

                    {/* Género */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Grupo sanguíneo</label>
                        <select
                            name="grupoSanguineo"
                            value={formData.grupoSanguineo}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="O">O</option>
                            <option value="AB">AB</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">RH</label>
                        <select
                            name="rh"
                            value={formData.rh}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >

                            <option value="">Seleccione una opción</option>
                            <option value="Positivo">Positivo</option>
                            <option value="Negativo">Negativo</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Género *</label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="" disabled>Seleccione un género</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino">Femenino</option>
                            <option value="no-binario">No Binario</option>
                            <option value="Otro">Otro</option>
                            <option value="prefiero-no-decirlo">Prefiero no decirlo</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Sexo</label>
                        <select
                            name="sex"
                            value={formData.sex}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="H">Hombre</option>
                            <option value="M">Mujer</option>
                            <option value="I">Indeterminado/Intersexual</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Estado civil</label>
                        <select
                            name="estadoCivil"
                            value={formData.estadoCivil}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="Soltero">Soltero(A)</option>
                            <option value="Casado">Casado(A)</option>
                            <option value="Viudo">Viudo(A)</option>
                            <option value="Divorciado">Divorciado(A)</option>
                            <option value="Separado">Separado(A)</option>
                            <option value="Unión Libre">Unión Libre</option>
                            <option value="No Aplica">No Aplica</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Condición de desplazamiento</label>
                        <select
                            name="condicionDesplazamiento"
                            value={formData.condicionDesplazamiento}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="Desplazados internos">Desplazados internos</option>
                            <option value="Poblacion desplazada por la violencia">Población desplazada por la violencia</option>
                            <option value="Desplazamiento forzado">Desplazamiento forzado</option>
                            <option value="No aplica">No aplica</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Grupo étnico</label>
                        <select
                            name="grupoEtnico"
                            value={formData.grupoEtnico}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona un grupo étnico</option>
                            <option value="ROM (GITANOS)">ROM (GITANOS)</option>
                            <option value="AFROCOLOMBIANOS">AFROCOLOMBIANOS (Negro(a), mulato(a), afro colombiano(a) o afro descendiente)</option>
                            <option value="INDIGENAS">INDÍGENAS</option>
                            <option value="RAIZALES">RAIZALES (del archipiélago de San Andrés y Providencia)</option>
                            <option value="PALENQUERO">PALENQUERO (de San Basilio)</option>
                            <option value="Sin pertenencia étnica">Sin pertenencia étnica</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Escolaridad</label>
                        <select
                            name="escolaridad"
                            value={formData.escolaridad}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona una opción</option>
                            <option value="Básica Primaria">Básica Primaria</option>
                            <option value="Básica Secundaria">Básica Secundaria</option>
                            <option value="Técnica Profesional">Técnica Profesional</option>
                            <option value="Profesional">Profesional</option>
                            <option value="Especialización">Especialización</option>
                            <option value="Ninguno">Ninguno</option>
                            <option value="Otro">Otro</option>
                            <option value="Preescolar">Preescolar</option>
                            <option value="Media Académica o Clásica">Media Académica o Clásica</option>
                            <option value="Media Técnica (Bachillerato Técnico)">Media Técnica (Bachillerato Técnico)</option>
                            <option value="Normalista">Normalista</option>
                            <option value="Tecnológica">Tecnológica</option>
                            <option value="Maestría">Maestría</option>
                            <option value="Doctorado">Doctorado</option>
                            <option value="Posgrado">Posgrado</option>
                        </select>
                    </div>

                    {/* Número de Celular */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular *</label>
                        <input
                            type="text"
                            name="cellNumber"
                            value={formData.cellNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de Celular 2</label>
                        <input
                            type="text"
                            name="cellNumber1"
                            value={formData.cellNumber1}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de Celular"

                        />
                    </div>

                    {/* Correo Electrónico */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Correo Electrónico *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Correo Electrónico"
                            required
                        />
                    </div>

                    {/* Tipo de ID */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de ID *</label>
                        <select
                            name="idType"
                            value={formData.idType}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Seleccione</option>
                            <option value="CC">CC</option>
                            <option value="TI">TI</option>
                            <option value="CE">CE</option>
                            <option value="PS">PS</option>
                            <option value="DE">DE</option>
                        </select>
                    </div>

                    {/* Número de ID */}
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Número de ID *</label>
                        <input
                            type="text"
                            name="idNumber"
                            value={formData.idNumber}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            placeholder="Número de ID"
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Tipo de usuario</label>
                        <select
                            name="tipoUsuario"
                            value={formData.tipoUsuario}
                            onChange={handleSelectChange}
                            className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Selecciona un tipo de usuario</option>
                            <option value="01">Contributivo cotizante</option>
                            <option value="02">Contributivo beneficiario</option>
                            <option value="03">Contributivo adicional</option>
                            <option value="04">Subsidiado</option>
                            <option value="05">No afiliado</option>
                            <option value="06">Especial o Excepción cotizante</option>
                            <option value="07">Especial o Excepción beneficiario</option>
                            <option value="08">Personas privadas de la libertad a cargo del Fondo Nacional de Salud</option>
                            <option value="09">Tomador / Amparado ARL</option>
                            <option value="10">Tomador / Amparado SOAT</option>
                            <option value="11">Tomador / Amparado Planes voluntarios de salud</option>
                            <option value="12">Particular</option>
                        </select>
                    </div>

                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Ocupación</label>
                        <Select
                            name="ocupacion"
                            value={selectedOcupacion}
                            onChange={handleOcupacionChange}
                            onInputChange={(inputValue) => {
                                if (inputValue.length > 2) {
                                    fetchOcupacionOptions(inputValue);
                                } else {
                                    setOcupacionOptions([]); // Limpiar las opciones si el input es corto
                                }
                            }}
                            options={ocupacionOptions}
                            isLoading={loading}
                            className="mt-1 block w-full"
                            placeholder="Buscar..."
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Aseguradora</label>
                        <Select
                            name="aseguradora"
                            value={selectedAseguradora}
                            onChange={handleAseguradoraChange}
                            onInputChange={(inputValue) => {
                                if (inputValue.length > 2) {
                                    fetchAseguradoraOptions(inputValue);
                                } else {
                                    setAseguradoraOptions([]); // Limpiar las opciones si el input es corto
                                }
                            }}
                            options={aseguradoraOptions}
                            isLoading={loading}
                            className="mt-1 block w-full"
                            placeholder="Buscar..."
                        />
                    </div>
                </div>

                <hr className="my-6 border-gray-300" />

                {/* Seguro Médico */}
                <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700">¿Tiene Seguro Médico?</label>
                    <select
                        name="hasMedicalInsurance"
                        value={formData.hasMedicalInsurance}
                        onChange={handleSelectChange}
                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="no">No</option>
                        <option value="yes">Sí</option>
                    </select>
                </div>

                {formData.hasMedicalInsurance === 'yes' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        {/* Nombre del Seguro */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Nombre del Seguro</label>
                            <input
                                type="text"
                                name="insuranceName"
                                value={formData.insuranceName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Nombre del Seguro"
                            />
                        </div>

                        {/* Número del Seguro */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Número del Seguro</label>
                            <input
                                type="text"
                                name="insuranceNumber"
                                value={formData.insuranceNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Número del Seguro"
                            />
                        </div>
                    </div>
                )}

                <hr className="my-6 border-gray-300" />

                {/* Cuidador o Representante */}
                <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700">¿Tiene Cuidador o Representante?</label>
                    <select
                        name="hasCarer"
                        value={formData.hasCarer}
                        onChange={handleSelectChange}
                        className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="no">No</option>
                        <option value="yes">Sí</option>
                    </select>
                </div>

                {formData.hasCarer === 'yes' && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        {/* Primer Nombre del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Primer Nombre del Cuidador</label>
                            <input
                                type="text"
                                name="carerFirstName"
                                value={formData.carerFirstName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Primer Nombre del Cuidador"
                            />
                        </div>

                        {/* Segundo Nombre del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Segundo Nombre del Cuidador</label>
                            <input
                                type="text"
                                name="carerSecondName"
                                value={formData.carerSecondName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Segundo Nombre del Cuidador"
                            />
                        </div>

                        {/* Primer Apellido del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Primer Apellido del Cuidador</label>
                            <input
                                type="text"
                                name="carerFirstLastName"
                                value={formData.carerFirstLastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Primer Apellido del Cuidador"
                            />
                        </div>

                        {/* Segundo Apellido del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Segundo Apellido del Cuidador</label>
                            <input
                                type="text"
                                name="carerSecondLastName"
                                value={formData.carerSecondLastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Segundo Apellido del Cuidador"
                            />
                        </div>

                        {/* Parentesco */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Parentesco</label>
                            <input
                                type="text"
                                name="carerRelationship"
                                value={formData.carerRelationship}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Parentesco"
                            />
                        </div>

                        {/* Tipo de ID del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Tipo de ID del Cuidador</label>
                            <select
                                name="carerIdType"
                                value={formData.carerIdType}
                                onChange={handleSelectChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="">Seleccione</option>
                                <option value="CC">CC</option>
                                <option value="TI">TI</option>
                                <option value="CE">CE</option>
                                <option value="PS">PS</option>
                                <option value="DE">DE</option>
                            </select>
                        </div>

                        {/* Número de ID del Cuidador */}
                        <div className="col-span-1">
                            <label className="block text-sm font-medium text-gray-700">Número de ID del Cuidador</label>
                            <input
                                type="text"
                                name="carerIdNumber"
                                value={formData.carerIdNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border-gray-300 rounded-md shadow-sm"
                                placeholder="Número de ID del Cuidador"
                            />
                        </div>
                    </div>
                )}

                <div className="flex justify-end mt-6">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Guardar
                    </button>
                </div>
            </form>
            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-md shadow-lg w-3/4"> {/* Ajustamos el ancho con w-3/4 */}
                        <h2 className="text-lg font-bold mb-6">Agregar Dirección</h2>
                        <form onSubmit={handleSubmitDireccion} className="grid grid-cols-2 gap-6"> {/* Layout en 2 columnas */}

                            <div>
                                <label className="block text-gray-700">Vía</label>
                                <select
                                    name="via"
                                    value={addressData.via}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                >
                                    <option value="">Seleccione Vía</option>
                                    <option value="calle">Calle</option>
                                    <option value="carrera">Carrera</option>
                                    <option value="avenida">Avenida</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-gray-700">Número</label>
                                <input
                                    type="text"
                                    name="numero"
                                    value={addressData.numero}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Letra</label>
                                <input
                                    type="text"
                                    name="letra"
                                    value={addressData.letra}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div className="flex items-center">
                                <label className="block text-gray-700">Bis</label>
                                <input
                                    type="checkbox"
                                    name="bis"
                                    checked={addressData.bis}
                                    onChange={handleAddressChange}
                                    className="ml-2"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Complemento</label>
                                <input
                                    type="text"
                                    name="complemento"
                                    value={addressData.complemento}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Cardinalidad</label>
                                <input
                                    type="text"
                                    name="cardinalidad"
                                    value={addressData.cardinalidad}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Número (otro)</label>
                                <input
                                    type="text"
                                    name="otroNumero"
                                    value={addressData.otroNumero}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Letra (otro)</label>
                                <input
                                    type="text"
                                    name="otroLetra"
                                    value={addressData.otroLetra}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Complemento (otro)</label>
                                <input
                                    type="text"
                                    name="otroComplemento"
                                    value={addressData.otroComplemento}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700">Cardinalidad (otro)</label>
                                <input
                                    type="text"
                                    name="otroCardinalidad"
                                    value={addressData.otroCardinalidad}
                                    onChange={handleAddressChange}
                                    className="w-full px-3 py-2 border rounded"
                                />
                            </div>

                            <div className="col-span-2 flex justify-end mt-6">
                                <button
                                    type="button"
                                    onClick={() => setShowModal(false)} // Cierra el modal
                                    className="mr-4 px-4 py-2 bg-gray-600 text-white rounded-md"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2  bg-blue-600 text-white rounded-md"
                                >
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


        </div>
    );
}

export default PatientComponent;
