import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-blue-200 text-gray-800 p-4">
            {/* Ícono grande */}
            <FontAwesomeIcon icon={faExclamationTriangle} beat className="text-yellow-500 text-6xl mb-4" />

            {/* Mensaje de error */}
            <h1 className="text-4xl font-bold mb-2 text-center">¡Página no encontrada!</h1>
            <p className="text-lg text-center mb-6">
                Lo sentimos, no pudimos encontrar la página que estás buscando. <br />
                Verifica la URL o regresa a la página principal.
            </p>

            {/* Botón para regresar */}
            <button
                onClick={() => navigate(-1)} // Cambia esto si usas un router
                className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-all"
            >
                Regresar
            </button>
        </div>
    );
};

export default ErrorPage;
