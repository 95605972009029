import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableDocuments from '../ClinicComponent/TableDocuments';
import Swal from 'sweetalert2';
import { validarInputExpresionRegular } from '../../utils/generalFunctions';
import { alertaGeneral } from '../../utils/alertas';
import CompleterProfileDoctor from './CompleteProfileDoctor';
import ReenvioDocumentos from './ReenvioDocumentos';

const DoctorInfo = () => {
    const [doctor, setDoctor] = useState({});
    const [file, setFile] = useState({
        foto_perfil: null,
        cedulaPDF: null,
        diploma_acta_grado: null,
        homologacion_estudios: null,
        diploma_especializacion: null,
        tarjeta_profesional: null,
        firma_profesional: null,
        rethus: null,
        poliza_responsabilidad_civil: null,
    });

    const [showCompleteProfile, setShowCompleteProfile] = useState(false);
    const [showReenvioDocumentos, setShowReenvioDocumentos] = useState(false);
    const [cargando, setCargando] = useState(true);

    // Función para obtener la información del doctor
    const fetchDoctor = async () => {
        try {
            const doctorId = localStorage.getItem('doctorId');
            const token = localStorage.getItem('token');

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/${doctorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDoctor(response.data);
            setCargando(false);
        } catch (error) {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: error.response?.data?.message || 'No se pudo obtener la información del doctor, intente nuevamente.',
                isError: true,
            })
            setCargando(false);
        }
    };

    useEffect(() => {
        fetchDoctor();
    }, []);

    const documentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Profesional', url: doctor?.urlProfessionalPhoto || '', isEdit: true },
        { nameToSave: '', name: 'Documento Identidad', url: doctor?.urlIdDocument || '', isEdit: false },
        { nameToSave: '', name: 'Diploma Grado', url: doctor?.urlUndergraduateDiploma || '', isEdit: false },
        { nameToSave: '', name: 'Homologación Estudios', url: doctor?.urlStudyHomologation || '', isEdit: false },
        { nameToSave: '', name: 'Diploma Especialización', url: doctor?.urlSpecializationDiploma || '', isEdit: false },
        { nameToSave: '', name: 'Tarjeta Profesional', url: doctor?.urlProfessionalCard || '', isEdit: false },
        { nameToSave: '', name: 'Firma Profesional', url: doctor?.urlProfessionalSignature || '', isEdit: false },
        { nameToSave: '', name: 'Certificado Rethus', url: doctor?.urlRethusDocument || '', isEdit: false },
        { nameToSave: '', name: 'Poliza Responsabilidad Civil', url: doctor?.urlCivilLiabilityPolicyDocument || '', isEdit: false },
    ];

    const onHandleSubmitFiles = async () => {
        const token = localStorage.getItem('token');

        const dataDoctorToSend = new FormData();

        dataDoctorToSend.append('foto_perfil', file.foto_perfil);
        dataDoctorToSend.append('clinicInfo', doctor.clinicInfo._id);
        dataDoctorToSend.append('idNumber', doctor.idNumber);

        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/updatePictureProfile/${doctor._id}`, dataDoctorToSend,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (res.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Foto actualizada correctamente',
                    text: 'La foto del doctor ha sido actualizada correctamente.',
                }).then(() => {
                    localStorage.setItem('imageProfile', res.data);
                    window.location.reload();
                })
            }

        } catch (error) {
            alertaGeneral({
                titulo: 'Error',
                messageHtml: error.response?.data?.message || 'No se pudo actualizar la foto de perfil, intente nuevamente.',
                isError: true
            })
        }
    }

    const onHandleActualizarDatosPersonales = async () => {
        const token = localStorage.getItem('token');
        const formDataToSend = new FormData();
        formDataToSend.append('idType', doctor.idType);
        formDataToSend.append('idNumber', doctor.idNumber);
        formDataToSend.append('cellNumber', doctor.cellNumber);
        formDataToSend.append('priceService', doctor.priceService);
        formDataToSend.append('email', doctor.email);
        if (!doctor.profileComplete) {
            formDataToSend.append('profileComplete', true);
        }

        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/editDoctor/${doctor._id}`, formDataToSend,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);

            if (res.status === 200) {
                alertaGeneral({ titulo: "Cambios realizados", messageHtml: "Los cambios se han realizado correctamente." });
            }

        } catch (error) {
            alertaGeneral({ titulo: "Error al actualizar", messageHtml: "Error inesperado, por favor intenta de nuevo.", isError: true });
        }
    }

    const onHandleChangeProfileIncomplete = (e, expresionRegular) => {
        if (doctor.profileComplete && e.target.name !== 'priceService') {
            return;
        }
        setDoctor({ ...doctor, [e.target.name]: expresionRegular ? validarInputExpresionRegular(e, expresionRegular) : e.target.value });
    }

    if (cargando) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return !showCompleteProfile && !showReenvioDocumentos ? (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Información del Afiliado</h1>
            {doctor.estado === 'no_aprobado' && (
                <button className="mb-6 w-full px-4 py-2 bg-blue-600 text-1xl text-white font-semibold rounded-md hover:bg-blue-700"
                    onClick={() => setShowCompleteProfile(true)}>
                    Deseo Prestar Mis Servicios
                </button>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Nombre Completo</label>
                    <input
                        type="text"
                        value={doctor.fullName || ''}
                        readOnly
                        className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                    />
                </div>
                <div>
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Tipo de Documento de Identidad *</label>
                        <select
                            name="idType"
                            value={doctor.idType || ''}
                            onChange={(e) => onHandleChangeProfileIncomplete(e)}
                            className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                        >
                            <option value="" disabled>Seleccione</option>
                            <option value="cc">CC</option>
                            <option value="ti">TI</option>
                            <option value="ce">CE</option>
                            <option value="ps">PS</option>
                            <option value="de">DE</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Número de ID</label>
                    <input
                        type="text"
                        name="idNumber"
                        value={doctor.idNumber || ''}
                        onChange={(e) => onHandleChangeProfileIncomplete(e, /[^0-9]/g)}
                        readOnly={doctor.profileComplete}
                        className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Correo</label>
                    <input
                        type="email"
                        name="email"
                        value={doctor.email || ''}
                        readOnly
                        className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Número de Celular</label>
                    <input
                        type="text"
                        name="cellNumber"
                        value={doctor.cellNumber || ''}
                        readOnly
                        className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                    />
                </div>
                {doctor.service?.includes('medicina') && (
                    <>
                        <div>
                            <label className="block text-gray-700 font-semibold mb-2">Número Rethus</label>
                            <input
                                type="text"
                                name="rethusNumber"
                                value={doctor.rethusNumber || ''}
                                readOnly
                                className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-semibold mb-2">Póliza de Responsabilidad Civil</label>
                            <input
                                type="text"
                                name="civilLiabilityPolicyNumber"
                                value={doctor.civilLiabilityPolicyNumber || ''}
                                readOnly
                                className={`w-full px-4 py-2 border rounded-lg ${doctor.profileComplete && 'bg-gray-100'} text-gray-700`}
                            />
                        </div>
                    </>
                )}
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Servicio</label>
                    <input
                        type="text"
                        value={doctor.service?.replace('_', ' ') || ''}
                        readOnly
                        className="uppercase w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                {doctor && doctor.estado === 'aprobado' && (
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Precio Servicio</label>
                        <input
                            type="text"
                            name="priceService"
                            value={doctor.priceService || ''}
                            onInput={(e) => onHandleChangeProfileIncomplete(e, /[^0-9]/g)}
                            className="w-full px-4 py-2 border rounded-lg text-gray-700"
                        />
                    </div>
                )}
            </div>

            {!doctor.profileComplete && (
                <button
                    className="mt-7 w-full px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
                    onClick={onHandleActualizarDatosPersonales}
                >
                    Actualizar Datos Personales
                </button>
            )}

            {doctor.estado === 'en_revision' && (
                <div className="mt-4">
                    <p className="text-center text-red-500">Tu perfil y documentos se encuentran en revisión, pronto recibirás una respuesta.</p>
                </div>
            )}

            {doctor.estado === 'rechazado' && (
                <div className="mt-4">
                    <p className="text-center text-red-500">Tu perfil y documentos han sido rechazados, por favor revisa la información y documentos enviados.</p>
                    <button
                        className="mt-4 px-4 py-2 w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                        onClick={setShowReenvioDocumentos}
                    >
                        Revisar Documentos
                    </button>
                </div>
            )}

            {doctor.estado !== 'no_aprobado' && (
                <>
                    {/* Inicio componente de documentos */}
                    <TableDocuments
                        lstDocuments={documentsExists ? documentsExists : []}
                        filesToEdit={file}
                        setFilesToEdit={setFile}
                    />
                    {file && file.foto_perfil && (
                        <div className="flex justify-end mt-4">
                            <button
                                type="button"
                                onClick={onHandleSubmitFiles}
                                className="px-4 py-2 w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                            >
                                Confirmar Cambios
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    ) : showCompleteProfile ?
        (
            <CompleterProfileDoctor doctor={doctor} setDoctor={setDoctor} setShow={setShowCompleteProfile} />
        )
        : (
            <ReenvioDocumentos doctor={doctor} setShow={setShowReenvioDocumentos} />
        )
};

export default DoctorInfo;
